import { useEffect, useState } from 'react';

import {
  useGetUserQuery,
  useTrackAnonymousViewPageMutation,
  useTrackButtonClickMutation,
  useTrackViewPageMutation,
} from 'src/common/slices';

import { anonymousId } from '../tracking/data';

export const useSegmentTracking = (pageName?: string) => {
  const [trackViewPage] = useTrackViewPageMutation();
  const [trackAnonymousViewPage] = useTrackAnonymousViewPageMutation();
  const [trackButtonClicked] = useTrackButtonClickMutation();
  const { isSuccess: isUserAuth, isFetching, data: userRawData } = useGetUserQuery();

  const [hasLoaded, setHasLoaded] = useState(false);

  // Helps to fix the issue with the page view tracking being called multiple times
  useEffect(() => {
    if (!isFetching && !hasLoaded) setHasLoaded(true);
  }, [isFetching, hasLoaded]);

  useEffect(() => {
    if (!pageName || !hasLoaded) return;

    const trackingCallPromise = isUserAuth
      ? trackViewPage({ name: pageName })
      : trackAnonymousViewPage({ anonymousId, name: pageName });

    trackingCallPromise.catch(() => {});
  }, [isUserAuth, trackViewPage, trackAnonymousViewPage, pageName, hasLoaded]);

  const triggerButtonClickEvent = (eventName: string, pageUrl: string, buttonName: string) => {
    const userObject = isUserAuth ? { userId: userRawData?.data?.user._id } : { anonymousId };

    trackButtonClicked({
      eventName,
      pageUrl,
      userObject,
      buttonName,
    }).catch(() => {});
  };

  return { triggerButtonClickEvent };
};
