import { useCallback } from 'react';
import { faCircleExclamation } from '@fortawesome/pro-solid-svg-icons';
import { NotificationDispatch } from '@tryhackme/thm-ui-components';
import { StandardActionModal } from 'src/common/components/standard-action-modal';
import { StyledModalIcon } from 'src/common/components/standard-action-modal/standard-action-modal.styles';
import { TerminateMachineModalProps } from 'src/features/room/room.types';
import { StyledModalBodyText } from 'src/features/room/components/commons';
import { useTerminateVmMutation } from 'src/features/room/room.slice';
import { hasErrorPostData } from 'src/common/helpers/slices';
import { clearSplitScreenVmOrAllVms } from 'src/app/reducers/split-screen.reducer';
import { TaskType } from 'src/common/enums/task';
import { useAppDispatch } from 'src/app/hooks';
import { setIsVmReady } from 'src/app/reducers/room/room.reducer';
const iconComponent = <StyledModalIcon data-icon="error" icon={faCircleExclamation} />;
const dispatchTerminateMachineNotification = () => NotificationDispatch('success', 'Your machine has been terminated');
const modalBodyText = <StyledModalBodyText>Are you sure you want to terminate this machine?</StyledModalBodyText>;
export const TerminateMachineModal = ({
  open,
  onOpenChange,
  runningInstance
}: TerminateMachineModalProps) => {
  const [terminateMachineTrigger] = useTerminateVmMutation();
  const dispatch = useAppDispatch();
  const handleTerminateMachine = useCallback(async () => {
    dispatch(clearSplitScreenVmOrAllVms({
      type: TaskType.VM,
      vm: runningInstance
    }));
    dispatch(setIsVmReady(false));
    const result = await terminateMachineTrigger(runningInstance.id);
    if (hasErrorPostData(result)) {
      NotificationDispatch('error', result.error?.data?.message ?? 'Something went wrong while terminating the machine.');
    } else if (runningInstance.remote.remoteUrl) dispatchTerminateMachineNotification();
  }, [runningInstance, terminateMachineTrigger, dispatch]);
  return <StandardActionModal modalTitleText="Terminate Machine" modalBodyText={modalBodyText} dismissButtonTitle="Cancel" actionButtonTitle="Terminate Machine" handleModalAction={handleTerminateMachine} showIcon iconComponent={iconComponent} open={open} onOpenChange={onOpenChange} data-sentry-element="StandardActionModal" data-sentry-component="TerminateMachineModal" data-sentry-source-file="terminate-machine-modal.tsx" />;
};