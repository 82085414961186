import { useCallback } from 'react';

import { useTrackEventMutation } from 'src/common/slices';
import { TutorSegementEvent } from 'src/common/types';
import { TUTOR_STUCK_MESSAGE } from 'src/common/constants';
import {
  SendTutorMessage,
  SendTutorMessageVote,
  TutorDisplayMode,
} from 'src/features/room/components/tutor/tutor.types';
import { useGetRoomDetailQuery } from 'src/features/room/room.slice';
import { useAppSelector } from 'src/app/hooks';

export const useTutorTracking = (roomCode: string) => {
  const [trackEvent] = useTrackEventMutation();

  const { data: roomData } = useGetRoomDetailQuery(roomCode);
  const roomDetails = roomData?.data;
  const roomDifficulty = roomDetails?.difficulty;
  const roomType = roomDetails?.type;

  const {
    chat: { sessionId },
  } = useAppSelector((state) => state.tutor);

  const trackTutorEvent = useCallback(
    (eventName: TutorSegementEvent, additionalProperties: Record<string, any> = {}) => {
      // eslint-disable-next-line no-void
      void trackEvent({
        event: eventName,
        properties: {
          room_code: roomCode,
          room_type: roomType ?? '',
          room_difficulty: roomDifficulty ?? '',
          tutor_session_id: sessionId,
          ...additionalProperties,
        },
      });
    },
    [trackEvent, roomCode, roomType, roomDifficulty, sessionId],
  );

  const trackTutorOpen = useCallback(
    (displayMode: TutorDisplayMode) => {
      trackTutorEvent('tutor-chat-opened', {
        display_mode: displayMode,
      });
    },
    [trackTutorEvent],
  );

  const trackTutorClose = useCallback(() => {
    trackTutorEvent('tutor-chat-closed');
  }, [trackTutorEvent]);

  const trackMessageSent = useCallback(
    (message: SendTutorMessage) => {
      const messageType = message.type;
      if (messageType !== 'free-text' && messageType !== 'button') {
        return;
      }
      const event = messageType === 'free-text' ? 'tutor-chat-message-sent' : 'tutor-chat-button-clicked';
      trackTutorEvent(event, {
        message_type: message.type,
        message: messageType === 'free-text' ? message.message : undefined,
        button: messageType === 'button' ? message.message : undefined,
        button_type: messageType === 'button' ? 'dynamic-button' : undefined,
      });
    },
    [trackTutorEvent],
  );

  const trackVote = useCallback(
    (vote: SendTutorMessageVote) => {
      trackTutorEvent('tutor-chat-vote-clicked', {
        tutor_session_id: vote.tutorSessionId,
        message_id: vote.messageId,
        vote: vote.vote,
      });
    },
    [trackTutorEvent],
  );

  const trackChatRestart = useCallback(() => {
    trackTutorEvent('tutor-chat-restarted');
  }, [trackTutorEvent]);

  const trackIntroMessageButtonClicked = useCallback(
    (key: 'stuck-button' | 'learn-more-button') => {
      trackTutorEvent('tutor-chat-button-clicked', {
        button_type: `intro-${key}`,
        button: key === 'stuck-button' ? TUTOR_STUCK_MESSAGE : '',
      });
    },
    [trackTutorEvent],
  );

  const trackSendFeedbackClicked = useCallback(() => {
    trackTutorEvent('tutor-chat-send-feedback-clicked');
  }, [trackTutorEvent]);

  const trackTermsOfUseBannerClosed = useCallback(() => {
    trackTutorEvent('tutor-terms-of-use-banner-closed');
  }, [trackTutorEvent]);

  const trackChatWindowDragged = useCallback(() => {
    trackTutorEvent('tutor-chat-window-dragged');
  }, [trackTutorEvent]);

  const trackTutorNotificationsToggle = useCallback(
    (notificationsEnabled: boolean) => {
      trackTutorEvent('tutor-chat-notifications-toggled', {
        enabled: notificationsEnabled,
      });
    },
    [trackTutorEvent],
  );

  const trackNudgeOpened = useCallback(() => {
    trackTutorEvent('tutor-nudge-opened');
  }, [trackTutorEvent]);

  return {
    trackTutorOpen,
    trackTutorClose,
    trackMessageSent,
    trackVote,
    trackChatRestart,
    trackIntroMessageButtonClicked,
    trackSendFeedbackClicked,
    trackTermsOfUseBannerClosed,
    trackChatWindowDragged,
    trackTutorNotificationsToggle,
    trackNudgeOpened,
  };
};
