import type { BaseQueryFn, FetchBaseQueryError } from '@reduxjs/toolkit/query';
import type { ApiResponse, PaginatedResponse } from 'src/common/interfaces';
import type {
  LeaderboardBase,
  LeaderboardQuery,
} from 'src/features/soc-sim/landing-sections/leaderboard/leaderboard.types';
import type {
  CreateWorkspaceBody,
  CreateWorkspaceResponse,
  SOCSimTeamsPointsResponse,
  SOCSimTeamsRankResponse,
  UpdateWorkspaceUsersBody,
  WorkspaceResponseBody,
  InviteWorkspaceTeammatesResponse,
  InviteWorkspaceTeammatesBody,
  WorkspaceLeaderboardUser,
  WorkspaceActivity,
  WorkspaceChallengesResponse,
  WorkspaceDiscovery,
  RemoveWorkspaceDomainsBody,
  AddWorkspaceDomainsBody,
  VerifyWorkspaceDomainBody,
  WorkspaceDomainUpdateResponse,
} from 'src/common/interfaces/companies/workspace';
import type { WorkspaceKPIData } from './components/workspace-kpi/kpi.types';
import type { JoinWorkspaceBody, UpdateWorkspaceDetailsBody } from './components/workspace.types';
import type { GetWorkspacePreSignedUrlBody, GetWorkspacePreSignedUrlResponse } from './workspace.types';

import { store } from 'src/app/store';
import { RTKQueryTag } from 'src/common/constants';
import { apiSlice } from 'src/features/api/api-slice';

const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getWorkspace: builder.query<WorkspaceResponseBody, undefined>({
      query: () => 'workspace',
      providesTags: () => [{ type: RTKQueryTag.Workspace }],
      transformResponse: (response: ApiResponse<WorkspaceResponseBody>) =>
        response.data ?? { hasJoinedWorkspace: false },
    }),
    getWorkspaceLeaderboard: builder.query<WorkspaceLeaderboardUser[], void>({
      query: () => 'workspace/leaderboard',
      providesTags: () => [{ type: RTKQueryTag.WorkspaceLeaderboard }],
      transformResponse: (response: ApiResponse<WorkspaceLeaderboardUser[]>) => response.data ?? [],
    }),
    getWorkspaceActivity: builder.query<WorkspaceActivity[], { limit: number }>({
      query: ({ limit }) => ({
        method: 'GET',
        url: 'workspace/activity',
        params: { limit },
      }),
      providesTags: () => [{ type: RTKQueryTag.WorkspaceActivity }],
      transformResponse: (response: ApiResponse<WorkspaceActivity[]>) => response.data ?? [],
    }),
    getWorkspaceChallenges: builder.query<WorkspaceChallengesResponse | undefined, void>({
      query: () => 'workspace/challenges',
      providesTags: () => [{ type: RTKQueryTag.WorkspaceChallenges }],
      transformResponse: (response: ApiResponse<WorkspaceChallengesResponse>) => response.data,
    }),
    getSocSimLeaderboardForWorkspace: builder.query<PaginatedResponse<LeaderboardBase> | undefined, LeaderboardQuery>({
      query: ({ page, limit, sortBy, sortOrder, period }) => ({
        method: 'GET',
        url: 'workspace/soc-sim',
        params: { limit, page, sortBy, sortOrder, period },
      }),
      providesTags: () => [RTKQueryTag.WorkspaceSocSimLeaderboard],
      transformResponse: (response: ApiResponse<PaginatedResponse<LeaderboardBase>>) =>
        response.data && {
          ...response.data,
          docs: response.data.docs.map((doc, i) => ({ ...doc, rank: (response.data?.pagingCounter ?? 1) + i })),
        },
    }),
    getWorkspaceKPI: builder.query<WorkspaceKPIData | undefined, void>({
      query: () => 'workspace/dashboard-kpi',
      providesTags: () => [RTKQueryTag.WorkspaceKPI],
      transformResponse: (response: ApiResponse<WorkspaceKPIData>) => response.data,
    }),
    joinWorkspace: builder.mutation<ApiResponse, void>({
      query: () => ({ method: 'PATCH', url: 'workspace/join' }),
      invalidatesTags: [RTKQueryTag.Workspace],
    }),
    joinWorkspaceV2: builder.mutation<ApiResponse, JoinWorkspaceBody>({
      query: (body) => ({ method: 'PATCH', url: 'workspace/join', body }),
      invalidatesTags: [RTKQueryTag.Workspace],
    }),
    leaveWorkspace: builder.mutation<ApiResponse, void>({
      query: () => ({ method: 'PATCH', url: 'workspace/leave' }),
      invalidatesTags: [RTKQueryTag.Workspace, RTKQueryTag.WorkspaceDiscovery],
    }),
    createWorkspace: builder.mutation<ApiResponse<CreateWorkspaceResponse>, CreateWorkspaceBody>({
      query: (body) => ({ method: 'POST', url: 'workspace', body }),
      invalidatesTags: [RTKQueryTag.Workspace],
    }),
    updateWorkspaceDetails: builder.mutation<ApiResponse, UpdateWorkspaceDetailsBody>({
      query: (body) => ({
        url: 'workspace/details',
        method: 'PATCH',
        body,
      }),
      invalidatesTags: [RTKQueryTag.Workspace],
    }),
    getWorkspaceLogoPreSignedUrl: builder.query<GetWorkspacePreSignedUrlResponse, GetWorkspacePreSignedUrlBody>({
      query: (params) => ({
        url: 'workspace/logo-upload-url',
        method: 'GET',
        params,
      }),
      transformResponse: (response: ApiResponse<GetWorkspacePreSignedUrlResponse>) =>
        <GetWorkspacePreSignedUrlResponse>response.data,
    }),
    getSOCSimTeamsPoints: builder.query<SOCSimTeamsPointsResponse, void>({
      query: () => 'soc-sim/teams/points',
      providesTags: [RTKQueryTag.SOCSimTeamsPoints],
    }),
    getSOCSimTeamsRank: builder.query<SOCSimTeamsRankResponse, void>({
      query: () => 'soc-sim/teams/rank',
      providesTags: [RTKQueryTag.SOCSimTeamsRank],
    }),
    updateWorkspaceUsers: builder.mutation<ApiResponse, UpdateWorkspaceUsersBody>({
      query: (changes) => ({
        method: 'PATCH',
        url: 'workspace/users',
        body: changes,
      }),
      invalidatesTags: [
        RTKQueryTag.Workspace,
        RTKQueryTag.WorkspaceLeaderboard,
        RTKQueryTag.WorkspaceActivity,
        RTKQueryTag.WorkspaceChallenges,
      ],
    }),
    inviteWorkspaceTeammates: builder.mutation<
      ApiResponse<InviteWorkspaceTeammatesResponse>,
      InviteWorkspaceTeammatesBody
    >({
      query: (body) => ({ method: 'POST', url: 'workspace/invite-teammates', body }),
      invalidatesTags: [
        RTKQueryTag.WorkspaceInviteTeammates,
        RTKQueryTag.Workspace,
        RTKQueryTag.WorkspaceLeaderboard,
        RTKQueryTag.WorkspaceActivity,
        RTKQueryTag.WorkspaceChallenges,
        RTKQueryTag.WorkspaceSocSimLeaderboard,
      ],
    }),
    getWorkspaceDiscovery: builder.query<WorkspaceDiscovery[], void>({
      query: () => 'workspace/all-paid',
      providesTags: () => [{ type: RTKQueryTag.WorkspaceDiscovery }],
      transformResponse: (response: ApiResponse<WorkspaceDiscovery[]>) => response.data ?? [],
    }),
    removeWorkspaceDomains: builder.mutation<ApiResponse<WorkspaceDomainUpdateResponse>, RemoveWorkspaceDomainsBody>({
      query: (body) => ({ method: 'DELETE', url: 'workspace/domain', body }),
    }),
    addWorkspaceDomain: builder.mutation<ApiResponse<{ shouldVerify: boolean }>, AddWorkspaceDomainsBody>({
      query: (body) => ({ method: 'POST', url: 'workspace/domain', body }),
    }),
    verifyWorkspaceDomain: builder.mutation<ApiResponse<WorkspaceDomainUpdateResponse>, VerifyWorkspaceDomainBody>({
      queryFn: async (body, _api, _extraOptions, query) => {
        // eslint-disable-next-line no-promise-executor-return
        await new Promise((resolve) => setTimeout(resolve, 500));
        return query({ method: 'POST', url: 'workspace/verify-domain', body }) as ReturnType<
          BaseQueryFn<string, ApiResponse<WorkspaceDomainUpdateResponse>, FetchBaseQueryError>
        >;
      },
    }),
    resendDomainVerification: builder.mutation<
      ApiResponse<WorkspaceDomainUpdateResponse>,
      Omit<AddWorkspaceDomainsBody, 'deleteDomains'>
    >({
      query: (body) => ({ method: 'POST', url: 'workspace/resend-domain-verification', body }),
    }),
  }),
});

export const invalidateWorkspace = () => {
  store.dispatch(extendedApiSlice.util.invalidateTags([RTKQueryTag.Workspace, RTKQueryTag.WorkspaceDiscovery]));
};

export const {
  useGetWorkspaceQuery,
  useGetWorkspaceActivityQuery,
  useGetWorkspaceChallengesQuery,
  useGetWorkspaceLeaderboardQuery,
  useJoinWorkspaceMutation,
  useJoinWorkspaceV2Mutation,
  useCreateWorkspaceMutation,
  useLazyGetWorkspaceLogoPreSignedUrlQuery,
  useUpdateWorkspaceDetailsMutation,
  useUpdateWorkspaceUsersMutation,
  useGetSocSimLeaderboardForWorkspaceQuery,
  useGetSOCSimTeamsPointsQuery,
  useGetSOCSimTeamsRankQuery,
  useGetWorkspaceDiscoveryQuery,
  useGetWorkspaceKPIQuery,
  useInviteWorkspaceTeammatesMutation,
  useLeaveWorkspaceMutation,
  useRemoveWorkspaceDomainsMutation,
  useAddWorkspaceDomainMutation,
  useVerifyWorkspaceDomainMutation,
  useResendDomainVerificationMutation,
  endpoints: WorkspaceEndpoints,
} = extendedApiSlice;
