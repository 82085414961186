import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'src/app/hooks';
import { closeAiTermsOfUseBanner, initTutorState, setDisplayMode, setNotificationsEnabled, setTutorSessionId } from 'src/app/reducers/room/tutor.reducer';
import { useGetUserQuery } from 'src/common/slices';
export const useTutorSessionInit = (roomCode: string) => {
  const dispatch = useAppDispatch();
  const {
    data: userData
  } = useGetUserQuery();
  const {
    stateInit
  } = useAppSelector(state => state.tutor);
  useEffect(() => {
    if (stateInit || !userData) {
      return;
    }
    const {
      sessions,
      displayMode,
      notificationsEnabled,
      hasSeenAiTermsOfUseBanner
    } = userData?.data?.user?.settings?.tutor ?? {};
    if (sessions) {
      const lastSession = sessions[roomCode];
      const lastSessionId = lastSession?.sessionId;
      if (lastSessionId) dispatch(setTutorSessionId(lastSessionId));
    }
    if (displayMode) dispatch(setDisplayMode(displayMode));
    if (notificationsEnabled !== undefined) dispatch(setNotificationsEnabled(notificationsEnabled));
    if (hasSeenAiTermsOfUseBanner) dispatch(closeAiTermsOfUseBanner());
    dispatch(initTutorState());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData, roomCode, dispatch]);
};