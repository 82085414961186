import { useState } from 'react';

import { HookOutput } from 'src/common/components/community-section';
import { useFeatureValue } from 'src/common/growthbook';

import { IS_APP_BANNER_DISMISSED_KEY } from '../app-banner.constants';

export const useShowAppBanner = (): HookOutput => {
  const isPreviouslyDisabled = localStorage.getItem(IS_APP_BANNER_DISMISSED_KEY) === true.toString();
  const [isClosed, setIsClosed] = useState(false);
  const appBannerFlag = useFeatureValue('app-banner', { enabled: false, blockedPages: [] });
  const isShowAppBanner = !isClosed && !isPreviouslyDisabled && (appBannerFlag?.enabled as boolean);

  const hideAppBanner = () => {
    setIsClosed(true);
    localStorage.setItem(IS_APP_BANNER_DISMISSED_KEY, true.toString());
  };

  return [isShowAppBanner, hideAppBanner] as HookOutput;
};
