import { useCallback } from 'react';

import { useGetUserQuery } from 'src/common/slices';

import { isInternalContentDev } from '../helpers';

export const useScenarioPermissions = () => {
  const { data: userQueryData, isLoading: isUserLoading } = useGetUserQuery();
  const user = userQueryData?.data?.user;

  const company = user?.subscriptions.find((sub) => sub.type === 'company' && sub.status === 'active');
  const companyId = company?.companyId;

  const hasAccessToB2B = !isUserLoading && Boolean(companyId) && company?.features?.socSim;
  const hasAccessToB2C = !isUserLoading && (user?.isPremium || Boolean(companyId));
  // @TODO: Refactor - remove content-dev - https://tryhackme.atlassian.net/browse/PLAT-540
  const hasAccessToQAScenarios = isInternalContentDev(user);
  const isLoggedIn = Boolean(user);

  const canAccessScenario = useCallback(
    (flags: string[]) => {
      // Not logged in users can't access any scenarios
      if (!user) return false;

      // Handle qa scenarios - only content dev users
      if (flags.includes('qa_testing')) return hasAccessToQAScenarios;

      // Handle coming soon - no access for anyone
      if (flags.includes('coming_soon')) return false;

      // Handle free scenarios - no restrictions
      if (flags.includes('free')) return true;

      // Handle B2C scenarios - Premium or B2B users
      if (flags.includes('b2c')) {
        return hasAccessToB2C;
      }

      // Handle B2B scenarios with socSim feature
      if (flags.includes('b2b')) {
        return hasAccessToB2B;
      }

      return false;
    },
    [user, hasAccessToB2B, hasAccessToB2C, hasAccessToQAScenarios],
  );

  return { canAccessScenario, hasAccessToB2B, hasAccessToB2C, hasAccessToQAScenarios, isLoggedIn };
};
