export const ERROR_CONTACT_MSG = `If this is an error on our behalf please `;
export const ROOM_USER_EVENT = 'room-event-user';
export const ROOM_EVENTS_JOIN = 'room-events-join';
export const ROOM_EVENT = 'room-event';
export const ROOM_JOIN_TEAM = 'room-join-team';
export const ROOM_TEAM_EVENT = 'room-team-event';
export const ROOM_HEART_BEAT_INTERVAL_SECONDS = 30;
export const ERROR_ROOM_NOT_FOUND = 'The room you tried to access doesn’t exist. Try searching for another one';
export const HAS_SEEN_LOAD_VM_IN_BACKGROUND = 'hasSeenLoadVMInBackground';
export const ROOM_VM_READY = 'room-vm-ready';
export const ROOMS_TEAMS_MODAL_DISABLED = ['hackfinitybattle'];
