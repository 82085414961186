import { useViewport } from '@tryhackme/thm-ui-components';
import { useParams } from 'react-router-dom';

import { BREAKPOINTS } from 'src/common/constants/breakpoints';

import { useGetRoomDetailQuery } from './room.slice';

export const useIsEchoEnabledInCurrentRoom = () => {
  const { roomCode } = useParams();
  const { data: roomData } = useGetRoomDetailQuery(roomCode ?? '', { skip: !roomCode });
  const { width, height } = useViewport();

  if (!roomCode) return false;

  const room = roomData?.data;
  const isTutorVisible = room?.tutor?.isVisible;
  const isJoined = room?.joined;

  const isSmallScreen = width < BREAKPOINTS.MOBILE || height < 600;

  return isJoined && isTutorVisible && !isSmallScreen;
};

export const useIsAskEchoEnabledInCurrentRoom = () => {
  const { roomCode } = useParams();
  const { data: roomData } = useGetRoomDetailQuery(roomCode ?? '', { skip: !roomCode });

  if (!roomCode) return false;

  const room = roomData?.data;
  const isAskEchoEnabled = room?.isAskEchoEnabled;

  return Boolean(isAskEchoEnabled);
};
