import styled from 'styled-components';
import { secondaryColors } from '@tryhackme/thm-ui-components';

export const StyledBannerImageWrapper = styled.div`
  width: 42%;
  max-height: 340px;

  @media screen and (max-width: ${({ theme }) => theme.sizes.md}) {
    width: 100%;
    max-height: 180px;
  }
`;

export const StyledBannerImage = styled.img`
  width: 1050px;
  height: 1050px;
  transform: translate(-19%, -32%);

  @media screen and (max-width: 1280px) {
    width: 800px;
    height: 800px;
    transform: translate(-24%, -26%);
  }

  @media screen and (max-width: 920px) {
    width: 600px;
    height: 600px;
    transform: translate(-28%, -16%);
  }

  @media screen and (max-width: ${({ theme }) => theme.sizes.md}) {
    width: 100%;
    height: 500px;
    transform: translate(0, -36%);
  }
`;

export const StyledHeadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.7rem;
`;

export const StyledBannerTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 6.2rem 0;
  position: relative;
  z-index: 1;
  align-items: flex-start;

  span {
    font-size: ${({ theme }) => theme.fonts.sizes.medium};
    font-family: ${({ theme }) => theme.fonts.familySansPro};
    line-height: 1.6rem;
    font-weight: ${({ theme }) => theme.fonts.weight.bold};
    color: ${({ theme }) => theme.colors.primary.main};
    margin: 0 0 1.6rem;
  }

  h1 {
    font-size: 3.2rem;
    font-family: ${({ theme }) => theme.fonts.familyUbuntu};
    font-weight: 500;
    color: ${secondaryColors.grey[100]};
    margin: 0 0 1.6rem;

    @media screen and (max-width: 1020px) {
      font-size: 2.8rem;
    }
  }

  p {
    font-size: 1.8rem;
    font-family: ${({ theme }) => theme.fonts.familySansPro};
    font-weight: 400;
    color: ${({ theme }) => theme.colors.grey[400]};
    margin: 0 0 1.6rem;

    @media screen and (max-width: 1200px) {
      br {
        display: none;
      }
    }

    @media screen and (max-width: 1020px) {
      font-size: 1.6rem;
    }
  }

  @media screen and (max-width: ${({ theme }) => theme.sizes.md}) {
    padding: 6.2rem 0 0;
  }
`;

export const StyledTitleSection = styled.div`
  color: ${({ theme }) => theme.colors.primary.main};
  display: inline !important;
`;
