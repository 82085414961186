import { useEffect, useMemo, useState } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { SwitchThemeContext, useSwitchTheme } from '@tryhackme/thm-ui-components';
import { Provider } from 'react-redux';
import 'react-toastify/dist/ReactToastify.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'src/common/chart/chart-config';
import { RouterProvider } from './router';
import { store } from './app/store';
import { commonEndpoints } from './common/slices';
import { firstInitAmplitude } from './common/tracking/amplitude';
import { ThemeObserverWrapper } from './common/components/theme-observer';
import { GrowthBookProvider, gb } from './common/growthbook';

// This block starts fetching the always necessary data into RTK Query as soon as the app loads
// Note: since those queries never unsubscribe, they will forever remain as ghost subscriptions
// which will cause the data to always be considered "used"
// eslint-disable-next-line @typescript-eslint/no-floating-promises
store.dispatch(commonEndpoints.getFeatureFlags.initiate());
// eslint-disable-next-line @typescript-eslint/no-floating-promises
store.dispatch(commonEndpoints.getUser.initiate());
const amplitudeInitPromise = firstInitAmplitude();
export const App = () => {
  useEffect(() => {
    /* eslint-disable no-console */
    const handleError = (event: ErrorEvent) => {
      if (event?.message?.includes('SyntaxError') && event?.message?.trim().endsWith("'<'") && event?.filename?.startsWith(window.location.origin)) {
        const url = new URL(window.location.href);
        if (url.searchParams.get('badBundle') === 'true') {
          if (url.pathname.replace(/^\/r/, '') === '/500') {
            setTimeout(() => {
              const root = document.querySelector('#root');
              if (root && !root?.innerHTML?.length) {
                root.innerHTML = '<h1>Fatal error! Please try again or contact customer support.</h1>';
              }
            }, 1000);
            console.error('Detected bad JS bundle loop, giving up');
            return;
          }
          console.error('Detected bad JS bundle again, going to 500...');
          window.location.href = '/500';
          return;
        }
        console.error('Detected bad JS bundle, reloading page...');
        url.searchParams.set('badBundle', 'true');
        window.location.href = url.toString();
      }
    };
    /* eslint-enable no-console */
    if (gb.isOn('reload-on-bad-bundle')) {
      window.addEventListener('error', handleError, true);
    }
    return () => {
      window.removeEventListener('error', handleError);
    };
  }, []);
  const [readyToRender, setReadyToRender] = useState(false);
  const {
    currentTheme,
    setCurrentTheme
  } = useSwitchTheme();
  useEffect(() => {
    amplitudeInitPromise.then(() => setReadyToRender(true)).catch(() => setReadyToRender(true));
  }, []);
  const switchThemeContextValue = useMemo(() => ({
    theme: currentTheme,
    setTheme: setCurrentTheme
  }), [currentTheme, setCurrentTheme]);
  if (!readyToRender) return null;
  return <HelmetProvider data-sentry-element="HelmetProvider" data-sentry-component="App" data-sentry-source-file="app.tsx">
      <Provider store={store} data-sentry-element="Provider" data-sentry-source-file="app.tsx">
        <GrowthBookProvider growthbook={gb} data-sentry-element="GrowthBookProvider" data-sentry-source-file="app.tsx">
          <SwitchThemeContext.Provider value={switchThemeContextValue} data-sentry-element="unknown" data-sentry-source-file="app.tsx">
            <ThemeObserverWrapper data-sentry-element="ThemeObserverWrapper" data-sentry-source-file="app.tsx" />
            <RouterProvider data-sentry-element="RouterProvider" data-sentry-source-file="app.tsx" />
          </SwitchThemeContext.Provider>
        </GrowthBookProvider>
      </Provider>
    </HelmetProvider>;
};