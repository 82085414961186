import React, { useEffect, useMemo, useState } from 'react';
import useCountDown from 'react-countdown-hook';
import { useParams } from 'react-router-dom';
import { Panel, PanelGroup } from 'react-resizable-panels';
import { useAppSelector } from 'src/app/hooks';
import { invalidateCachedRunningVms } from 'src/features/room/room.slice';
import { TutorWindow } from 'src/features/room/components/tutor/tutor-window';
import { useIsDarkMode } from 'src/common/hooks/use-is-dark-mode';
import { StyledSplitScreenContent, StyledSplitScreenWrapper, StyledSplitScreenLeft, StyledSplitScreenMiddle, StyledSplitScreenRight, AnimatedPanelWrapper, PanelContainer, StyledPanelResizeHandle } from './split-screen.styles';
import { SplitScreenProps } from './split-screen.types';
import { SplitScreenNavigation } from './components/split-screen-navigation';
import { SplitScreenContent } from './components/split-screen-content';
const SplitScreen: React.FC<SplitScreenProps> = ({
  children
}) => {
  const isDarkMode = useIsDarkMode();
  const {
    roomCode
  } = useParams();
  const {
    tutorPanelOpen,
    vmPanelOpen,
    tabs,
    currentTab
  } = useAppSelector(state => state.splitScreen);
  const {
    chat: {
      displayMode
    }
  } = useAppSelector(state => state.tutor);
  const isEmbedded = displayMode === 'embedded';
  const isEmbeddedTutorOpen = tutorPanelOpen && isEmbedded;
  const [leftPanelSize, setLeftPanelSize] = useState(25);
  const [rightPanelSize, setRightPanelSize] = useState(50);
  const [bootTimeRemaining, {
    start: startBootTimeCountdown,
    reset: resetBootTimeCountdown
  }] = useCountDown(0, 1000);
  useEffect(() => {
    const bootTimeRemainingSeconds = currentTab?.vm?.remote.bootTimeRemainingSeconds;
    if (bootTimeRemainingSeconds && bootTimeRemainingSeconds > 0) {
      startBootTimeCountdown(bootTimeRemainingSeconds * 1000);
    } else {
      resetBootTimeCountdown();
    }
  }, [currentTab?.vm?.remote.bootTimeRemainingSeconds, startBootTimeCountdown, resetBootTimeCountdown]);
  useEffect(() => {
    if (bootTimeRemaining === 0 && currentTab?.vm?.remote.bootTime !== 0) {
      setTimeout(() => invalidateCachedRunningVms(), 1000);
    }
  }, [bootTimeRemaining, currentTab?.vm?.remote.bootTime]);
  const bootProgress = useMemo(() => {
    const bootTime = currentTab?.vm?.remote.bootTime || 2;
    const bootTimeSeconds = 60 * bootTime;
    return Math.round((bootTimeSeconds - bootTimeRemaining / 1000) / bootTimeSeconds * 100);
  }, [currentTab?.vm?.remote, bootTimeRemaining]);
  const handleLayoutChange = (layout: number[]) => {
    if (tutorPanelOpen && vmPanelOpen) {
      if (layout[0]) setLeftPanelSize(layout[0]);
      if (layout[2]) setRightPanelSize(layout[2]);
    } else if (tutorPanelOpen) {
      if (layout[0]) setLeftPanelSize(layout[0]);
    } else if (vmPanelOpen && layout[1]) {
      setRightPanelSize(layout[1]);
    }
  };
  return <StyledSplitScreenWrapper open={isEmbeddedTutorOpen || vmPanelOpen} data-sentry-element="StyledSplitScreenWrapper" data-sentry-component="SplitScreen" data-sentry-source-file="split-screen.tsx">
      <PanelGroup direction="horizontal" autoSaveId="split-screen-layout" onLayout={handleLayoutChange} data-sentry-element="PanelGroup" data-sentry-source-file="split-screen.tsx">
        {roomCode && <AnimatedPanel isOpen={isEmbeddedTutorOpen} position="left" defaultSize={leftPanelSize}>
            <Panel id="left-panel" order={1} minSize={20} defaultSize={leftPanelSize} maxSize={30}>
              <StyledSplitScreenLeft open={isEmbeddedTutorOpen} data-testid="splitScreenLeft">
                <StyledSplitScreenContent open={isEmbeddedTutorOpen}>
                  <TutorWindow roomCode={roomCode} />
                </StyledSplitScreenContent>
              </StyledSplitScreenLeft>
            </Panel>

            <StyledPanelResizeHandle $position="left" $isDarkMode={isDarkMode} />
          </AnimatedPanel>}

        <Panel id="middle-panel" order={2} minSize={27} defaultSize={50} data-sentry-element="Panel" data-sentry-source-file="split-screen.tsx">
          <StyledSplitScreenMiddle data-testid="splitScreenMiddle" data-sentry-element="StyledSplitScreenMiddle" data-sentry-source-file="split-screen.tsx">{children}</StyledSplitScreenMiddle>
        </Panel>

        <AnimatedPanel isOpen={vmPanelOpen} position="left" defaultSize={rightPanelSize} data-sentry-element="AnimatedPanel" data-sentry-source-file="split-screen.tsx">
          <StyledPanelResizeHandle $position="right" $isDarkMode={isDarkMode} data-sentry-element="StyledPanelResizeHandle" data-sentry-source-file="split-screen.tsx" />
          <Panel id="right-panel" order={3} minSize={20} defaultSize={50} data-sentry-element="Panel" data-sentry-source-file="split-screen.tsx">
            <StyledSplitScreenRight open={vmPanelOpen} data-testid="splitScreenRight" data-sentry-element="StyledSplitScreenRight" data-sentry-source-file="split-screen.tsx">
              <StyledSplitScreenContent open={vmPanelOpen} data-sentry-element="StyledSplitScreenContent" data-sentry-source-file="split-screen.tsx">
                {currentTab && <SplitScreenContent currentTabData={currentTab} bootProgress={bootProgress} />}
              </StyledSplitScreenContent>
              <SplitScreenNavigation currentTab={currentTab} tabs={tabs} open={vmPanelOpen} bootTimeRemaining={bootTimeRemaining} resetBootTimeCountdown={resetBootTimeCountdown} data-sentry-element="SplitScreenNavigation" data-sentry-source-file="split-screen.tsx" />
            </StyledSplitScreenRight>
          </Panel>
        </AnimatedPanel>
      </PanelGroup>
    </StyledSplitScreenWrapper>;
};
export { SplitScreen };
const AnimatedPanel: React.FC<{
  isOpen: boolean;
  position: 'left' | 'right';
  defaultSize: number;
  children: React.ReactNode;
}> = ({
  isOpen,
  position,
  defaultSize,
  children
}) => {
  const [width, setWidth] = useState(isOpen ? defaultSize : 0);
  const [shouldAnimate, setShouldAnimate] = useState(true);
  useEffect(() => {
    if (isOpen) {
      setWidth(defaultSize);
      const timer = setTimeout(() => setShouldAnimate(false), 500);
      return () => clearTimeout(timer);
    }
    setWidth(0);
    setShouldAnimate(true);
    return () => {};
  }, [isOpen, defaultSize]);
  return <AnimatedPanelWrapper isOpen={isOpen} position={position} width={width} shouldAnimate={shouldAnimate} data-sentry-element="AnimatedPanelWrapper" data-sentry-component="AnimatedPanel" data-sentry-source-file="split-screen.tsx">
      {isOpen && <PanelContainer isOpen={isOpen}>{children}</PanelContainer>}
    </AnimatedPanelWrapper>;
};