import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Container } from '@tryhackme/thm-ui-components';

export const StyledAppBannerContainer = styled.div<{ $backgroundImage?: string }>`
  width: auto;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  overflow: hidden;
  background-image: ${({ $backgroundImage }) => ($backgroundImage ? `url(${$backgroundImage})` : `none`)};
`;

export const StyledContainer = styled(Container)`
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: ${({ theme }) => theme.sizes.md}) {
    flex-direction: column;
  }
`;

export const StyledAppBannerCloseButton = styled(FontAwesomeIcon)`
  cursor: pointer;
  height: 2.23rem;
  width: 2.23rem;
  position: absolute;
  right: 2.4rem;
  top: 2.5rem;
  color: ${({ theme }) => theme.colors.grey[500]};

  :hover {
    color: ${({ theme }) => theme.colors.white};
  }
`;
