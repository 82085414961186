import { useParams } from 'react-router-dom';

import { useFeatureIsOn } from 'src/common/growthbook';

const SUPPORTED_ROOM_CODES = new Set(['offensivesecurityintro']);

export const BreakdownExperimentTaskProgressMap: Record<number, number> = {
  20: 33,
  40: 66,
  60: 100,
};

export const useIsBreakdownExperimentEnabled = () => {
  const { roomCode } = useParams();

  const isBreakdownFeatureEnabled = useFeatureIsOn('breakdown-offensive-security-intro');

  return isBreakdownFeatureEnabled && !!roomCode && SUPPORTED_ROOM_CODES.has(roomCode);
};
