import { FC, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { ThemeProvider, useTheme } from 'styled-components';
import { darkColors, Modal } from '@tryhackme/thm-ui-components';
import { ReactComponent as ModalImg } from 'src/images/svgs/fireship/fireship-image.svg';
import { useAppDispatch, useAppSelector } from 'src/app/hooks';
import { toggleModal } from 'src/app/reducers/campaigns.reducer';
import { useAnnouncement } from 'src/features/announcements/hooks/use-announcement';
import { AnnouncementName } from 'src/common/enums/announcements';
import { useAnnouncementStorage } from 'src/features/announcements/hooks/use-announcement-storage';
import { useAnnouncementCta } from 'src/features/announcements/hooks/use-announcement-cta';
import { useGetUserQuery } from 'src/common/slices';
import { useCampaign } from '../use-campaign';
import { StyledContent, Styledtag, StyledWrapper, StyledfFireshipRedirectButton } from './fireship-modal.styles';
export const FireshipCampaignModal: FC = () => {
  const {
    modalIsShown
  } = useAppSelector(state => state.campaigns);
  const dispatch = useAppDispatch();
  const {
    announcement
  } = useAnnouncement(AnnouncementName.FIRESHIP_2025);
  const {
    isSeen,
    markAsSeen,
    reset
  } = useAnnouncementStorage(announcement);
  const campaignData = useCampaign();
  const {
    data: userDataResponse
  } = useGetUserQuery();
  const [ctaText, urlToNavigate] = useAnnouncementCta();
  const [prevAuthState, setPrevAuthState] = useState(!!userDataResponse?.data);
  const theme = useTheme();
  useEffect(() => {
    const isLoggedIn = !!userDataResponse?.data;
    const wasLoggedIn = !!prevAuthState;
    if (isLoggedIn !== wasLoggedIn) {
      reset();
      setPrevAuthState(isLoggedIn);
    }
  }, [userDataResponse?.data, prevAuthState, reset]);
  const onClose = () => {
    markAsSeen();
    dispatch(toggleModal(false));
  };
  const shouldShow = !!campaignData && !!announcement && (userDataResponse?.data ? !isSeen : true);
  const url = userDataResponse?.data ? urlToNavigate : '/signup?source=fireship';
  return shouldShow ? <ThemeProvider theme={{
    ...theme,
    colors: darkColors
  }}>
      <Modal padding="0" open={modalIsShown} onOpenChange={onClose}>
        <StyledWrapper>
          <StyledContent>
            <ModalImg />
            <Styledtag>Fireship Viewers Exclusive</Styledtag>
            <h2>Welcome to TryHackMe!</h2>
            <p>
              You’ve unlocked a special offer! <br />
              Fireship viewers get <span>20% off</span> our Premium subscription!
            </p>

            <StyledfFireshipRedirectButton as={Link} to={url} variant="solid" color="primary" onClick={onClose}>
              {ctaText}
            </StyledfFireshipRedirectButton>
          </StyledContent>
        </StyledWrapper>
      </Modal>
    </ThemeProvider> : null;
};