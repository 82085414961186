import { Navigate } from 'react-router-dom';
import { routeProtector } from 'src/router/route-protector';
import { FeatureFlagName } from 'src/common/constants';
import { AdditionalContentTypes } from 'src/common/enums';
import { ThmRouteObject } from 'src/common/interfaces';
import { userIsPartOfAWorkspace, isBusinessUserOrCoporatePartner } from '../route-loaders';
export const managementDashboardRoutes: ThmRouteObject[] = [{
  path: '/client/:companyId/',
  loader: routeProtector().requireCorporatePartner().requireCompletedOnboardingAndWelcome(),
  async lazy() {
    const {
      ManagementDashboard
    } = await import('src/features/management-dashboard');
    return {
      Component: ManagementDashboard
    };
  },
  children: [{
    path: '',
    loader: routeProtector().requireCorporatePartner(),
    async lazy() {
      const {
        Users
      } = await import('src/features/management-dashboard');
      return {
        Component: Users
      };
    },
    children: [{
      path: '',
      element: <Navigate to="./users" />
    }, {
      path: 'users',
      handle: {
        name: 'mgt_dash_users'
      },
      async lazy() {
        const {
          UserManagementView
        } = await import('src/features/management-dashboard');
        return {
          Component: UserManagementView
        };
      }
    }, {
      path: 'admins',
      handle: {
        name: 'mgt_dash_admins'
      },
      async lazy() {
        const {
          UserManagementAdminsView
        } = await import('src/features/management-dashboard');
        return {
          Component: UserManagementAdminsView
        };
      }
    }, {
      path: 'groups',
      handle: {
        name: 'mgt_dash_groups'
      },
      async lazy() {
        const {
          UserManagementGroupsView
        } = await import('src/features/management-dashboard');
        return {
          Component: UserManagementGroupsView
        };
      }
    }, {
      path: 'aws-training-access',
      handle: {
        name: 'mgt_dash_aws_training_access'
      },
      async lazy() {
        const {
          UserManagementCloudTrainingAccessView
        } = await import('src/features/management-dashboard');
        return {
          Component: () => <UserManagementCloudTrainingAccessView platform={AdditionalContentTypes.AWS} />
        };
      }
    }, {
      path: 'azure-training-access',
      handle: {
        name: 'mgt_dash_azure_training_access'
      },
      loader: routeProtector().requireCorporatePartner().requireFeatureFlag(FeatureFlagName.AZURE_CLOUD_TRAINING),
      async lazy() {
        const {
          UserManagementCloudTrainingAccessView
        } = await import('src/features/management-dashboard');
        return {
          Component: () => <UserManagementCloudTrainingAccessView platform={AdditionalContentTypes.AZURE} />
        };
      }
    }]
  }, {
    path: 'guides',
    handle: {
      name: 'mgt_dash_guides'
    },
    loader: routeProtector().requireCorporatePartner(),
    async lazy() {
      const {
        Guides
      } = await import('src/features/management-dashboard');
      return {
        Component: Guides
      };
    }
  }, {
    path: 'reports',
    async lazy() {
      const {
        Reports
      } = await import('src/features/management-dashboard');
      return {
        Component: Reports
      };
    },
    children: [{
      path: '',
      element: <Navigate to="./content" />
    }, {
      path: 'content',
      handle: {
        name: 'mgt_dash_reports_content'
      },
      async lazy() {
        const {
          ReportsContentView
        } = await import('src/features/management-dashboard');
        return {
          Component: ReportsContentView
        };
      }
    }, {
      path: 'assignments',
      handle: {
        name: 'mgt_dash_reports_assignments'
      },
      async lazy() {
        const {
          ReportsAssignmentsView
        } = await import('src/features/management-dashboard');
        return {
          Component: ReportsAssignmentsView
        };
      }
    }, {
      path: 'time',
      handle: {
        name: 'mgt_dash_reports_time'
      },
      async lazy() {
        const {
          ReportsTimeView
        } = await import('src/features/management-dashboard');
        return {
          Component: ReportsTimeView
        };
      }
    }, {
      path: 'activity',
      handle: {
        name: 'mgt_dash_reports_activity'
      },
      async lazy() {
        const {
          ReportsActivityView
        } = await import('src/features/management-dashboard');
        return {
          Component: ReportsActivityView
        };
      }
    }, {
      path: 'time-content',
      handle: {
        name: 'mgt_dash_reports_time_content'
      },
      async lazy() {
        const {
          ReportsTimeContentView
        } = await import('src/features/management-dashboard');
        return {
          Component: ReportsTimeContentView
        };
      }
    }, {
      path: 'skills-matrix',
      handle: {
        name: 'mgt_dash_reports_skills_matrix'
      },
      async lazy() {
        const {
          ReportsSkillsMatrixView
        } = await import('src/features/management-dashboard');
        return {
          Component: ReportsSkillsMatrixView
        };
      }
    }]
  }, {
    path: 'soc-sim',
    loader: routeProtector().requireBusinessUserOrCorporatePartner().requireFeatureFlag(FeatureFlagName.MGTDASH_SOC_SIM).requireSocSimFeatureEnabled(),
    async lazy() {
      const {
        SocSim
      } = await import('src/features/management-dashboard');
      return {
        Component: SocSim
      };
    },
    children: [{
      path: '',
      element: <Navigate to="./reports/team" />
    }, {
      path: 'reports/team',
      handle: {
        name: 'mgt_dash_soc_sim_team_report'
      },
      async lazy() {
        const {
          TeamReportView
        } = await import('src/features/management-dashboard/soc-sim');
        return {
          Component: TeamReportView
        };
      }
    }, {
      path: 'reports/users',
      children: [{
        path: '',
        handle: {
          name: 'mgt_dash_soc_sim_user_reports'
        },
        async lazy() {
          const {
            UserReportsView
          } = await import('src/features/management-dashboard/soc-sim');
          return {
            Component: UserReportsView
          };
        }
      }, {
        path: ':userId',
        handle: {
          name: 'mgt_dash_soc_sim_user_report'
        },
        async lazy() {
          const {
            UserReportView
          } = await import('src/features/management-dashboard/soc-sim');
          return {
            Component: UserReportView
          };
        }
      }]
    }]
  }, {
    path: 'paths',
    loader: routeProtector().requireCorporatePartner(),
    async lazy() {
      const {
        LearningPaths
      } = await import('src/features/management-dashboard');
      return {
        Component: LearningPaths
      };
    },
    children: [{
      path: '',
      element: <Navigate to="../content-studio" />
    }, {
      path: 'modules',
      handle: {
        name: 'mgt_dash_modules'
      },
      async lazy() {
        const {
          ModulesView
        } = await import('src/features/management-dashboard');
        return {
          Component: ModulesView
        };
      }
    }, {
      path: 'modules/new',
      handle: {
        name: 'mgt_dash_modules_new'
      },
      async lazy() {
        const {
          CreateModuleView
        } = await import('src/features/management-dashboard');
        return {
          Component: CreateModuleView
        };
      }
    }, {
      path: 'modules/:moduleId',
      handle: {
        name: 'mgt_dash_modules_edit'
      },
      async lazy() {
        const {
          EditModuleView
        } = await import('src/features/management-dashboard');
        return {
          Component: EditModuleView
        };
      }
    }, {
      path: 'new',
      handle: {
        name: 'mgt_dash_paths_new'
      },
      async lazy() {
        const {
          CreatePathView
        } = await import('src/features/management-dashboard');
        return {
          Component: CreatePathView
        };
      }
    }, {
      path: ':pathCode',
      handle: {
        name: 'mgt_dash_paths_details'
      },
      async lazy() {
        const {
          PathDetails
        } = await import('src/features/management-dashboard');
        return {
          Component: PathDetails
        };
      }
    }, {
      path: ':pathCode/manage',
      handle: {
        name: 'mgt_dash_paths_manage'
      },
      async lazy() {
        const {
          ManagePathView
        } = await import('src/features/management-dashboard');
        return {
          Component: ManagePathView
        };
      }
    }]
  }, {
    path: 'content-studio',
    handle: {
      name: 'mgt_dash_content_studio'
    },
    async lazy() {
      const {
        ContentStudio
      } = await import('src/features/management-dashboard');
      return {
        Component: ContentStudio
      };
    }
  }, {
    path: 'assignments',
    handle: {
      name: 'mgt_dash_assignments'
    },
    loader: routeProtector().requireCorporatePartner(),
    async lazy() {
      const {
        Assignments
      } = await import('src/features/management-dashboard');
      return {
        Component: Assignments
      };
    }
  }, {
    path: 'create-assignment/:contentId',
    handle: {
      name: 'mgt_dash_create_assignment'
    },
    async lazy() {
      const {
        CreateAssignment
      } = await import('src/features/management-dashboard');
      return {
        Component: CreateAssignment
      };
    }
  }, {
    path: 'assignments/:assignmentId',
    handle: {
      name: 'mgt_dash_assignment_details'
    },
    loader: routeProtector().requireCorporatePartner(),
    async lazy() {
      const {
        AssignmentDetails
      } = await import('src/features/management-dashboard');
      return {
        Component: AssignmentDetails
      };
    }
  }, {
    path: 'settings',
    loader: routeProtector().requireCorporatePartner(),
    async lazy() {
      const {
        ManagementDashboardSettings
      } = await import('src/features/management-dashboard');
      return {
        Component: ManagementDashboardSettings
      };
    },
    children: [{
      path: '',
      element: <Navigate to="./room-branding" />
    }, {
      path: 'api',
      handle: {
        name: 'mgt_dash_settings_api'
      },
      async lazy() {
        const {
          SettingsApiView
        } = await import('src/features/management-dashboard');
        return {
          Component: SettingsApiView
        };
      }
    }, {
      path: 'billing',
      handle: {
        name: 'mgt_dash_settings_billing'
      },
      async lazy() {
        const {
          SettingsBillingView
        } = await import('src/features/management-dashboard');
        return {
          Component: SettingsBillingView
        };
      }
    }, {
      path: 'room-branding',
      handle: {
        name: 'mgt_dash_settings_room_branding'
      },
      async lazy() {
        const {
          SettingsGeneralView
        } = await import('src/features/management-dashboard');
        return {
          Component: SettingsGeneralView
        };
      }
    }, {
      path: 'sso',
      handle: {
        name: 'mgt_dash_settings_sso'
      },
      loader: routeProtector().requireFeatureFlag(FeatureFlagName.SINGLE_SIGN_ON).requireSSOFeatureEnabled(),
      async lazy() {
        const {
          SettingsSSOView
        } = await import('src/features/management-dashboard');
        return {
          Component: SettingsSSOView
        };
      }
    }, {
      path: 'preferences',
      handle: {
        name: 'mgt_dash_settings_preferences'
      },
      async lazy() {
        const {
          SettingsPreferencesView
        } = await import('src/features/management-dashboard');
        return {
          Component: SettingsPreferencesView
        };
      }
    }, {
      path: 'reminders',
      loader: routeProtector().requireFeatureFlag('email-reminders'),
      handle: {
        name: 'mgt_dash_settings_reminders'
      },
      async lazy() {
        const {
          SettingsRemindersView
        } = await import('src/features/management-dashboard');
        return {
          Component: SettingsRemindersView
        };
      }
    }]
  }, {
    path: 'MITRE',
    loader: routeProtector().requireFeatureFlag('mitre-page').requireCorporatePartner(),
    handle: {
      name: 'mgt_dash_mitre'
    },
    async lazy() {
      const {
        Mitre
      } = await import('src/features/management-dashboard/mitre/mitre');
      return {
        Component: Mitre
      };
    }
  }, {
    path: 'billing',
    element: <Navigate to="../settings/billing" />
  }, {
    path: 'api',
    element: <Navigate to="../settings/api" />
  }, {
    path: 'sso',
    element: <Navigate to="../settings/sso" />
  }
  // {
  //   // redirect to legacy tabs
  //   path: '*',
  //   loader: backToTheLegacyLoader,
  // },
  ]
}, {
  path: '/client/:companyId/users/:userId',
  loader: routeProtector().requireCorporatePartner().requireCompletedOnboardingAndWelcome(),
  async lazy() {
    const {
      CompanyUserDetailsView
    } = await import('src/features/management-dashboard');
    return {
      Component: CompanyUserDetailsView
    };
  }
}, {
  path: '/workspace',
  loader: routeProtector().requireLogin(),
  children: [{
    path: '',
    async lazy() {
      const {
        Workspace
      } = await import('src/features/management-dashboard');
      return {
        Component: Workspace
      };
    }
  }, {
    path: '/workspace/create',
    loader: routeProtector().requireFeatureFlag('v2-workspaces'),
    async lazy() {
      const {
        CreateWorkspace
      } = await import('src/features/management-dashboard/workspace/create-workspace/create-workspace');
      return {
        Component: CreateWorkspace
      };
    }
  }]
}, {
  path: '/ctf-builder',
  loader: routeProtector().requireFeatureFlag('ctf-builder-v2').requireOneOf({
    redirectTo: '/workspace?ref=ctf-builder',
    loaders: [userIsPartOfAWorkspace, isBusinessUserOrCoporatePartner]
  }),
  async lazy() {
    const {
      CTFBuilderV2
    } = await import('src/features/management-dashboard/ctf-builder/ctf-builder-v2');
    return {
      Component: CTFBuilderV2
    };
  },
  handle: {
    isHeaderTransparent: true,
    isHeaderSticky: false
  }
}];