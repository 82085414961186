import { RefObject, useEffect } from 'react';

export const useCountdown = (elements: RefObject<HTMLElement>[], startTime: number, endTime: number) => {
  useEffect(() => {
    const setValues = () => {
      const now = Date.now();
      if (now > endTime) {
        return;
      }

      const timeLeft = Math.floor((endTime - now) / 1000);

      const d = Math.floor(timeLeft / (3600 * 24));
      const h = Math.floor((timeLeft - d * 3600 * 24) / 3600);
      const m = Math.floor((timeLeft - d * 3600 * 24 - h * 3600) / 60);
      const s = Math.floor(timeLeft - d * 3600 * 24 - h * 3600 - m * 60);

      [d, h, m, s]
        .map((n) => n.toString().padStart(2, '0'))
        // eslint-disable-next-line unicorn/no-array-for-each
        .forEach((val, idx) => {
          const el = elements[idx];
          if (!el || !el.current) return;
          el.current.textContent = val;
        });
    };

    setValues();
    const intervalId = setInterval(setValues, 1000);

    return () => clearInterval(intervalId);
  }, [startTime, endTime, elements]);
};
