import { useCallback, useEffect } from 'react';

import { useAppDispatch, useAppSelector } from 'src/app/hooks';
import { useGetUserQuery } from 'src/common/slices';
import { setIntroMessageVisible, setStuckButtonVisible } from 'src/app/reducers/room/tutor.reducer';
import { DEFAULT_TUTOR_LANDING_PAGE_URL, TUTOR_STUCK_MESSAGE } from 'src/common/constants';
import { TutorMessage, TutorMessageSender, TutorIntroMessage } from 'src/features/room/components/tutor/tutor.types';

import { useTutorTracking } from './use-tutor-tracking';
import { useTutorMessages } from './use-tutor-messages';
import { useTutorSocketEvents } from './use-tutor-socket';

interface UseTutorIntroMessageProps {
  roomCode: string;
  roomId?: string;
  defaultConfig: TutorIntroMessage;
}

export const useTutorIntroMessage = (props: UseTutorIntroMessageProps) => {
  const { defaultConfig, roomCode, roomId } = props;
  const dispatch = useAppDispatch();
  const { data: userData } = useGetUserQuery();

  const { trackIntroMessageButtonClicked } = useTutorTracking(roomCode);
  const { addUserMessage } = useTutorMessages();
  const { sendMessage } = useTutorSocketEvents({ roomCode, roomId });

  const {
    chat: {
      sessionId: tutorSessionId,
      messages,
      introMessage: { visible: introMessageVisible, stuckButtonVisible },
    },
  } = useAppSelector((state) => state.tutor);
  const messagesLength = messages.length;

  useEffect(() => {
    let isIntroMessageVisible = false;
    if (userData?.data?.user?.settings?.tutor?.sessions) {
      const sessions = userData?.data?.user?.settings?.tutor?.sessions;
      const sessionsLength = Object.keys(sessions).length;

      const lastSession = sessions[roomCode];
      const lastSessionId = lastSession?.sessionId;
      const lastSessionIdShouldSeeIntro = lastSession?.shouldSeeIntro;

      if ((!lastSession && sessionsLength < 3) || (lastSessionId === tutorSessionId && lastSessionIdShouldSeeIntro)) {
        isIntroMessageVisible = true;
      }
    } else {
      isIntroMessageVisible = true;
    }

    dispatch(setIntroMessageVisible(isIntroMessageVisible));
  }, [userData, tutorSessionId, roomCode, dispatch]);

  useEffect(() => {
    if (messagesLength === 1 && introMessageVisible) {
      dispatch(setStuckButtonVisible(true));
    }
  }, [messagesLength, introMessageVisible, dispatch]);

  const handleStuckButtonClick = useCallback(() => {
    dispatch(setStuckButtonVisible(false));
    const message: TutorMessage = {
      message: TUTOR_STUCK_MESSAGE,
      sender: TutorMessageSender.USER,
      type: 'button',
    };
    addUserMessage(message.message, message.type);
    sendMessage(message);
    trackIntroMessageButtonClicked('stuck-button');
  }, [dispatch, addUserMessage, sendMessage, trackIntroMessageButtonClicked]);

  const handleLearnMoreClick = useCallback(() => {
    window.open(DEFAULT_TUTOR_LANDING_PAGE_URL, '_blank');
    trackIntroMessageButtonClicked('learn-more-button');
  }, [trackIntroMessageButtonClicked]);

  const handleButtonClick = useCallback(
    (key: string) => {
      if (key === 'stuck-button') {
        handleStuckButtonClick();
      } else if (key === 'learn-more-button') {
        handleLearnMoreClick();
      }
      defaultConfig.onButtonClick?.(key);
    },
    [defaultConfig, handleStuckButtonClick, handleLearnMoreClick],
  );

  const introMessage: TutorIntroMessage = {
    ...defaultConfig,
    isVisible: introMessageVisible,
    footer: {
      ...defaultConfig.footer,
      buttons: defaultConfig.footer.buttons.map((button) =>
        button.key === 'stuck-button' ? { ...button, isVisible: stuckButtonVisible } : button,
      ),
    },
    onButtonClick: handleButtonClick,
  };

  return {
    introMessage,
    handleStuckButtonClick,
    handleLearnMoreClick,
  };
};
