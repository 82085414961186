import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { ReactComponent as TwitterXIcon } from 'src/images/svgs/socials/x-twitter.svg';
import { ReactComponent as FacebookIcon } from 'src/images/svgs/socials/facebook.svg';
import { ReactComponent as LinkedinIcon } from 'src/images/svgs/socials/linkedin.svg';
import { StyledSocialButton } from 'src/common/styles';
import { SegmentShareEvent } from 'src/common/types';
import { useTrackEventMutation } from 'src/common/slices';
import { BigStreakSocialShareProps } from './big-streak-social-share.types';
import { StyledWrapperButtons } from './big-streak-social-share.styles';
export const BigStreakSocialSharing = ({
  username,
  streakLength
}: BigStreakSocialShareProps) => {
  const streakUrl = `https://tryhackme.com/${username!}/streaks/${streakLength}-day-streak`;
  const [trackEvent] = useTrackEventMutation();
  const location = useLocation();
  const trackShareClick = useCallback((event: SegmentShareEvent) => {
    const source = event.split('-')[1];
    // eslint-disable-next-line no-void
    void trackEvent({
      event: 'social-share',
      properties: {
        page: location.pathname,
        achievement: 'streak',
        medium: source,
        clicked_on: source,
        name: 'streak'
      }
    });
  }, [trackEvent, location.pathname]);
  return <StyledWrapperButtons data-sentry-element="StyledWrapperButtons" data-sentry-component="BigStreakSocialSharing" data-sentry-source-file="big-streak-social-share.tsx">
      <StyledSocialButton data-social="twitter" href={`https://twitter.com/intent/tweet?url=${streakUrl}&via=realtryhackme&hashtags=tryhackme`} target="_blank" onClick={() => trackShareClick('share-twitter')} data-sentry-element="StyledSocialButton" data-sentry-source-file="big-streak-social-share.tsx">
        <TwitterXIcon data-sentry-element="TwitterXIcon" data-sentry-source-file="big-streak-social-share.tsx" />
        Twitter
      </StyledSocialButton>
      <StyledSocialButton data-social="facebook" href={`https://www.facebook.com/sharer/sharer.php?u=${streakUrl}`} target="_blank" onClick={() => trackShareClick('share-facebook')} data-sentry-element="StyledSocialButton" data-sentry-source-file="big-streak-social-share.tsx">
        <FacebookIcon data-sentry-element="FacebookIcon" data-sentry-source-file="big-streak-social-share.tsx" />
        Facebook
      </StyledSocialButton>
      <StyledSocialButton data-social="linkedin" href={`https://www.linkedin.com/sharing/share-offsite/?url=${streakUrl}`} target="_blank" onClick={() => trackShareClick('share-linkedin')} data-sentry-element="StyledSocialButton" data-sentry-source-file="big-streak-social-share.tsx">
        <LinkedinIcon data-sentry-element="LinkedinIcon" data-sentry-source-file="big-streak-social-share.tsx" />
        Linkedin
      </StyledSocialButton>
    </StyledWrapperButtons>;
};