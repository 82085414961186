import type { UIMatch } from 'react-router-dom';
import type { RouteHandleObject } from 'src/common/interfaces';

import { useFeatureValue } from 'src/common/growthbook';

export const useAppBannerPermittedRoute = (route: UIMatch<unknown, RouteHandleObject | undefined>) => {
  const appBannerFlag = useFeatureValue('app-banner', { enabled: false, blockedPages: [] });
  const isBlockedPage = ((appBannerFlag?.blockedPages as string[]) ?? []).some((page) => route.pathname.includes(page));
  const isPathOutlinePage = route.pathname.includes('/path/outline/');
  const isRoomPage = route.pathname.includes('/room/');
  const isSal1LandingPage = route.pathname.includes('/certification/security-analyst-level-1');

  return !isBlockedPage && !isPathOutlinePage && !isRoomPage && !isSal1LandingPage;
};
