import { FC, useRef } from 'react';
import { useCountdown } from 'src/common/hooks/use-countdown';
import { CountdownProps } from './countdown.types';
import { StyledClockSeparator, StyledCountdown, StyledMessage, StyledTimeblock, StyledTimeblockValue, StyledTimer, StyledUnit } from './countdown.styles';
export const Countdown: FC<CountdownProps> = ({
  startTime,
  endTime,
  showMessage,
  includeDays,
  vertical,
  dark
}) => {
  const dRef = useRef<HTMLElement>(null);
  const hRef = useRef<HTMLElement>(null);
  const mRef = useRef<HTMLElement>(null);
  const sRef = useRef<HTMLElement>(null);
  useCountdown([dRef, hRef, mRef, sRef], startTime, endTime);
  return <StyledTimer data-sentry-element="StyledTimer" data-sentry-component="Countdown" data-sentry-source-file="countdown.tsx">
      {showMessage && <StyledMessage>Sale ends in:</StyledMessage>}
      <StyledCountdown data-sentry-element="StyledCountdown" data-sentry-source-file="countdown.tsx">
        {includeDays && <>
            <StyledTimeblock $vertical={vertical} $dark={dark} data-testid="countdown-days">
              <StyledTimeblockValue ref={dRef} $vertical={vertical} />
              <StyledUnit $vertical={vertical} $dark={dark}>
                days
              </StyledUnit>
            </StyledTimeblock>
            <StyledClockSeparator $vertical={vertical}>:</StyledClockSeparator>
          </>}

        <StyledTimeblock $vertical={vertical} $dark={dark} data-testid="countdown-hours" data-sentry-element="StyledTimeblock" data-sentry-source-file="countdown.tsx">
          <StyledTimeblockValue ref={hRef} $vertical={vertical} data-sentry-element="StyledTimeblockValue" data-sentry-source-file="countdown.tsx" />

          <StyledUnit $vertical={vertical} $dark={dark} data-sentry-element="StyledUnit" data-sentry-source-file="countdown.tsx">
            hr
          </StyledUnit>
        </StyledTimeblock>
        <StyledClockSeparator $vertical={vertical} data-sentry-element="StyledClockSeparator" data-sentry-source-file="countdown.tsx">:</StyledClockSeparator>

        <StyledTimeblock $vertical={vertical} $dark={dark} data-testid="countdown-mins" data-sentry-element="StyledTimeblock" data-sentry-source-file="countdown.tsx">
          <StyledTimeblockValue ref={mRef} $vertical={vertical} data-sentry-element="StyledTimeblockValue" data-sentry-source-file="countdown.tsx" />
          <StyledUnit $vertical={vertical} $dark={dark} data-sentry-element="StyledUnit" data-sentry-source-file="countdown.tsx">
            min
          </StyledUnit>
        </StyledTimeblock>
        <StyledClockSeparator $vertical={vertical} data-sentry-element="StyledClockSeparator" data-sentry-source-file="countdown.tsx">:</StyledClockSeparator>

        <StyledTimeblock $vertical={vertical} $dark={dark} data-testid="countdown-secs" data-sentry-element="StyledTimeblock" data-sentry-source-file="countdown.tsx">
          <StyledTimeblockValue ref={sRef} $vertical={vertical} data-sentry-element="StyledTimeblockValue" data-sentry-source-file="countdown.tsx" />
          <StyledUnit $vertical={vertical} $dark={dark} data-sentry-element="StyledUnit" data-sentry-source-file="countdown.tsx">
            sec
          </StyledUnit>
        </StyledTimeblock>
      </StyledCountdown>
    </StyledTimer>;
};