import styled from 'styled-components';
import { StyledLoadingPlaceholder, darkColors } from '@tryhackme/thm-ui-components';

export const StyledLoading = styled.div<{ width?: string; height?: string; isDarkVersion?: boolean }>`
  ${StyledLoadingPlaceholder}

  width: ${({ width }) => width || '100%'};
  height: ${({ height }) => height || '15.4rem'};
  ${({ isDarkVersion }) =>
    isDarkVersion &&
    `
   background: linear-gradient(
    to right,
    ${darkColors.loading.primary},
    ${darkColors.loading.secondary},
    ${darkColors.loading.primary}
  );
  `}
`;

export const StyledLoadingPagination = styled.div`
  display: grid;
  place-items: center;
  gap: 1.9rem;
`;
