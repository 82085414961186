import { RoomOptionsType } from './question-and-answer-item.types';

export enum SubmitButtonTitle {
  LOGIN_TO_ANSWER = 'Login to answer..',
  JOIN_THIS_ROOM = 'Join this room',
  CORRECT_ANSWER = 'Correct Answer',
  DEFAULT = 'Submit',
  COMPLETE = 'Complete',
}

export enum AnswerPlaceholder {
  LOGIN_TO_ANSWER = 'Login to answer..',
  JOIN_THIS_ROOM = 'Join this room',
  NO_ANSWER_NEEDED = 'No answer needed',
}

export enum AnswerErrorMessage {
  TOO_SHORT = 'Your answer is too short. Please make sure it matches the answer format represented by underscores.',
  INCORRECT = 'Your answer is incorrect. Please ensure it follows the answer format represented by underscores, check for typos, and try again.',
  NON_ENGLISH = 'Uh-oh! The answer you provided may not be in English. Please review it and try again.',
}

export const QUESTION_OPTIONS: RoomOptionsType = {
  offensivesecurityintro: {
    'task-1': {
      1: [
        { value: 'Offensive Security', label: 'Offensive Security' },
        { value: 'Defensive Security', label: 'Defensive Security' },
      ],
    },
  },
  defensivesecurityintro: {
    'task-1': {
      1: [
        { value: 'Red Team', label: 'Red Team' },
        { value: 'Blue Team', label: 'Blue Team' },
      ],
    },
    'task-2': {
      1: [
        { value: 'Network Defense Department', label: 'Network Defense Department' },
        { value: 'Cyber Threat Task Force', label: 'Cyber Threat Task Force' },
        { value: 'Security Operations Center', label: 'Security Operations Center' },
        { value: 'Digital Intrusion Response Team', label: 'Digital Intrusion Response Team' },
      ],
      2: [
        { value: 'Data Framework and Investigation Reporting', label: 'Data Framework and Investigation Reporting' },
        { value: 'Digital Forensics and Incident Response', label: 'Digital Forensics and Incident Response' },
        { value: 'Distributed Firewall and Intrusion Recovery', label: 'Distributed Firewall and Intrusion Recovery' },
        {
          value: 'Dynamic Forensics and Intelligence Reconnaissance',
          label: 'Dynamic Forensics and Intelligence Reconnaissance',
        },
      ],
      3: [
        { value: 'Ransomware', label: 'Ransomware' },
        { value: 'Spyware', label: 'Spyware' },
        { value: 'Adware', label: 'Adware' },
        { value: 'Worm', label: 'Worm' },
      ],
    },
  },
  searchskills: {
    'task-2': {
      1: [
        { value: 'Snake oil', label: 'Snake oil' },
        { value: 'False cipher', label: 'False cipher' },
        { value: 'Crypto fraud', label: 'Crypto fraud' },
        { value: 'Pseudo encryption', label: 'Pseudo encryption' },
      ],
      2: [
        { value: 'ip', label: 'ip' },
        { value: 'ps', label: 'ps' },
        { value: 'ns', label: 'ns' },
        { value: 'ss', label: 'ss' },
      ],
    },
    'task-3': {
      2: [
        { value: 'Simple Sockets', label: 'Simple Sockets' },
        { value: 'Socket Statistics', label: 'Socket Statistics' },
        { value: 'Secure Shell', label: 'Secure Shell' },
        { value: 'System Status', label: 'System Status' },
      ],
    },
    'task-4': {
      1: [
        { value: 'Sri Lanka', label: 'Sri Lanka' },
        { value: 'United States', label: 'United States' },
        { value: 'Denmark', label: 'Denmark' },
        { value: 'Czech Republic', label: 'Czech Republic' },
      ],
    },
    'task-5': {
      1: [
        { value: 'xz', label: 'xz' },
        { value: 'gz', label: 'gz' },
        { value: 'bz', label: 'bz' },
        { value: 'lz', label: 'lz' },
      ],
    },
    'task-6': {
      1: [
        { value: 'Catalog', label: 'Catalog' },
        { value: 'Command Access Terminal', label: 'Command Access Terminal' },
        { value: 'Console Action Tool', label: 'Console Action Tool' },
        { value: 'Concatenate', label: 'Concatenate' },
      ],
      2: [
        { value: '-a', label: '-a' },
        { value: '-n', label: '-n' },
        { value: '-b', label: '-b' },
        { value: '-o', label: '-o' },
      ],
    },
    'task-7': {
      1: [
        { value: 'Twitter', label: 'Twitter' },
        { value: 'LinkedIn', label: 'LinkedIn' },
        { value: 'Facebook', label: 'Facebook' },
        { value: 'Instagram', label: 'Instagram' },
      ],
      2: [
        { value: 'Twitter', label: 'Twitter' },
        { value: 'TikTok', label: 'TikTok' },
        { value: 'Facebook', label: 'Facebook' },
        { value: 'Instagram', label: 'Instagram' },
      ],
    },
  },
  linuxfundamentalspart1: {
    'task-2': {
      1: [
        { value: '1980', label: '1980' },
        { value: '1985', label: '1985' },
        { value: '1989', label: '1989' },
        { value: '1991', label: '1991' },
      ],
    },
  },
  pentestingfundamentals: {
    'task-2': {
      1: [
        { value: 'Black hat', label: 'Black hat' },
        { value: 'White hat', label: 'White hat' },
        { value: 'Grey hat', label: 'Grey hat' },
        { value: 'Red hat', label: 'Red hat' },
      ],
      2: [
        { value: 'Black hat', label: 'Black hat' },
        { value: 'White hat', label: 'White hat' },
        { value: 'Grey hat', label: 'Grey hat' },
        { value: 'Red hat', label: 'Red hat' },
      ],
      3: [
        { value: 'Terms of Service', label: 'Terms of Service' },
        { value: 'Code of Conduct', label: 'Code of Conduct' },
        { value: 'Rules of Engagement', label: 'Rules of Engagement' },
        { value: 'Statement of Compliance', label: 'Statement of Compliance' },
      ],
    },
    'task-3': {
      1: [
        { value: 'Initial Exploitation', label: 'Initial Exploitation' },
        { value: 'Post-Exploitation', label: 'Post-Exploitation' },
        { value: 'Information Gathering', label: 'Information Gathering' },
        { value: 'Reporting', label: 'Reporting' },
      ],
      2: [
        { value: 'OSSTMM', label: 'OSSTMM' },
        { value: 'OWASP', label: 'OWASP' },
        { value: 'PTES', label: 'PTES' },
        { value: 'NIST', label: 'NIST' },
      ],
      3: [
        { value: 'OSSTMM', label: 'OSSTMM' },
        { value: 'OWASP', label: 'OWASP' },
        { value: 'PTES', label: 'PTES' },
        { value: 'NIST', label: 'NIST' },
      ],
    },
    'task-4': {
      1: [
        { value: 'Black Box', label: 'Black Box' },
        { value: 'White Box', label: 'White Box' },
        { value: 'Grey Box', label: 'Grey Box' },
        { value: 'Red Box', label: 'Red Box' },
      ],
      2: [
        { value: 'Black Box', label: 'Black Box' },
        { value: 'White Box', label: 'White Box' },
        { value: 'Grey Box', label: 'Grey Box' },
        { value: 'Red Box', label: 'Red Box' },
      ],
    },
  },
  whatisnetworking: {
    'task-1': {
      1: [
        { value: 'Group', label: 'Group' },
        { value: 'Collection', label: 'Collection' },
        { value: 'Network', label: 'Network' },
        { value: 'System', label: 'System' },
      ],
    },
    'task-2': {
      1: [
        { value: 'Larry Page', label: 'Larry Page' },
        { value: 'Marc Andreessen', label: 'Marc Andreessen' },
        { value: 'Vint Cerf', label: 'Vint Cerf' },
        { value: 'Tim Berners-Lee', label: 'Tim Berners-Lee' },
      ],
    },
    'task-3': {
      1: [
        { value: 'Internal Packet', label: 'Internal Packet' },
        { value: 'Interface Point', label: 'Interface Point' },
        { value: 'Intelligent Process', label: 'Intelligent Process' },
        { value: 'Internet Protocol', label: 'Internet Protocol' },
      ],
      2: [
        { value: 'Segment', label: 'Segment' },
        { value: 'Octet', label: 'Octet' },
        { value: 'Subnet', label: 'Subnet' },
        { value: 'Block', label: 'Block' },
      ],
      3: [
        { value: '2', label: '2' },
        { value: '4', label: '4' },
        { value: '6', label: '6' },
        { value: '8', label: '8' },
      ],
      4: [
        { value: 'Machine Access Control', label: 'Machine Access Control' },
        { value: 'Main Access Channel', label: 'Main Access Channel' },
        { value: 'Media Access Control', label: 'Media Access Control' },
        { value: 'Managed Access Control', label: 'Managed Access Control' },
      ],
    },
    'task-4': {
      1: [
        { value: 'ICMP', label: 'ICMP' },
        { value: 'UDP', label: 'UDP' },
        { value: 'HTTP', label: 'HTTP' },
        { value: 'TCP', label: 'TCP' },
      ],
    },
  },
};
