import { useAppDispatch, useAppSelector } from 'src/app/hooks';
import { useGetRoomDetailQuery } from 'src/features/room/room.slice';
import { useFeatureIsOn } from 'src/common/growthbook';
import * as TutorReducer from 'src/app/reducers/room/tutor.reducer';
import * as SplitScreenReducer from 'src/app/reducers/split-screen.reducer';
import { useIsAskEchoEnabledInCurrentRoom } from 'src/features/room/room.helpers';

import { TutorActionParamsWithoutDelayProp, TutorDisplayMode } from '../tutor.types';

import { useTutorTracking } from './use-tutor-tracking';
import { useTutorSocketEvents } from './use-tutor-socket';

const buildHintMessage = (hint: string) =>
  `Hey,\n\nThis is the hint you're looking for: ${hint}\n\nLet me know if you want any help.`;

/**
 * Hook that provides a function to open the tutor with an optional delay and execute an action afterwards
 *
 * @param roomCode - The room code used for tracking
 * @returns An object containing the openTutorWithDelayedAction function
 */
export const useOpenTutorWithDelayedAction = (roomCode: string) => {
  const areEchoHintsEnabled = useFeatureIsOn('echo-hints');
  const isAskEchoEnabled = useIsAskEchoEnabledInCurrentRoom();
  const { data: roomData } = useGetRoomDetailQuery(roomCode);
  const roomDetails = roomData?.data;
  const roomId = roomDetails?._id;

  const dispatch = useAppDispatch();

  const {
    chat: { open: isTutorOpen, displayMode, messages },
  } = useAppSelector((state) => state.tutor);
  const { readMessages } = useTutorSocketEvents({
    roomId,
    roomCode,
  });
  const { trackTutorOpen } = useTutorTracking(roomCode);

  const openTutor = () => {
    if (displayMode === TutorDisplayMode.EMBEDDED) {
      dispatch(SplitScreenReducer.setTutorPanelOpen(true));
    }
    if (messages.length < 1) {
      dispatch(TutorReducer.setIsLoading(true));
      readMessages({ source: 'tutor-open' });
    }
    dispatch(TutorReducer.setStuckButtonVisible(false));
    dispatch(TutorReducer.openTutor());
    trackTutorOpen(displayMode);
  };

  const openTutorWithDelayedAction = (params: TutorActionParamsWithoutDelayProp) => {
    if (!isTutorOpen) openTutor();

    switch (params.type) {
      case 'hint': {
        if (!areEchoHintsEnabled) break;
        const { taskId, questionNo, defaultHint } = params.payload;

        dispatch(
          TutorReducer.setQueuedAction({
            type: 'hint',
            withDelay: !isTutorOpen,
            payload: {
              taskId,
              questionNo,
              defaultHint: buildHintMessage(defaultHint),
            },
          }),
        );

        break;
      }
      case 'ask-echo': {
        if (!isAskEchoEnabled) return;
        const { selectedText, taskId, surroundingText } = params.payload;
        dispatch(
          TutorReducer.setQueuedAction({
            type: 'ask-echo',
            withDelay: !isTutorOpen,
            payload: {
              selectedText,
              taskId,
              surroundingText,
            },
          }),
        );

        break;
      }
      default: {
        break;
      }
    }
  };

  return { openTutorWithDelayedAction };
};
