import { Button } from '@tryhackme/thm-ui-components';
import styled from 'styled-components';

export const StyledWrapperContent = styled.div`
  padding: 4.6rem 4.6rem 10rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 2.4rem;

  h2 {
    margin-bottom: 0;
    font-size: 3.2rem;
  }
`;

export const StyledShareButton = styled(Button)`
  &:hover:not(:disabled) {
    color: white;
  }
  svg {
    fill: white;
  }
`;
