import { store } from 'src/app/store';
import { RTKQueryTag } from 'src/common/constants';
import { ApiResponse } from 'src/common/interfaces';
import {
  CertificateExamSlug,
  CertificationPurchaseAvailability,
  CertificationResultsData,
} from 'src/common/interfaces/certifications';
import { apiSlice } from 'src/features/api/api-slice';

const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createCertification: builder.mutation<CertificateExamSlug, void>({
      query: () => ({
        url: 'certification/create-sal1-exam',
        method: 'POST',
      }),
    }),
    getPreviousResults: builder.query<CertificationResultsData[], { certificationType: string }>({
      query: ({ certificationType }) => ({
        url: 'certification/results/previous',
        method: 'GET',
        params: { certificationType },
      }),
      transformResponse: (response: ApiResponse<CertificationResultsData[]>) => response.data ?? [],
      providesTags: [RTKQueryTag.CertificationPreviousResults],
    }),

    getExamPurchaseData: builder.query<CertificationPurchaseAvailability | null, { certificationType: string }>({
      query: ({ certificationType }) => ({
        url: 'certification/purchase/active-purchase',
        method: 'GET',
        params: { certificationType },
      }),
      transformResponse: (response: ApiResponse<CertificationPurchaseAvailability>) => response?.data ?? null,
      providesTags: [RTKQueryTag.CertificationPurchase],
    }),
  }),
});

export const invalidateCertificationPurchase = () => {
  store.dispatch(
    extendedApiSlice.util.invalidateTags([RTKQueryTag.CertificationPurchase, RTKQueryTag.CertificationPreviousResults]),
  );
};

export const { useCreateCertificationMutation, useGetPreviousResultsQuery, useGetExamPurchaseDataQuery, endpoints } =
  extendedApiSlice;
