import { ActiveElement, Chart, ChartData, ChartEvent, RadialLinearScaleOptions, RadialLinearScale } from 'chart.js';

import { ChartJSContext, CanvasGradient } from 'src/features/dashboard/components/commons/chart';

import { SkillsMatrixScore, GraphColorScheme, ExtendedRadialLinearScale } from './skill-matrix-graphic.types';
import { colorSchemes, roleKeys, RoleKey } from './skill-matrix-graphic.constants';

let gradientCache: CanvasGradient | undefined;
let width = 0;
let height = 0;
let lastRoleId: string | undefined;

export const createRadialGradient = (
  context: ChartJSContext,
  startColor: string,
  middleColor: string,
  endColor: string,
  roleId?: string,
): CanvasGradient | undefined => {
  const { chartArea } = context.chart;
  // initial load
  if (!chartArea) return undefined;

  if (roleId !== lastRoleId) {
    gradientCache = undefined;
    lastRoleId = roleId;
  }

  const chartWidth = chartArea.right - chartArea.left;
  const chartHeight = chartArea.bottom - chartArea.top;

  if (width !== chartWidth || height !== chartHeight) gradientCache = undefined;

  let gradient = gradientCache;

  if (!gradientCache) {
    // Create the gradient on first render or when the size of the chart has changed
    width = chartWidth;
    height = chartHeight;

    const centerX = (chartArea.left + chartArea.right) / 2;
    const centerY = (chartArea.top + chartArea.bottom) / 2;
    const r = Math.min((chartArea.right - chartArea.left) / 2, (chartArea.bottom - chartArea.top) / 2);
    const { ctx } = context.chart;
    gradient = ctx.createRadialGradient(centerX, centerY, 0, centerX, centerY, r);
    gradient.addColorStop(0, startColor);
    gradient.addColorStop(0.5, middleColor);
    gradient.addColorStop(1, endColor);
    gradientCache = gradient;
  }
  return gradient as CanvasGradient;
};

const capScoreAt100 = (score: number) => Math.min(score, 100);

const convertDataForChart = (
  inputData: SkillsMatrixScore[],
  startColor: string,
  middleColor: string,
  endColor: string,
  roleId?: string,
) => [
  {
    data: inputData.map((score) => capScoreAt100(score.score)),
    backgroundColor: (context: ChartJSContext) =>
      createRadialGradient(context, startColor, middleColor, endColor, roleId),
    color: 'transparent',
    datalabels: { display: false },
  },
];

export const getRadarChartData = (
  data: SkillsMatrixScore[],
  currentColorSettings: GraphColorScheme,
  roleId?: string,
): ChartData<'radar'> => ({
  labels: data.map((scoreItem) => {
    const { label } = scoreItem;
    const icon = '➔';

    if (label.includes('&')) {
      const parts = label.split('&');
      if (parts.length === 2) {
        return [`${parts[0] ? parts[0].trim() : ''} &`, `${parts[1] ? parts[1].trim() : ''} ${icon}`];
      }
    }

    const words = label.split(' ');
    words[words.length - 1] += ` ${icon}`;

    return words;
  }),

  datasets: convertDataForChart(
    data,
    currentColorSettings.backgroundGradientStart,
    currentColorSettings.backgroundGradientMiddle,
    currentColorSettings.backgroundEdge,
    roleId,
  ),
});

export const updatePointLabelColors = (
  chart: Chart,
  hoveredIndex: number | null,
  currentIndex: number | null,
  hoverColor: string,
  defaultColor: string,
) => {
  const rScale = chart?.options?.scales?.r as RadialLinearScaleOptions;

  rScale.pointLabels.color = (context: { index: number }) => {
    if (context.index === hoveredIndex) return hoverColor;
    if (context.index === currentIndex) return defaultColor;
    return defaultColor;
  };
};

const getPointLabelItems = (
  radialScale: RadialLinearScale,
): { left: number; right: number; top: number; bottom: number }[] => {
  const extendedScale = radialScale as ExtendedRadialLinearScale;

  // eslint-disable-next-line no-underscore-dangle
  if (extendedScale._pointLabelItems) {
    // eslint-disable-next-line no-underscore-dangle
    return extendedScale._pointLabelItems;
  }

  return [];
};

export const findHoveredLabelIndex = (radialScale: RadialLinearScale, x: number, y: number): number => {
  const pointLabelItems = getPointLabelItems(radialScale);

  return pointLabelItems.findIndex(
    ({ left, right, top, bottom }) => x >= left && x <= right && y >= top && y <= bottom,
  );
};

export const handleLabelHover = (
  event: ChartEvent,
  chart: Chart,
  hoveredIndexRef: { current: number | null },
  hoverColor: string,
  defaultColor: string,
): void => {
  const { x, y } = event as unknown as MouseEvent;
  if (!x || !y) return;

  const radialScale = chart.scales.r as RadialLinearScale;

  const hoveredIndex = findHoveredLabelIndex(radialScale, x, y);
  if (hoveredIndex === -1) {
    // eslint-disable-next-line no-param-reassign
    chart.canvas.style.cursor = 'default';

    if (hoveredIndexRef.current !== null) {
      updatePointLabelColors(chart, null, hoveredIndexRef.current, hoverColor, defaultColor);
      // eslint-disable-next-line no-param-reassign
      hoveredIndexRef.current = null;
      chart.update();
    }

    return;
  }

  if (hoveredIndex === hoveredIndexRef.current) {
    // eslint-disable-next-line no-param-reassign
    chart.canvas.style.cursor = 'pointer';

    return;
  }
  // eslint-disable-next-line no-param-reassign
  chart.canvas.style.cursor = 'pointer';

  // Update colors if hover state changes
  updatePointLabelColors(chart, hoveredIndex, hoveredIndexRef.current, hoverColor, defaultColor);

  // eslint-disable-next-line no-param-reassign
  hoveredIndexRef.current = hoveredIndex;

  chart.update();
};

export const getGraphOptions = ({
  currentColorSettings,
  isClickable,
  onLabelClick,
  hoveredIndexRef,
  dynamicMax,
  isZoomed = false,
  isDashboard,
}: {
  currentColorSettings: GraphColorScheme;
  isClickable: boolean;
  hoveredIndexRef: { current: number | null };
  onLabelClick?: (index: number) => void;
  dynamicMax: number;
  isZoomed: boolean;
  isDashboard: boolean;
}) => ({
  elements: {
    point: { radius: 0, pointHitRadius: 20 },
    line: {
      borderColor: currentColorSettings.borderColor,
      circular: true,
      borderWidth: 1.7,
      lineTension: 0,
    },
  },
  scales: {
    r: {
      angleLines: {
        color: currentColorSettings.grid,
        borderDash: [4, 4],
      },
      grid: {
        color: currentColorSettings.grid,
      },
      pointLabels: {
        display: true,
        color: currentColorSettings.text,
        font: { size: 14, family: 'Source Sans Pro, sans-serif' },
      },
      ticks: {
        // keep ticks transparent to space out grid lines. To preserve grid lines spacing
        color: 'transparent',
        backdropColor: 'transparent',
        StepSize: 12.5,
        count: isZoomed || isDashboard ? 5 : 10,
      },
      beginAtZero: true,
      min: 0,
      max: dynamicMax ?? 100,
    },
  },
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: { display: false },
  },
  onHover: isClickable
    ? (event: ChartEvent, _: ActiveElement[], chart: Chart) => {
        handleLabelHover(event, chart, hoveredIndexRef, currentColorSettings.hoverText, currentColorSettings.text);
      }
    : undefined,

  onClick: isClickable
    ? (event: ChartEvent, _: unknown, chart: Chart) => {
        const { x, y } = event as unknown as MouseEvent;
        if (!x || !y) return;

        const radialScale = chart.scales.r as RadialLinearScale;
        const clickedLabelIndex = findHoveredLabelIndex(radialScale, x, y);

        if (clickedLabelIndex !== -1 && onLabelClick) {
          onLabelClick(clickedLabelIndex);
        }
      }
    : undefined,
});

export const getColorScheme = (isDark: boolean, roleName?: string) => {
  const roleKey = (roleName && roleKeys.includes(roleName as RoleKey) ? roleName : 'foundational') as RoleKey;
  return colorSchemes[roleKey][isDark ? 'dark' : 'light'];
};
