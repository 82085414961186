import { FC, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { ThemeProvider, useTheme } from 'styled-components';
import { darkColors, Modal, StyledButton } from '@tryhackme/thm-ui-components';
import { AnnouncementComponentProps } from 'src/common/interfaces/announcements';
import { usePricing } from 'src/common/hooks';
import { useAnnouncementStorage } from 'src/features/announcements/hooks/use-announcement-storage';
import { Price } from 'src/features/pricing/components/price';
import { useTrackEventMutation } from 'src/common/slices';
import { Countdown } from '../countdown/countdown';
import { useAnnouncement } from '../../hooks/use-announcement';
import { useAnnouncementCta } from '../../hooks/use-announcement-cta';
import { Benefits } from './components/benefits';
import { StyledContent, StyledCenteredContent, StyledHeading, StyledMessage, StyledSubTitle, StyledText, StyledButtons, StyledOldPrice } from './announcement-modal.styles';
export const AnnouncementModal: FC<AnnouncementComponentProps> = ({
  name
}) => {
  const theme = useTheme();
  const {
    announcement,
    shouldPromote
  } = useAnnouncement(name);
  const {
    isSeen,
    markAsSeen
  } = useAnnouncementStorage(announcement);
  const [ctaText, urlToNavigate] = useAnnouncementCta();
  const {
    price: priceStr,
    symbol
  } = usePricing(true);
  const [trackEvent] = useTrackEventMutation();
  const [open, setOpen] = useState(!isSeen);
  const price = Number.parseFloat(priceStr);
  const discountedPrice = price * (100 - (announcement?.amount ?? 0)) / 100;
  useEffect(() => {
    if (isSeen) {
      setOpen(false);
    }
  }, [isSeen]);
  const onOpenChange = (isOpen: boolean) => {
    if (isOpen) return;
    markAsSeen();
    setOpen(false);
  };
  const onCtaClick = () => {
    markAsSeen();
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    trackEvent({
      event: 'click-subscribe-now',
      properties: {
        'clicked-on': 'dashboard-pop-up'
      }
    });
  };
  return announcement ? <ThemeProvider theme={{
    ...theme,
    colors: darkColors
  }}>
      <Modal padding="0" open={open && shouldPromote} onOpenChange={onOpenChange} preventCloseOnOutsideClick>
        <StyledContent>
          <StyledCenteredContent>
            <StyledHeading>SPRING INTO CYBER</StyledHeading>
            <StyledSubTitle>
              <span>{announcement?.amount}% off</span> TryHackMe annual subscription
            </StyledSubTitle>
          </StyledCenteredContent>
          <Countdown includeDays vertical dark startTime={announcement.startTime} endTime={announcement.endTime} />
          <Benefits />
          <StyledCenteredContent>
            <StyledMessage>
              Subscribe for{' '}
              <StyledOldPrice price={price} symbol={symbol}>
                {(int, fraction) => int + fraction}
              </StyledOldPrice>{' '}
              <Price price={discountedPrice} symbol={symbol}>
                {(int, fraction) => int + fraction}
              </Price>
              /year
            </StyledMessage>
            <StyledText>
              Join <span>100,000s+</span> of professionals who have advanced their security careers.
            </StyledText>
          </StyledCenteredContent>
          <StyledButtons>
            <StyledButton as={Link} role="button" to={urlToNavigate} aria-label="Subscribe now" variant="solid" color="primary" onClick={onCtaClick}>
              {ctaText}
            </StyledButton>
          </StyledButtons>
        </StyledContent>
      </Modal>
    </ThemeProvider> : null;
};