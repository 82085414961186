import styled from 'styled-components';

export const StyledTitle = styled.h2`
  font-family: ${({ theme }) => theme.fonts.familySansPro};
  font-size: 2.4rem;
  font-weight: ${({ theme }) => theme.fonts.weight.bold};

  &[data-type='league']::first-letter {
    text-transform: uppercase;
  }
`;
