import { FC } from 'react';
import { Link } from 'react-router-dom';
import { ThemeProvider, useTheme } from 'styled-components';
import { lightColors, StyledButton } from '@tryhackme/thm-ui-components';
import { AnnouncementComponentProps } from 'src/common/interfaces/announcements';
import { useTrackEventMutation } from 'src/common/slices';
import { Countdown } from '../countdown';
import { useAnnouncement } from '../../hooks/use-announcement';
import { useAnnouncementCta } from '../../hooks/use-announcement-cta';
import { AnnouncementBarProps } from './announcement-bar.types';
import { StyledBar, StyledHighlight, StyledText, StyledWrapRow } from './announcement-bar.styles';
export const AnnouncementBar: FC<AnnouncementComponentProps & AnnouncementBarProps> = ({
  name,
  thin
}) => {
  const theme = useTheme();
  const {
    announcement,
    shouldPromote
  } = useAnnouncement(name);
  const [ctaText, urlToNavigate] = useAnnouncementCta();
  const [trackEvent] = useTrackEventMutation();
  const onCtaClick = () => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    trackEvent({
      event: 'click-subscribe-now',
      properties: {
        'clicked-on': 'top-banner'
      }
    });
  };
  return announcement && shouldPromote ? <ThemeProvider theme={{
    ...theme,
    colors: lightColors
  }}>
      <StyledBar $thin={!!thin} data-testid="announcement-bar">
        <StyledWrapRow>
          <StyledText>
            SPRING INTO CYBER: <StyledHighlight>{announcement.amount}% off</StyledHighlight> annual subscriptions!
          </StyledText>
          {!thin && <StyledButton as={Link} to={urlToNavigate} variant="solid" color="primary" onClick={onCtaClick}>
              {ctaText}
            </StyledButton>}
        </StyledWrapRow>
        {/* <StyledTimerMessage>Ends in:</StyledTimerMessage> */}
        <Countdown includeDays vertical dark startTime={announcement.startTime} endTime={announcement.endTime} />
      </StyledBar>
    </ThemeProvider> : null;
};