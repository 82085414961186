import { apiSlice } from 'src/features/api/api-slice';

import {
  GetHacktivitiesSearchQueryParams,
  GetSearchProgressSearchQueryParams,
  HacktivitiesApiResponse,
  SearchProgressResponse,
  ExtendedSearchProps,
  ExtendedSearchKind,
} from './content.types';

export const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getHacktivitiesSearch: builder.query<HacktivitiesApiResponse, GetHacktivitiesSearchQueryParams>({
      query: ({
        kind,
        difficulty,
        order,
        roomType,
        contentSubType,
        povTagFilter,
        page,
        searchText,
        userProgress,
        newLimit,
        from,
      }: GetHacktivitiesSearchQueryParams) => ({
        url: 'hacktivities/extended-search',
        params: {
          kind,
          difficulty,
          order,
          roomType,
          contentSubType,
          povTagFilter,
          page,
          searchText,
          userProgress,
          limit: newLimit ?? 12,
          from,
        },
      }),
      transformResponse: (response: HacktivitiesApiResponse) => {
        const uniqueDocsMap = new Map<string, ExtendedSearchProps>();

        for (const item of response.data.docs) {
          const key = item.kind === ExtendedSearchKind.MODULES ? item.moduleURL : item.code;
          if (!uniqueDocsMap.has(key)) {
            uniqueDocsMap.set(key, item);
          }
        }

        const uniqueDocs: ExtendedSearchProps[] = [...uniqueDocsMap.values()];

        return {
          ...response,
          data: {
            ...response.data,
            docs: uniqueDocs,
            totalDocs: uniqueDocs.length,
          },
        };
      },
    }),
    getSearchProgress: builder.query<SearchProgressResponse, GetSearchProgressSearchQueryParams>({
      query: ({ roomCodes, networkCodes, pathIds, moduleIds }) => ({
        url: 'hacktivities/search-progress',
        params: {
          roomCodes,
          networkCodes,
          pathIds,
          moduleIds,
        },
      }),
    }),
  }),
});

export const { useGetHacktivitiesSearchQuery, useGetSearchProgressQuery, useLazyGetSearchProgressQuery } =
  extendedApiSlice;
