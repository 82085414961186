import type { AppBannerProps } from './app-banner.types';
import { faXmark } from '@fortawesome/pro-regular-svg-icons';
import { darkColors } from '@tryhackme/thm-ui-components';
import { ThemeProvider, useTheme } from 'styled-components';
import { StyledAppBannerCloseButton, StyledAppBannerContainer, StyledContainer } from './app-banner.styles';
export const AppBanner = ({
  dismiss,
  backgroundImage,
  children
}: AppBannerProps) => {
  const theme = useTheme();
  return <ThemeProvider theme={{
    ...theme,
    colors: darkColors
  }} data-sentry-element="ThemeProvider" data-sentry-component="AppBanner" data-sentry-source-file="app-banner.tsx">
      <StyledAppBannerContainer $backgroundImage={backgroundImage} data-sentry-element="StyledAppBannerContainer" data-sentry-source-file="app-banner.tsx">
        <StyledContainer data-sentry-element="StyledContainer" data-sentry-source-file="app-banner.tsx">
          {children}

          <StyledAppBannerCloseButton aria-label="close-notification" icon={faXmark} onClick={dismiss} data-sentry-element="StyledAppBannerCloseButton" data-sentry-source-file="app-banner.tsx" />
        </StyledContainer>
      </StyledAppBannerContainer>
    </ThemeProvider>;
};