import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ChatErrorProps } from '@tryhackme/thm-ui-components';

import {
  TutorDisplayMode,
  TutorDraggingState,
  TutorMessage,
  TutorMessageSender,
  TutorNudge,
  TutorQueuedActionParams,
} from 'src/features/room/components/tutor/tutor.types';

export interface TutorState {
  stateInit: boolean;

  socketRooms: { roomCode: string; joined: boolean; joinedAt: number }[];

  queuedAction: TutorQueuedActionParams | null;

  chat: {
    open: boolean;
    sessionId: string | null;
    messages: TutorMessage[];
    loading: boolean;
    restarting: boolean;
    disabled: boolean;
    width: 40 | 64;
    height: number;
    displayMode: TutorDisplayMode;
    draggingState: TutorDraggingState;
    notificationsEnabled: boolean;
    hasSeenAiTermsOfUseBanner: boolean;
    shouldSeeIntroMessage: boolean;
    introMessage: {
      visible: boolean;
      stuckButtonVisible: boolean;
    };
    nudge: TutorNudge;
    error: ChatErrorProps | null;
  };
}

const initialState: TutorState = {
  // this is to update tutor reducer's state only one time in #useTutorSession
  // to prevent inconsistent state as we don't refetch the user data if state changes
  stateInit: false,
  socketRooms: [],
  queuedAction: null,
  chat: {
    open: false,
    sessionId: null,
    messages: [],
    loading: false,
    restarting: false,
    disabled: false,
    width: 40,
    height: 75.2,
    displayMode: TutorDisplayMode.EMBEDDED,
    draggingState: 'idle',
    notificationsEnabled: true,
    hasSeenAiTermsOfUseBanner: false,
    shouldSeeIntroMessage: true,
    introMessage: {
      visible: true,
      stuckButtonVisible: false,
    },
    nudge: {
      status: 'idle',
      preview: '',
      message: '',
    },
    error: null,
  },
};

export const tutorSlice = createSlice({
  name: 'tutor',
  initialState,
  reducers: {
    addMessages: (state, action: PayloadAction<TutorMessage[]>) => ({
      ...state,
      chat: {
        ...state.chat,
        messages: action.payload,
        loading: false,
        introMessage: {
          ...state.chat.introMessage,
          stuckButtonVisible: action.payload.length <= 1,
        },
      },
    }),

    addMessage: (state, action: PayloadAction<TutorMessage>) => ({
      ...state,
      chat: {
        ...state.chat,
        messages: [...state.chat.messages, action.payload],
        loading: action.payload.sender === TutorMessageSender.USER,
        introMessage: {
          ...state.chat.introMessage,
          stuckButtonVisible: state.chat.messages.length < 1,
        },
      },
    }),

    removeLastUserMessage: (state) => {
      const messages = [...state.chat.messages];

      let lastUserMessageIndex = -1;
      for (let i = messages.length - 1; i >= 0; i--) {
        if (messages[i]?.sender === TutorMessageSender.USER) {
          lastUserMessageIndex = i;
          break;
        }
      }

      if (lastUserMessageIndex !== -1) {
        messages.splice(lastUserMessageIndex, 1);
      }

      return {
        ...state,
        chat: {
          ...state.chat,
          messages,
        },
      };
    },

    clearMessages: (state) => ({
      ...state,
      chat: {
        ...state.chat,
        messages: [],
        loading: true,
        restarting: true,
        sessionId: null,
        introMessage: {
          visible: false,
          stuckButtonVisible: false,
        },
      },
    }),

    invalidateButtons: (state) => ({
      ...state,
      chat: {
        ...state.chat,
        messages: state.chat.messages.map((message) => {
          if (message.sender !== TutorMessageSender.USER) {
            return {
              ...message,
              suggestions: {
                list: [],
                valid: false,
              },
            };
          }

          return message;
        }),
      },
    }),

    addMessageVote: (state, action: PayloadAction<{ vote: TutorMessage['vote']; messageId: string }>) => {
      const { vote, messageId } = action.payload;

      const messages = state.chat.messages.map((message) => {
        if (message.id === messageId) {
          return {
            ...message,
            vote,
          };
        }

        return message;
      });

      return {
        ...state,
        chat: {
          ...state.chat,
          messages,
        },
      };
    },

    openTutor: (state) => ({
      ...state,
      chat: {
        ...state.chat,
        open: true,
      },
    }),

    closeTutor: (state) => ({
      ...state,
      chat: {
        ...state.chat,
        open: false,
      },
    }),

    toggleChatExpansion: (state) => ({
      ...state,
      chat: {
        ...state.chat,
        width: state.chat.width === 40 ? 64 : 40,
      },
    }),

    setChatHeight: (state, action: PayloadAction<number>) => ({
      ...state,
      chat: {
        ...state.chat,
        height: action.payload,
      },
    }),

    setIsLoading: (state, action: PayloadAction<boolean>) => ({
      ...state,
      chat: {
        ...state.chat,
        loading: action.payload,
        introMessage: {
          ...state.chat.introMessage,
          stuckButtonVisible: false,
        },
      },
    }),

    setIsRestarting: (state, action: PayloadAction<boolean>) => ({
      ...state,
      chat: {
        ...state.chat,
        restarting: action.payload,
      },
    }),

    setNotificationsEnabled: (state, action: PayloadAction<boolean>) => ({
      ...state,
      chat: {
        ...state.chat,
        notificationsEnabled: action.payload,
      },
    }),

    setTutorSessionId: (state, action: PayloadAction<string | null>) => ({
      ...state,
      chat: { ...state.chat, sessionId: action.payload },
    }),

    setDisplayMode: (state, action: PayloadAction<TutorDisplayMode>) => ({
      ...state,
      chat: { ...state.chat, displayMode: action.payload },
    }),

    setDraggingState: (state, action: PayloadAction<TutorDraggingState>) => ({
      ...state,
      chat: { ...state.chat, draggingState: action.payload },
    }),

    setIsChatDisabled: (state, action: PayloadAction<boolean>) => ({
      ...state,
      chat: { ...state.chat, disabled: action.payload },
    }),

    closeAiTermsOfUseBanner: (state) => ({
      ...state,
      chat: {
        ...state.chat,
        hasSeenAiTermsOfUseBanner: true,
      },
    }),

    setIntroMessageVisible: (state, action: PayloadAction<boolean>) => ({
      ...state,
      chat: {
        ...state.chat,
        introMessage: {
          ...state.chat.introMessage,
          visible: action.payload,
        },
      },
    }),

    setStuckButtonVisible: (state, action: PayloadAction<boolean>) => ({
      ...state,
      chat: {
        ...state.chat,
        introMessage: {
          ...state.chat.introMessage,
          stuckButtonVisible: action.payload,
        },
      },
    }),

    setChatError: (state, action: PayloadAction<ChatErrorProps | null>) => ({
      ...state,
      chat: {
        ...state.chat,
        loading: false,
        restarting: false,
        error: action.payload,
      },
    }),

    setNudge: (state, action: PayloadAction<TutorState['chat']['nudge']>) => ({
      ...state,
      chat: {
        ...state.chat,
        nudge: action.payload,
      },
    }),

    setNudgeToInactive: (state) => ({
      ...state,
      chat: {
        ...state.chat,
        nudge: {
          ...state.chat.nudge,
          status: 'inactive',
        },
      },
    }),

    initTutorState: (state) => ({ ...state, stateInit: true }),

    clearTutorState: () => ({
      ...initialState,
    }),

    joinSocketRoom: (state, action: PayloadAction<string>) => {
      const currentTime = Date.now();
      return {
        ...state,
        socketRooms: [
          ...state.socketRooms,
          {
            roomCode: action.payload,
            joined: true,
            joinedAt: currentTime,
          },
        ],
      };
    },

    setQueuedAction: (state, action: PayloadAction<TutorQueuedActionParams>) => ({
      ...state,
      queuedAction: action.payload,
      chat: {
        ...state.chat,
        introMessage: {
          ...state.chat.introMessage,
          stuckButtonVisible: false,
        },
      },
    }),

    clearQueuedAction: (state) => ({
      ...state,
      queuedAction: null,
    }),
  },
});

export const {
  openTutor,
  closeTutor,
  toggleChatExpansion,
  setChatHeight,
  setIsLoading,
  setIsRestarting,
  setNotificationsEnabled,
  setTutorSessionId,
  setDisplayMode,
  setDraggingState,
  setIsChatDisabled,
  initTutorState,
  closeAiTermsOfUseBanner,
  addMessages,
  addMessage,
  clearMessages,
  invalidateButtons,
  addMessageVote,
  clearTutorState,
  joinSocketRoom,
  setIntroMessageVisible,
  setStuckButtonVisible,
  setChatError,
  setNudge,
  setNudgeToInactive,
  setQueuedAction,
  clearQueuedAction,
  removeLastUserMessage,
} = tutorSlice.actions;

export const tutorReducer = tutorSlice.reducer;
