import { useState, useMemo, useCallback, useEffect } from 'react';
import { RoomTask } from 'src/common/interfaces/tasks';
import { AnswerPlaceholder } from '../../components/question-and-answer-item/question-and-answer-item.constants';
export const useRoomBreakdown = (tasks?: RoomTask[]) => {
  const [currentTask, setCurrentTask] = useState<RoomTask>();
  const sortedTasks = useMemo(() => [...(tasks || [])].sort((task1, task2) => task1.taskNo - task2.taskNo), [tasks]);
  useEffect(() => {
    if (!currentTask) {
      setCurrentTask(sortedTasks.find(t => {
        const answerableQuestions = t.questions.filter(q => q.progress?.answerDescription !== AnswerPlaceholder.NO_ANSWER_NEEDED);
        return answerableQuestions.length ? answerableQuestions.find(q => !q.progress?.correct) : true;
      }));
    }
  }, [currentTask, sortedTasks]);
  const advanceToNextTask = useCallback(() => setCurrentTask(sortedTasks.find(t => currentTask && t.taskNo === currentTask.taskNo + 1)), [currentTask, sortedTasks]);
  const resetToFirstTask = useCallback(() => setCurrentTask(sortedTasks[0]), [sortedTasks]);
  return {
    currentTask: sortedTasks.find(t => t.taskNo === currentTask?.taskNo),
    advanceToNextTask,
    resetToFirstTask
  };
};