import { BaseQueryFn, FetchBaseQueryError } from '@reduxjs/toolkit/query';

import { apiSlice } from 'src/features/api/api-slice';
import { RTKQueryTag } from 'src/common/constants';
import { ApiResponse, CompanyData, FeatureFlagData, B2CSubscriptionBillingData, UserData } from 'src/common/interfaces';
import { RoomBasicData } from 'src/common/interfaces/room';
import { SubscriptionErrorResponse } from 'src/features/manage-account/subscription/subscription.types';
import { store } from 'src/app/store';

import { ExtendVMTimeResponses, InstanceId } from '../interfaces/instances';
import {
  DashboardNotification,
  DismissUserDashboardNotificationItems,
  EmailVerifcationEmailBody,
  SendEmailVerificationBody,
} from '../interfaces/user';

export const userSelfQueryTag = { type: RTKQueryTag.User, id: 'SELF' };
const INVALID_SUBSCRIPTION_DATA_MESSAGE = 'Unexpected billing data';
export const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getUser: builder.query<ApiResponse<UserData>, void>({
      // Use semi-custom queryFn to prevent request from being sent if user is not logged in
      queryFn: (_args, _api, _extraOptions, baseQuery) => {
        if (process.env.NODE_ENV !== 'test' && !document.cookie.includes('logged-in-hint'))
          return { error: { status: 'CUSTOM_ERROR', error: 'Not logged in' } };
        return baseQuery('/users/self') as ReturnType<BaseQueryFn<string, ApiResponse<UserData>, FetchBaseQueryError>>;
      },
      providesTags: [userSelfQueryTag],
    }),

    getCompany: builder.query<CompanyData, string>({
      query: (companyId: string) => `/companies?companyId=${companyId}`,
      transformResponse: (response: ApiResponse<{ company: CompanyData }>) => response.data?.company as CompanyData,
      providesTags: (result, error, companyId) => [{ type: RTKQueryTag.Company, id: companyId }],
    }),

    getFeatureFlags: builder.query<ApiResponse<FeatureFlagData[]>, void>({
      query: () => '/feature-flags',
      providesTags: [RTKQueryTag.FeatureFlag],
    }),

    updateFeatureFlag: builder.mutation<ApiResponse, FeatureFlagData>({
      query: (featureFlag) => ({
        method: 'PUT',
        url: '/feature-flags',
        body: featureFlag,
      }),
      invalidatesTags: [RTKQueryTag.FeatureFlag],
    }),

    getRoomBasics: builder.query<RoomBasicData, string>({
      query: (roomCode) => `/rooms/basics?roomCode=${roomCode}`,
      transformResponse: (response: ApiResponse<RoomBasicData>) => response.data as RoomBasicData,
    }),

    extendVm: builder.mutation<ApiResponse<ExtendVMTimeResponses>, InstanceId>({
      query: ({ id }) => ({
        url: `vms/extend`,
        method: 'POST',
        body: { id },
      }),
      invalidatesTags: (result, error) => (!error && result ? [RTKQueryTag.RunningInstances] : []),
    }),

    uploadFileToS3: builder.mutation<unknown, { file: File; url: string }>({
      query: ({ file, url }) => ({
        url,
        method: 'PUT',
        body: file,
        headers: {
          'Content-Type': file.type,
        },
      }),
    }),

    updateBillingBanner: builder.mutation<ApiResponse<null>, { companyId: string; configAutomaticBilling: boolean }>({
      query: ({ companyId, configAutomaticBilling }) => ({
        method: 'PUT',
        url: `/companies/billing-banner?companyId=${companyId}`,
        body: { configAutomaticBilling },
      }),
      invalidatesTags: (result, error, { companyId }) => [{ type: RTKQueryTag.Company, id: companyId }],
    }),
    getCurrentB2CSubscriptionBillingData: builder.query<ApiResponse<B2CSubscriptionBillingData>, void>({
      query: () => ({
        url: '/users/subscriptions/current-subscription-billing-data',
        async responseHandler(response: Response) {
          if (!response.ok && response.status === 500) {
            const errorResponse = (await response.json()) as SubscriptionErrorResponse;
            if (errorResponse.message === INVALID_SUBSCRIPTION_DATA_MESSAGE) {
              throw new Error(`Error ${response.status}: ${errorResponse.message}`);
            }
          }

          return response.json();
        },
      }),
      providesTags: [RTKQueryTag.CurrentB2CSubscriptionBillingData],
    }),

    updateCardExpiryNotificationDismissal: builder.mutation<ApiResponse<null>, { lastCardExpiryDismissal: number }>({
      query: ({ lastCardExpiryDismissal }) => ({
        method: 'PUT',
        url: `/users/subscriptions/card-expiry`,
        body: { lastCardExpiryDismissal },
      }),
      invalidatesTags: [RTKQueryTag.User],
    }),
    getDashboardNotifications: builder.query<ApiResponse<DashboardNotification>, void>({
      query: () => '/users/dashboard-notifications',
      providesTags: [RTKQueryTag.DashboardNotifications],
    }),
    updateDashboardNotification: builder.mutation<
      ApiResponse<null>,
      { notification: DismissUserDashboardNotificationItems }
    >({
      query: ({ notification }) => ({
        method: 'PUT',
        url: `/users/dashboard-notifications/dismiss`,
        body: { notification },
      }),
      invalidatesTags: [RTKQueryTag.DashboardNotifications],
    }),
    sendBusinessEmailVerificationCode: builder.mutation<ApiResponse<{ data: null }>, SendEmailVerificationBody>({
      query: (body) => ({ method: 'POST', url: 'users/send-business-email-verification', body }),
    }),
    verifyBusinessEmail: builder.mutation<ApiResponse<{ data: null }>, EmailVerifcationEmailBody>({
      query: (body) => ({ method: 'POST', url: 'users/verify-business-email', body }),
    }),
  }),
});

export const invalidateUserQuery = () => {
  store.dispatch(extendedApiSlice.util.invalidateTags([userSelfQueryTag]));
};

export const {
  useGetUserQuery,
  useLazyGetUserQuery,
  useGetCompanyQuery,
  useGetFeatureFlagsQuery,
  useUpdateFeatureFlagMutation,
  useGetRoomBasicsQuery,
  useExtendVmMutation,
  useUploadFileToS3Mutation,
  useUpdateBillingBannerMutation,
  useGetCurrentB2CSubscriptionBillingDataQuery,
  useUpdateCardExpiryNotificationDismissalMutation,
  useGetDashboardNotificationsQuery,
  useUpdateDashboardNotificationMutation,
  useSendBusinessEmailVerificationCodeMutation,
  useVerifyBusinessEmailMutation,
  endpoints: commonEndpoints,
} = extendedApiSlice;

export * from './analytics';
