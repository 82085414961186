import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface CampaignState {
  modalIsShown: boolean;
}

const initialState: CampaignState = {
  modalIsShown: false,
};

const campaignsSlice = createSlice({
  name: 'campaigns',
  initialState,
  reducers: {
    toggleModal(state, action: PayloadAction<boolean>) {
      // eslint-disable-next-line no-param-reassign
      state.modalIsShown = action.payload;
    },
  },
});

export const { toggleModal } = campaignsSlice.actions;

const campaignsReducer = campaignsSlice.reducer;
export { campaignsReducer };
