/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useCallback, useRef } from 'react';

import { useAppSelector } from 'src/app/hooks';
import {
  ONE_DAY_IN_MS,
  TUTOR_INTERACTED_WITH_TIMESTAMP,
  TUTOR_SEEN_ROOMS,
  TutorEngagementPrompt,
} from 'src/common/constants';

interface UseTutorEngagementPromptProps {
  roomCode: string;
  firstTimeDelayMs?: number;
  subsequentDelayMs?: number;
  engagementPromptDurationMs?: number;
}

export const useTutorEngagementPrompt = ({
  roomCode,
  firstTimeDelayMs = 60_000,
  subsequentDelayMs = 30_000,
  engagementPromptDurationMs = 10_000,
}: UseTutorEngagementPromptProps) => {
  const {
    chat: { notificationsEnabled = true, nudge },
  } = useAppSelector((state) => state.tutor);

  const [engagementPromptMessage, setEngagementPromptMessage] = useState(TutorEngagementPrompt.DEFAULT);
  const [showEngagementPrompt, setShowEngagementPrompt] = useState(false);
  const [hasCurrentlyInteracted, setHasCurrentlyInteracted] = useState(false);
  const [hasPromptBeenShown, setHasPromptBeenShown] = useState(false);

  const tutorSeenRooms: string[] = JSON.parse(localStorage.getItem(TUTOR_SEEN_ROOMS) ?? '[]') as string[];

  const isFirstTimeEver = tutorSeenRooms.length === 0;
  const isSeenInCurrentRoom = tutorSeenRooms.includes(roomCode);

  const isFirstRenderRef = useRef(true);
  const engagementPromptTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const hideTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  const getEngagementPromptMessage = useCallback(() => {
    if (isFirstTimeEver) {
      return TutorEngagementPrompt.FIRST_TIME;
    }
    if (!isSeenInCurrentRoom) {
      return TutorEngagementPrompt.SUBSEQUENT;
    }
    return TutorEngagementPrompt.DEFAULT;
  }, [roomCode]);

  useEffect(() => {
    if (isFirstRenderRef.current) {
      isFirstRenderRef.current = false;

      if (!isSeenInCurrentRoom) {
        tutorSeenRooms.push(roomCode);
        localStorage.setItem(TUTOR_SEEN_ROOMS, JSON.stringify(tutorSeenRooms));
      }
    }
  }, [roomCode, isSeenInCurrentRoom, tutorSeenRooms]);

  useEffect(() => {
    const showPrompt = () => {
      if (hasPromptBeenShown || !notificationsEnabled || nudge.status !== 'idle') return;
      setShowEngagementPrompt(true);
      setEngagementPromptMessage(getEngagementPromptMessage());
      hideTimeoutRef.current = setTimeout(() => {
        setShowEngagementPrompt(false);
        setHasPromptBeenShown(true);
        localStorage.setItem(TUTOR_INTERACTED_WITH_TIMESTAMP, Date.now().toString());
      }, engagementPromptDurationMs);
    };

    if (hasCurrentlyInteracted || !notificationsEnabled) return;

    if (isFirstTimeEver) {
      engagementPromptTimeoutRef.current = setTimeout(showPrompt, firstTimeDelayMs);
    } else {
      const lastInteractionTime = Number.parseInt(localStorage.getItem(TUTOR_INTERACTED_WITH_TIMESTAMP) ?? '0', 10);
      const currentTime = Date.now();
      const timeSinceLastInteraction = currentTime - lastInteractionTime;
      engagementPromptTimeoutRef.current = setTimeout(
        showPrompt,
        timeSinceLastInteraction > ONE_DAY_IN_MS ? subsequentDelayMs : firstTimeDelayMs,
      );
    }

    return () => {
      if (engagementPromptTimeoutRef.current) {
        clearTimeout(engagementPromptTimeoutRef.current);
      }
      if (hideTimeoutRef.current) {
        clearTimeout(hideTimeoutRef.current);
      }
    };
  }, [
    hasCurrentlyInteracted,
    hasPromptBeenShown,
    firstTimeDelayMs,
    subsequentDelayMs,
    engagementPromptDurationMs,
    notificationsEnabled,
    nudge,
    getEngagementPromptMessage,
  ]);

  const handleUserInteractionWithTutor = useCallback(() => {
    setHasCurrentlyInteracted(true);
    setShowEngagementPrompt(false);
    if (engagementPromptTimeoutRef.current) {
      clearTimeout(engagementPromptTimeoutRef.current);
    }
    if (hideTimeoutRef.current) {
      clearTimeout(hideTimeoutRef.current);
    }
    localStorage.setItem(TUTOR_INTERACTED_WITH_TIMESTAMP, Date.now().toString());
  }, []);

  const resetEngagementPrompt = useCallback(() => {
    setHasCurrentlyInteracted(false);
    setShowEngagementPrompt(false);
  }, []);

  return {
    engagementPromptMessage,
    showEngagementPrompt,
    hasPromptBeenShown,
    handleUserInteractionWithTutor,
    resetEngagementPrompt,
  };
};
