import { useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBookOpen, faBriefcase, faBrowser, faBuildingColumns, faBullseyeArrow, faChalkboardUser, faCircleDollar, faCrown, faEllipsisStroke, faFlagSwallowtail, faGrid2Plus, faTrophy, faUserGroup, faPen, faUpload, faTableTree, faFolderOpen, faTerminal, faMedal, faNetworkWired } from '@fortawesome/pro-regular-svg-icons';
import { ItemProps } from '@tryhackme/thm-ui-components/dist/components/atoms/desktop-nav/desktop-nav.types';
import { useGetFeatureFlagsQuery, useGetUserQuery } from 'src/common/slices';
import { B2BUserHelper, extractB2BUserRoles } from 'src/common/helpers';
import { FeatureFlagName } from 'src/common/constants';
import { UserRoles } from 'src/common/enums';
import { useFeatureIsOn } from 'src/common/growthbook';
import { CertificationType, CertificationExamAvailabilityStatus } from 'src/common/enums/certification';
import { useGetExamPurchaseDataQuery } from 'src/features/certifications/junior-security-analyst/junior-security-analysts.slice';
import { useGetWorkspaceQuery } from '../management-dashboard/workspace/workspace.slice';
import { CustomNavbarIcon } from './components';
import { CustomNavbarIconTypes } from './components/custom-navbar-icon/custom-navbar-icon.types';
const conditionalLink = <T,>(condition: boolean, link: T) => condition ? [link] : [];
export const useMainNavigationLinks = (): ItemProps[] => {
  const {
    data: featureFlagsQueryData
  } = useGetFeatureFlagsQuery();
  const {
    data: getUserQueryData
  } = useGetUserQuery();
  const isWeeklyLeaguesEnabled = useFeatureIsOn('weekly-leagues-page');
  const isNetworksV2Enabled = useFeatureIsOn('networks-via-iac');
  const isCertificationNavEnabled = useFeatureIsOn('certification-on-learn-main-nav');
  const {
    data: WorkspaceData
  } = useGetWorkspaceQuery(undefined, {
    skip: !getUserQueryData?.data?.user
  });
  const {
    data: purchaseData
  } = useGetExamPurchaseDataQuery({
    certificationType: CertificationType.SAL1
  }, {
    skip: !getUserQueryData?.data?.user
  });
  const certificationLink = purchaseData?.status === CertificationExamAvailabilityStatus.UNAVAILABLE || !purchaseData ? '/certification/security-analyst-level-1' : '/certification/security-analyst-level-1/details';
  return useMemo(() => {
    const enabledFeatureFlags = featureFlagsQueryData?.data?.filter(flag => flag.enabled).map(flag => flag.name) ?? [];
    const userData = getUserQueryData?.data?.user;
    const isAdmin = userData?.accountType === 'admin';
    const userB2BUserRoles = extractB2BUserRoles(userData);
    const isRoomDeveloper = userData?.roles?.includes(UserRoles.ROOM_MAKER);
    const isContentDevInternal = (isAdmin || userData?.roles?.includes(UserRoles.CONTENT_DEV_INTERNAL)) ?? false;
    const {
      isBusinessUser
    } = B2BUserHelper(userData);
    const isCtfBuilderEnabled = enabledFeatureFlags.includes(FeatureFlagName.REACT_CTF_BUILDER_PAGE);
    const isWorkspaceUser = !!WorkspaceData?.hasJoinedWorkspace;
    const isCtfBuilderLinkActive = isBusinessUser || isWorkspaceUser;
    const Learn = ({
      name: 'Learn',
      icon: <FontAwesomeIcon icon={faBookOpen} />,
      visibleIn: 'both',
      subItems: [{
        name: 'Learn',
        icon: <FontAwesomeIcon icon={faBookOpen} />,
        description: 'Hands-on training',
        href: '/hacktivities',
        visibleIn: 'both'
      }, {
        name: 'Challenges',
        icon: <FontAwesomeIcon icon={faBullseyeArrow} />,
        description: 'Reinforce your learning',
        href: `/hacktivities/challenges`,
        visibleIn: 'both',
        isNew: false
      }, ...conditionalLink(isCertificationNavEnabled, ({
        name: 'Get certified',
        icon: <CustomNavbarIcon type={CustomNavbarIconTypes.CERTIFICATION} />,
        description: 'Verify your skills',
        href: certificationLink,
        visibleIn: 'both',
        isNew: true
      } as const)), ...conditionalLink(enabledFeatureFlags.includes(FeatureFlagName.REACT_USER_ASSIGNMENTS_PAGE) && userB2BUserRoles.isB2BUserActive, ({
        name: 'Assignments',
        icon: <FontAwesomeIcon icon={faPen} />,
        description: 'Learn, practice, progress',
        href: '/assignments',
        visibleIn: 'both'
      } as const)), ...conditionalLink(enabledFeatureFlags.includes(FeatureFlagName.SOC_SIM_ON_LEARN_MAIN_NAV), ({
        name: 'SOC Simulator',
        icon: <CustomNavbarIcon type={CustomNavbarIconTypes.SOC_SIM} />,
        description: 'Triage alerts in realtime',
        href: '/soc-sim/?ref=nav',
        visibleIn: 'both',
        isNew: false
      } as const))]
    } satisfies ItemProps);
    return [{
      name: 'Dashboard',
      icon: <FontAwesomeIcon icon={faBrowser} />,
      href: '/dashboard',
      visibleIn: 'private',
      visibleInSplitScreen: true
    }, {
      ...Learn,
      visibleIn: 'public',
      visibleInSplitScreen: true
    }, {
      ...Learn,
      visibleIn: 'private'
    }, {
      name: 'Compete',
      icon: <FontAwesomeIcon icon={faTrophy} />,
      visibleIn: 'public',
      visibleInSplitScreen: true,
      subItems: [...conditionalLink(isWeeklyLeaguesEnabled, ({
        name: 'Weekly Leagues',
        icon: <FontAwesomeIcon icon={faMedal} />,
        description: 'Earn points & Win',
        href: '/leagues',
        visibleIn: 'private'
      } as const)), {
        name: 'King of the Hill',
        icon: <FontAwesomeIcon icon={faCrown} />,
        description: 'Attack & Defend',
        href: '/games/koth',
        visibleIn: 'public'
      }, {
        name: 'Leaderboards',
        icon: <FontAwesomeIcon icon={faTrophy} />,
        description: 'Platform Rankings',
        href: '/leaderboards',
        visibleIn: 'public'
      }]
    }, {
      name: 'Compete',
      icon: <FontAwesomeIcon icon={faTrophy} />,
      visibleIn: 'private',
      subItems: [...conditionalLink(isWeeklyLeaguesEnabled, ({
        name: 'Weekly Leagues',
        icon: <FontAwesomeIcon icon={faMedal} />,
        description: 'Earn points & Win',
        href: '/leagues',
        visibleIn: 'private'
      } as const)), {
        name: 'Leaderboards',
        icon: <FontAwesomeIcon icon={faTrophy} />,
        description: 'Platform Rankings',
        href: '/leaderboards',
        visibleIn: 'private'
      }, {
        name: 'King of the Hill',
        icon: <FontAwesomeIcon icon={faCrown} />,
        description: 'Attack & Defend',
        href: '/games/koth',
        visibleIn: 'private'
      }, {
        name: 'Workspace',
        icon: <FontAwesomeIcon icon={faUserGroup} />,
        description: 'Compete & Collaborate',
        href: '/workspace',
        visibleIn: 'private'
      }, ...conditionalLink(isCtfBuilderEnabled && isCtfBuilderLinkActive, ({
        name: 'CTF Builder',
        icon: <FontAwesomeIcon icon={faFlagSwallowtail} />,
        href: '/ctf-builder',
        description: 'Create your own CTF event',
        visibleIn: 'private'
      } as const))]
    }, ...conditionalLink(Boolean(isRoomDeveloper), ({
      name: 'Develop',
      icon: <FontAwesomeIcon icon={faTerminal} />,
      subItems: [{
        name: 'Upload',
        icon: <FontAwesomeIcon icon={faUpload} />,
        description: 'Upload VMs & Files',
        href: '/upload',
        visibleIn: 'private'
      }, {
        name: 'Manage Rooms',
        icon: <FontAwesomeIcon icon={faTableTree} />,
        description: 'Create and Manage Rooms',
        href: '/rooms?tab=manage-rooms',
        visibleIn: 'private'
      }, ...conditionalLink(isNetworksV2Enabled && isContentDevInternal, ({
        name: 'Manage Networks',
        icon: <FontAwesomeIcon icon={faNetworkWired} />,
        description: 'Create and Manage Networks',
        href: '/networks',
        visibleIn: 'private'
      } as const)), {
        name: 'Your Material',
        icon: <FontAwesomeIcon icon={faFolderOpen} />,
        description: 'View your uploads',
        href: '/your-material',
        visibleIn: 'private'
      }],
      visibleIn: 'private'
    } as ItemProps)), {
      name: 'For Education',
      icon: <FontAwesomeIcon icon={faBuildingColumns} />,
      subItems: [{
        name: 'Teaching',
        icon: <FontAwesomeIcon icon={faChalkboardUser} />,
        description: 'Use our security labs',
        href: '/classrooms',
        visibleIn: 'public'
      }, {
        name: 'Create labs',
        icon: <FontAwesomeIcon icon={faGrid2Plus} />,
        description: 'Upload & deploy VMs',
        href: '/develop-rooms',
        visibleIn: 'public'
      }],
      visibleIn: 'public'
    }, {
      name: 'For Business',
      icon: <FontAwesomeIcon icon={faBriefcase} />,
      href: 'https://business.tryhackme.com',
      visibleIn: 'public',
      visibleInSplitScreen: true
    }, {
      name: 'Pricing',
      icon: <FontAwesomeIcon icon={faCircleDollar} />,
      href: '/pricing',
      visibleIn: 'public'
    }, {
      name: 'Other',
      icon: <FontAwesomeIcon icon={faEllipsisStroke} />,
      subItems: [...conditionalLink(userB2BUserRoles.isCompanyAdmin, {
        name: 'Management Dashboard',
        href: `/client/${userData?.companies?.[0]?._id ?? ''}`
      }), {
        name: 'For Business',
        href: 'https://business.tryhackme.com'
      }, {
        name: 'For Education',
        href: '/classrooms'
      }, {
        name: 'Career Hub',
        href: '/careers'
      }, {
        name: 'Resources',
        href: '/resources/blog'
      }, {
        name: 'Glossary',
        href: '/glossary'
      }, {
        name: 'Buy Vouchers',
        href: '/subscriptions'
      }, {
        name: 'Develop Rooms',
        href: '/develop-rooms'
      }, {
        name: 'Swag Shop',
        href: '/swag-shop'
      }],
      visibleIn: 'private',
      visibleInSplitScreen: true
    }];
  }, [featureFlagsQueryData, getUserQueryData?.data?.user, certificationLink, isCertificationNavEnabled, isWeeklyLeaguesEnabled, isNetworksV2Enabled, WorkspaceData?.hasJoinedWorkspace]);
};