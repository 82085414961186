import { LoaderFunctionArgs, redirect } from 'react-router-dom';

import { store } from 'src/app/store';
import { commonEndpoints } from 'src/common/slices';
import { FeatureFlagName, LOGIN_REFERRER } from 'src/common/constants';
import { UserAccountTypes, UserGoal, UserLoginTypes, UserRoles } from 'src/common/enums';
import { featureFlagToRoomCodesMap } from 'src/common/constants/rooms-rebuild-waves';
import { gb, gbPromise } from 'src/common/growthbook';
import { AppFeatures } from 'src/common/interfaces/growthbook-generated-types';

import * as RouteLoaders from './route-loaders';

type CustomLoaderFunction = (loadersArgs: LoaderFunctionArgs) => Promise<ReturnType<typeof redirect> | null>;

const isLoaderValid = (result: boolean | null): boolean => !!result || result === null;

export const getLegacyUrlString = (url: URL) => {
  const isDevelopment = process.env.NODE_ENV === 'development';
  const origin = isDevelopment ? 'http://localhost:1337' : window.location.origin.toString();
  return origin + url.pathname.replace('/r', '') + url.search;
};

// eslint-disable-next-line @typescript-eslint/require-await -- to conform to the type
export const backToTheLegacyLoader: CustomLoaderFunction = async ({ request }) => {
  if (gb.isOn('remove-slash-r-prefix')) {
    // eslint-disable-next-line no-console
    console.error(`Unexpected legacy redirect for ${request.url}`);
    // in this case legacy redirect should never happen, so throw a 404
    return redirect('/not-found');
  }
  const urlObject = new URL(request.url);
  const legacyUrlString = getLegacyUrlString(urlObject);
  return redirect(legacyUrlString);
};

/**
 * !!!
 * For most pages and components we currently expect to load the component and show
 * a skeleton UI while the data loads instead of using this protector.
 * !!!
 */
export const routeProtector = () => {
  const protectorArray: CustomLoaderFunction[] = [];
  // this is the final function that gets returned after each chained function call
  const processFlow = async (loadersArgs: LoaderFunctionArgs) => {
    const promises = protectorArray.map((protector) => protector(loadersArgs));
    const results = await Promise.all(promises);
    const redirectResponse = results.find((result) => result !== null);
    if (redirectResponse) return redirectResponse;
    return null;
  };
  // the below functions are the chainable, each adding a new protector to the protectorArray
  // they are defined directly as properties of the processFlow function object (JavaScript is weird)
  processFlow.requireLogin = (redirectUrl?: string) => {
    protectorArray.push(async ({ request }) => {
      const query = store.dispatch(commonEndpoints.getUser.initiate());
      try {
        await query.unwrap();
      } catch {
        const referrer = new URL(request.url).pathname.replace('/r/', '/');
        window.sessionStorage.setItem(LOGIN_REFERRER, referrer);
        return redirect(redirectUrl || '/login');
      } finally {
        // ! It is important to unsubscribe here, otherwise subscription will always be active.
        query.unsubscribe();
      }
      return null;
    });
    return processFlow;
  };
  processFlow.requireNotLoggedIn = (redirectUrl: string) => {
    protectorArray.push(async ({ request }) => {
      const query = store.dispatch(commonEndpoints.getUser.initiate());
      try {
        await query.unwrap();
        // Preserve the query string when redirecting
        const url = new URL(redirectUrl, window.location.origin); // Ensure absolute URL handling
        const originalParams = new URLSearchParams(window.location.search);
        const paramsFromRequest = new URL(request.url).searchParams;

        // Append new params to the existing ones in redirectUrl
        for (const [key, value] of originalParams) {
          if (!url.searchParams.has(key)) url.searchParams.append(key, value);
        }

        for (const [key, value] of paramsFromRequest) {
          if (!url.searchParams.has(key)) url.searchParams.append(key, value);
        }

        return redirect(url.toString());
      } catch {
        return null;
      } finally {
        query.unsubscribe();
      }
    });
    return processFlow;
  };
  processFlow.requireCorporatePartner = () => {
    protectorArray.push(async ({ params }) => {
      const query = store.dispatch(commonEndpoints.getUser.initiate());
      try {
        const { data } = await query.unwrap();
        const hasCompanies = !!data?.user.companies.find(({ _id }) => _id === params?.companyId);
        if (!hasCompanies) throw new Error('user is not part of the company');
      } catch {
        return redirect('/login');
      } finally {
        query.unsubscribe();
      }
      return null;
    });
    return processFlow;
  };
  processFlow.requireAdmin = () => {
    protectorArray.push(async () => {
      const query = store.dispatch(commonEndpoints.getUser.initiate());
      try {
        const { data } = await query.unwrap();
        const isAdmin = !!(data?.user.accountType === UserAccountTypes.ADMIN);
        if (!isAdmin) throw new Error('user is not an admin');
      } catch {
        return redirect('/404');
      } finally {
        query.unsubscribe();
      }
      return null;
    });
    return processFlow;
  };
  processFlow.requireFeatureFlag = (flagName: FeatureFlagName | keyof AppFeatures) => {
    protectorArray.push(async (loadersArgs) => {
      if (Object.values(FeatureFlagName).includes(flagName as FeatureFlagName)) {
        const query = store.dispatch(commonEndpoints.getFeatureFlags.initiate());
        try {
          const result = await query.unwrap();
          if (!result?.data?.find((flag) => flag.name === flagName)?.enabled) {
            return await backToTheLegacyLoader(loadersArgs);
          }
        } catch {
          return redirect('/500');
        } finally {
          query.unsubscribe();
        }
      } else {
        await gbPromise;
        if (!gb.isOn(flagName as keyof AppFeatures)) {
          return redirect('/not-found');
        }
      }
      return null;
    });
    return processFlow;
  };
  processFlow.requireRoomsRebuildAccess = () => {
    protectorArray.push(async (loadersArgs) => {
      const query = store.dispatch(commonEndpoints.getFeatureFlags.initiate());
      try {
        const result = await query.unwrap();
        if (!result?.data) throw new Error('No feature flags found');
        const roomCode = loadersArgs.params?.roomCode;
        if (!roomCode) return null;

        const isAccessAllowed = result.data.some((flag) => {
          if (flag.name === FeatureFlagName.ROOMS_REBUILD && flag.enabled) return true;
          // all irrelevant flags will be skipped as they are not in featureFlagToRoomCodesMap
          if (flag.enabled) {
            const waveRoomCodes = featureFlagToRoomCodesMap[flag.name as keyof typeof FeatureFlagName];
            if (waveRoomCodes?.includes(roomCode)) return true;
          }
          return false;
        });

        return isAccessAllowed ? null : await backToTheLegacyLoader(loadersArgs);
      } catch {
        return redirect('/500');
      } finally {
        query.unsubscribe();
      }
    });
    return processFlow;
  };
  processFlow.requireBusinessUserOrCorporatePartner = () => {
    protectorArray.push(async () => {
      try {
        const isBusinessUser = await RouteLoaders.isBusinessUserOrCoporatePartner();
        if (!isBusinessUser) throw new Error('user is not part of a company');
      } catch {
        return redirect('/login');
      }
      return null;
    });
    return processFlow;
  };
  processFlow.requireAnalyst = () => {
    protectorArray.push(async () => {
      const query = store.dispatch(commonEndpoints.getUser.initiate());
      try {
        const { data } = await query.unwrap();
        const isAnalyst = !!(
          data?.user.accountType === UserAccountTypes.ADMIN || data?.user.roles?.includes(UserRoles.ANALYST)
        );
        if (!isAnalyst) throw new Error('user is not an admin or analyst');
      } catch {
        return redirect('/404');
      } finally {
        query.unsubscribe();
      }
      return null;
    });
    return processFlow;
  };
  processFlow.requireMod = () => {
    protectorArray.push(async () => {
      const query = store.dispatch(commonEndpoints.getUser.initiate());
      try {
        const { data } = await query.unwrap();
        const isMod = !!(
          data?.user.accountType === UserAccountTypes.ADMIN || data?.user.roles?.includes(UserRoles.MOD)
        );
        if (!isMod) throw new Error('user is not an admin or mod');
      } catch {
        return redirect('/404');
      } finally {
        query.unsubscribe();
      }
      return null;
    });
    return processFlow;
  };
  processFlow.requireTester = () => {
    protectorArray.push(async () => {
      const query = store.dispatch(commonEndpoints.getUser.initiate());
      try {
        const { data } = await query.unwrap();

        const isTester = !!(
          data?.user.accountType === UserAccountTypes.ADMIN ||
          data?.user.roles?.includes(UserRoles.TESTER) ||
          data?.user.roles?.includes(UserRoles.BETA_TESTER)
        );
        if (!isTester) throw new Error('user is not an admin or tester or beta-tester');
      } catch {
        return redirect('/404');
      } finally {
        query.unsubscribe();
      }
      return null;
    });
    return processFlow;
  };
  processFlow.requireSDR = () => {
    protectorArray.push(async () => {
      const query = store.dispatch(commonEndpoints.getUser.initiate());
      try {
        const { data } = await query.unwrap();
        const isSDR = !!(
          data?.user.accountType === UserAccountTypes.ADMIN ||
          data?.user.roles?.includes(UserRoles.SDR) ||
          data?.user.roles?.includes(UserRoles.BDM)
        );
        if (!isSDR) throw new Error('user is not an admin or SDR');
      } catch {
        return redirect('/404');
      } finally {
        query.unsubscribe();
      }
      return null;
    });
    return processFlow;
  };
  processFlow.requireBDM = () => {
    protectorArray.push(async () => {
      const query = store.dispatch(commonEndpoints.getUser.initiate());
      try {
        const { data } = await query.unwrap();
        const isBDM = !!(
          data?.user.accountType === UserAccountTypes.ADMIN || data?.user.roles?.includes(UserRoles.BDM)
        );
        if (!isBDM) throw new Error('user is not an admin or BDM');
      } catch {
        return redirect('/404');
      } finally {
        query.unsubscribe();
      }
      return null;
    });
    return processFlow;
  };
  processFlow.requireSDROrAnalyst = () => {
    protectorArray.push(async () => {
      const query = store.dispatch(commonEndpoints.getUser.initiate());
      try {
        const { data } = await query.unwrap();
        const isSDRorAnalyst = !!(
          data?.user.accountType === UserAccountTypes.ADMIN ||
          data?.user.roles?.includes(UserRoles.SDR) ||
          data?.user.roles?.includes(UserRoles.BDM) ||
          data?.user.roles?.includes(UserRoles.ANALYST)
        );
        if (!isSDRorAnalyst) throw new Error('user is not an admin or SDR or BDM or Analyst');
      } catch {
        return redirect('/404');
      } finally {
        query.unsubscribe();
      }
      return null;
    });
    return processFlow;
  };
  processFlow.requireLocalUser = () => {
    protectorArray.push(async () => {
      const query = store.dispatch(commonEndpoints.getUser.initiate());
      try {
        const { data } = await query.unwrap();
        const isLocalUser = data?.user.local.loginType === UserLoginTypes.LOCAL;
        if (!isLocalUser) throw new Error('user is not a local user');
      } catch {
        return redirect('/404');
      } finally {
        query.unsubscribe();
      }
      return null;
    });

    return processFlow;
  };
  processFlow.requireTagAdmin = () => {
    protectorArray.push(async () => {
      const query = store.dispatch(commonEndpoints.getUser.initiate());
      try {
        const { data } = await query.unwrap();
        const isTaggingAdmin = !!(
          data?.user.accountType === UserAccountTypes.ADMIN || data?.user.roles?.includes(UserRoles.TAG_ADMIN)
        );
        if (!isTaggingAdmin) throw new Error('user is not an admin or tag admin');
      } catch {
        return redirect('/404');
      } finally {
        query.unsubscribe();
      }
      return null;
    });
    return processFlow;
  };
  processFlow.requireSSOFeatureEnabled = () => {
    protectorArray.push(async ({ params }) => {
      const { companyId } = params;

      if (!companyId) return redirect('/dashboard');

      const query = store.dispatch(commonEndpoints.getCompany.initiate(companyId));
      try {
        const { features } = await query.unwrap();
        if (!features.sso) throw new Error('SSO is not enabled for company');
      } catch {
        return redirect(`/client/${companyId}`);
      } finally {
        query.unsubscribe();
      }
      return null;
    });
    return processFlow;
  };

  processFlow.requireCompletedOnboardingAndWelcome = () => {
    protectorArray.push(async () => {
      const query = store.dispatch(commonEndpoints.getUser.initiate());

      try {
        const { data } = await query.unwrap();

        if (data) {
          if (
            gb.isOn('implement-segments-2025') &&
            [UserGoal.EVALUTE_THM, UserGoal.TRAIN_TEAM].includes(data?.user?.demographics?.goal as UserGoal)
          ) {
            const hasCompletedOnboarding = !!data?.user?.demographics?.howHeard;
            if (!hasCompletedOnboarding) {
              return redirect('/onboarding');
            }
          } else {
            const hasCompletedOnboarding = !!data?.user?.demographics?.howHeard;
            const hasCompletedWelcome = !!data?.user?.local?.path;
            const isB2BUser =
              (data?.user?.companies && data.user.companies.length > 0) ||
              data?.user?.subscriptions?.some((subscription) => subscription.companyId != null);

            if (!hasCompletedOnboarding && !hasCompletedWelcome) {
              return redirect('/r/onboarding');
            }
            if (!hasCompletedWelcome && !isB2BUser) {
              return redirect('/r/welcome');
            }
          }
        }
      } catch {
        // The user should be logged out if we get here
        return null;
      } finally {
        query.unsubscribe();
      }
      return null;
    });
    return processFlow;
  };

  processFlow.requireNotSubscribed = () => {
    protectorArray.push(async (loadersArgs) => {
      const query = store.dispatch(commonEndpoints.getUser.initiate());

      try {
        const { data } = await query.unwrap();
        const isPremiumUser = data?.user?.isPremium && !data?.user?.isPaused;

        if (isPremiumUser) {
          return await backToTheLegacyLoader(loadersArgs);
        }
      } catch {
        // The user should be logged out if we get here
        return null;
      } finally {
        query.unsubscribe();
      }
      return null;
    });
    return processFlow;
  };
  processFlow.requireOneOfRoles = (roles: UserRoles[]) => {
    protectorArray.push(async () => {
      const query = store.dispatch(commonEndpoints.getUser.initiate());
      try {
        const { data } = await query.unwrap();
        const hasRoleMatching = data?.user.roles?.find((role) => roles.includes(role));
        const roleMatched = !!(data?.user.accountType === UserAccountTypes.ADMIN || hasRoleMatching);
        if (!roleMatched) throw new Error('user is not an admin or has no matching role');
      } catch {
        return redirect('/404');
      } finally {
        query.unsubscribe();
      }
      return null;
    });
    return processFlow;
  };

  processFlow.requireNotOneOfRoles = (roles: UserRoles[]) => {
    protectorArray.push(async () => {
      const query = store.dispatch(commonEndpoints.getUser.initiate());
      try {
        const { data } = await query.unwrap();
        const hasRoleMatching = data?.user.roles?.find((role) => roles.includes(role));
        if (hasRoleMatching) throw new Error('user has a matching role');
      } catch {
        return redirect('/404');
      } finally {
        query.unsubscribe();
      }
      return null;
    });
    return processFlow;
  };

  processFlow.requireSocSimFeatureEnabled = () => {
    protectorArray.push(async ({ params }) => {
      const { companyId } = params;

      if (!companyId) return redirect('/dashboard');

      const query = store.dispatch(commonEndpoints.getCompany.initiate(companyId));
      try {
        const { features } = await query.unwrap();
        if (!features.socSim) throw new Error('SOC SIM is not enabled for company');
      } catch {
        return redirect(`/client/${companyId}`);
      } finally {
        query.unsubscribe();
      }
      return null;
    });
    return processFlow;
  };

  processFlow.requirePublicSocSimFeatFlag = () => {
    protectorArray.push(async () => {
      const featureFlagQuery = store.dispatch(commonEndpoints.getFeatureFlags.initiate());
      try {
        const featureFlagResult = await featureFlagQuery.unwrap();
        const isPublicSocSimEnabled = featureFlagResult?.data?.find(
          (flag) => flag.name === 'IS_SOC_SIM_PUBLIC',
        )?.enabled;

        if (isPublicSocSimEnabled) {
          return null;
        }

        // If not public, require completed onboarding & welcome
        const userQuery = store.dispatch(commonEndpoints.getUser.initiate());

        try {
          const { data } = await userQuery.unwrap();

          if (data) {
            const hasCompletedOnboarding = !!data?.user?.demographics?.howHeard;
            const hasCompletedWelcome = !!data?.user?.local?.path;
            const isB2BUser =
              (data?.user?.companies && data.user.companies.length > 0) ||
              data?.user?.subscriptions?.some((subscription) => subscription.companyId != null);

            if (!hasCompletedOnboarding && !hasCompletedWelcome) {
              return redirect('/r/onboarding');
            }
            if (!hasCompletedWelcome && !isB2BUser) {
              return redirect('/r/welcome');
            }
          }
          return null;
        } catch {
          // The user should be logged out if we get here
          return redirect('/login');
        } finally {
          userQuery.unsubscribe();
        }
      } catch {
        return redirect('/500');
      } finally {
        featureFlagQuery.unsubscribe();
      }
    });
    return processFlow;
  };

  processFlow.requireCategorizationRuleDev = () => {
    protectorArray.push(async () => {
      const query = store.dispatch(commonEndpoints.getUser.initiate());
      try {
        const { data } = await query.unwrap();
        const isCategorizationRuleDev = !!(
          data?.user.accountType === UserAccountTypes.ADMIN ||
          data?.user.roles?.includes(UserRoles.CATEGORIZATION_RULE_DEV)
        );
        if (!isCategorizationRuleDev) throw new Error('user is not an admin or categorization rule developer');
      } catch {
        return redirect('/404');
      } finally {
        query.unsubscribe();
      }
      return null;
    });
    return processFlow;
  };

  processFlow.requireReactHomePageFlagOrRemovePrefixFlag = () => {
    protectorArray.push(async (loadersArgs) => {
      const featureFlagQuery = store.dispatch(commonEndpoints.getFeatureFlags.initiate());
      try {
        const featureFlagResult = await featureFlagQuery.unwrap();
        const isReactHomePageFlag = featureFlagResult?.data?.find(
          (flag) => flag.name === FeatureFlagName.REACT_HOME_PAGE,
        )?.enabled;
        if (isReactHomePageFlag) return null;
        await gbPromise;
        if (gb.isOn('remove-slash-r-prefix')) return null;
        return await backToTheLegacyLoader(loadersArgs);
      } catch {
        return redirect('/500');
      } finally {
        featureFlagQuery.unsubscribe();
      }
    });
    return processFlow;
  };

  processFlow.waitForUserData = () => {
    protectorArray.push(async () => {
      const query = store.dispatch(commonEndpoints.getUser.initiate());
      try {
        await query.unwrap();
      } catch {
        /* empty */
      } finally {
        query.unsubscribe();
      }
      return null;
    });
    return processFlow;
  };

  processFlow.requireWorkspaceUser = () => {
    protectorArray.push(async () => {
      try {
        const isParOfAWorkspace = await RouteLoaders.userIsPartOfAWorkspace();
        if (!isParOfAWorkspace) throw new Error('user is not part of a workspace');
      } catch {
        return redirect('/workspace?ref=ctf-builder');
      }
      return null;
    });
    return processFlow;
  };

  processFlow.requireOneOf = ({
    redirectTo = '/dashboard',
    loaders,
  }: {
    redirectTo: string;
    loaders: (() => Promise<null | boolean>)[];
  }) => {
    protectorArray.push(async () => {
      try {
        const results = await Promise.all(loaders.map((item) => item()));
        if (results.some((element) => isLoaderValid(element))) {
          return null;
        }
        throw new Error('Some of the loaders failed');
      } catch {
        return redirect(redirectTo);
      }
    });
    return processFlow;
  };

  return processFlow;
};
