import { useEffect, useState } from 'react';

export enum ScriptStatus {
  Loading = 'loading',
  Ready = 'ready',
  Error = 'error',
}

export const useExternalScript = (
  src: string,
  { onLoad, attributes }: { onLoad?: () => void | Promise<void>; attributes?: Record<string, string> } = {},
  dataKey?: string,
) => {
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    let script = document.querySelector<HTMLScriptElement>(`script[src="${src}"]`);

    /* eslint-disable @typescript-eslint/no-non-null-assertion -- this is vanilla DOM manipulation we have to use some faith */
    const removeEventListeners = () => {
      // eslint-disable-next-line @typescript-eslint/no-misused-promises -- we don't care when the function resolves
      script!.removeEventListener('load', handleScriptLoad);
      script!.removeEventListener('error', handleScriptError);
    };

    const handleScriptLoad = async () => {
      if (onLoad) await onLoad();
      script!.dataset.status = ScriptStatus.Ready;
      setIsLoading(false);
      removeEventListeners();
    };

    const handleScriptError = () => {
      script!.dataset.status = ScriptStatus.Error;
      removeEventListeners();
      throw new Error('Chargebee script failed to load');
    };
    /* eslint-enable @typescript-eslint/no-non-null-assertion */

    const domStatus = script?.getAttribute('data-status');
    if (script === null) {
      script = document.createElement('script');
      script.src = src;
      script.async = true;
      if (dataKey) script.dataset.key = dataKey;
      script.dataset.status = ScriptStatus.Loading;

      // eslint-disable-next-line @typescript-eslint/no-misused-promises -- we don't care when the function resolves
      script.addEventListener('load', handleScriptLoad);
      script.addEventListener('error', handleScriptError);
      if (attributes) {
        for (const [attributeName, attributeValue] of Object.entries(attributes)) {
          script.setAttribute(attributeName, attributeValue);
        }
      }
      document.body.append(script);
    } else if (typeof domStatus === 'string') {
      if (domStatus === ScriptStatus.Error || !Object.values(ScriptStatus).includes(domStatus as ScriptStatus))
        throw new Error('Chargebee script failed to load');
      // eslint-disable-next-line @typescript-eslint/no-misused-promises -- we don't care when the function resolves
      if (domStatus === ScriptStatus.Loading) script.addEventListener('load', handleScriptLoad);
      setIsLoading(domStatus !== ScriptStatus.Ready);
    } else {
      throw new TypeError(`Script ${src} already in DOM in an unexpected way`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- intentionally excluding onLoad for ease of use if there is a use case for it changing feel free to update
  }, [src]);

  return isLoading;
};
