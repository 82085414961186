import { StyledLoadingPlaceholder } from '@tryhackme/thm-ui-components';
import styled from 'styled-components';

import { StyledTitle } from 'src/common/styles';

export const StyledRadarWrapper = styled.div<{ $isDashboard?: boolean }>`
  width: ${({ $isDashboard }) => ($isDashboard ? '36.8rem' : '53rem')};
  height: ${({ $isDashboard }) => ($isDashboard ? '31.5rem' : '55.7rem')};

  @media screen and (max-width: ${({ theme }) => theme.sizes.md}) {
    width: 30rem;
    height: 25.6rem;
  }
`;

export const StyledRadarChartWrapper = styled.div`
  display: flex;
  color: ${({ theme }) => theme.colors.text.main};
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
`;

export const StyledBetaTag = styled.span`
  background-color: ${({ theme }) => theme.colors.alertBanner.info};
  color: ${({ theme }) => theme.colors.info.main};
  padding: 0.2rem 1rem;
  border-radius: 100px;
  font-size: 1.2rem;
  font-weight: 400;
  font-family: ${({ theme }) => theme.fonts.familyUbuntu};
`;

export const StyledQuestionTitle = styled(StyledTitle)`
  margin: 0;
`;

export const StyledChartLoading = styled.div<{ $isDashboard?: boolean }>`
  ${StyledLoadingPlaceholder}
  width: ${({ $isDashboard }) => ($isDashboard ? '36.8rem' : '53rem')};
  height: ${({ $isDashboard }) => ($isDashboard ? '31.5rem' : '55.7rem')};

  @media screen and (max-width: ${({ theme }) => theme.sizes.md}) {
    width: 30rem;
    height: 25.6rem;
  }
`;

export const StyledZoomIndicator = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
  color: ${({ theme }) => theme.colors.text.main};
  font-family: ${({ theme }) => theme.fonts.familyUbuntu};
  font-size: ${({ theme }) => theme.fonts.sizes.medium};
  align-items: center;
  gap: 1rem;

  svg {
    width: 1.6rem;
    height: 1.6rem;
    path {
      fill: ${({ theme }) => theme.colors.grey[600]};
    }
  }
`;
