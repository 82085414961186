/* eslint-disable react/jsx-no-useless-fragment */
import * as Tooltip from '@radix-ui/react-tooltip';
import { ToolTipProps } from './tool-tip.types';
import { StyledTooltipArrow, StyledTooltipContent, StyledTooltipText } from './tool-tip.styles';
export const ToolTip = ({
  children,
  toolTipText,
  side = 'top',
  $contentMaxWidth,
  $fontSize,
  $textAlign,
  disabled = false,
  sideOffset = 5,
  ...tooltipProviderProps
}: ToolTipProps) => {
  if (disabled) return <>{children}</>;
  return <Tooltip.Provider {...tooltipProviderProps} delayDuration={300} data-sentry-element="unknown" data-sentry-component="ToolTip" data-sentry-source-file="tool-tip.tsx">
      <Tooltip.Root data-sentry-element="unknown" data-sentry-source-file="tool-tip.tsx">
        <Tooltip.Trigger asChild data-sentry-element="unknown" data-sentry-source-file="tool-tip.tsx">{children}</Tooltip.Trigger>
        <Tooltip.Portal data-sentry-element="unknown" data-sentry-source-file="tool-tip.tsx">
          <StyledTooltipContent sideOffset={sideOffset} side={side} $contentMaxWidth={$contentMaxWidth} data-sentry-element="StyledTooltipContent" data-sentry-source-file="tool-tip.tsx">
            <StyledTooltipText $fontSize={$fontSize} $textAlign={$textAlign} data-sentry-element="StyledTooltipText" data-sentry-source-file="tool-tip.tsx">
              {toolTipText}
            </StyledTooltipText>
            <StyledTooltipArrow data-sentry-element="StyledTooltipArrow" data-sentry-source-file="tool-tip.tsx" />
          </StyledTooltipContent>
        </Tooltip.Portal>
      </Tooltip.Root>
    </Tooltip.Provider>;
};