import { useContext, useRef } from 'react';
import { Radar } from 'react-chartjs-2';
import { SwitchThemeContext } from '@tryhackme/thm-ui-components';
import { faMagnifyingGlassPlus } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ThemeColorMode } from 'src/common/enums';
import { SkillsMatrix, type SkillsMatrixScore } from './skill-matrix-graphic.types';
import { StyledRadarChartWrapper, StyledRadarWrapper, StyledZoomIndicator } from './skill-matrix-graphic.styles';
import { useGetUserSkillsMatrixQuery } from './skill-matrix-graphic.slice';
import { getColorScheme, getGraphOptions, getRadarChartData } from './skill-matrix-graphic.helper';
import { SkillMatrixFallback } from './skill-matrix-fallback';
export const SkillMatrixGraphic = ({
  isClickable = false,
  onSkillSelect,
  role,
  roleId,
  isDashboard = false
}: {
  isClickable?: boolean;
  onSkillSelect?: (skill: SkillsMatrixScore | undefined) => void;
  role?: string;
  roleId?: string;
  isDashboard?: boolean;
}) => {
  const {
    theme
  } = useContext(SwitchThemeContext);
  const isDarkMode = theme === ThemeColorMode.DARK;
  const currentColorSettings = getColorScheme(isDarkMode, roleId);
  const {
    data,
    isFetching
  } = useGetUserSkillsMatrixQuery(role);
  const skillsMatrixData = (data?.data as SkillsMatrix)?.skills ?? [];
  const hoveredIndexRef = useRef<number | null>(null);
  const maxScore = Math.max(...skillsMatrixData.map(skill => skill.score));
  const dynamicMax = Math.max(maxScore <= 40 ? maxScore + 10 : 100, 20);
  const isZoomed = maxScore <= 40;
  const handleLabelClick = (index: number) => {
    if (onSkillSelect) {
      onSkillSelect(skillsMatrixData[index]);
    }
  };
  const graphOptions = getGraphOptions({
    currentColorSettings,
    isClickable,
    hoveredIndexRef,
    onLabelClick: isClickable ? handleLabelClick : undefined,
    dynamicMax,
    isZoomed,
    isDashboard
  });
  const radarChartData = getRadarChartData(skillsMatrixData, currentColorSettings, roleId);
  return <div data-sentry-component="SkillMatrixGraphic" data-sentry-source-file="skill-matrix-graphic.tsx">
      <StyledRadarChartWrapper data-sentry-element="StyledRadarChartWrapper" data-sentry-source-file="skill-matrix-graphic.tsx">
        {!isFetching && skillsMatrixData.length ? <StyledRadarWrapper $isDashboard={isDashboard}>
            <Radar aria-label="Radar Skills Matrix" data={radarChartData} options={graphOptions} />
          </StyledRadarWrapper> : <SkillMatrixFallback isLoadingChart={isFetching} fallbackText="Error Loading Skills Matrix" isDashboard={isDashboard} />}
      </StyledRadarChartWrapper>
      {!isFetching && !isDashboard && maxScore <= 40 && <StyledZoomIndicator>
          <FontAwesomeIcon icon={faMagnifyingGlassPlus} />
          200%
        </StyledZoomIndicator>}
    </div>;
};