import { useLayoutEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { CampaignData } from 'src/common/interfaces/campaigns';

const CAMPAIGN_KEY = 'thm_campaign_data';

export const useCampaign = (): CampaignData | null => {
  const [urlParams] = useSearchParams();
  const campaignParam = urlParams.get('utm_campaign');
  const [campaignData, setCampaignData] = useState<CampaignData | null>(null);

  useLayoutEffect(() => {
    const storedCampaignData = sessionStorage.getItem(CAMPAIGN_KEY);
    if (!storedCampaignData && !campaignParam) {
      return;
    }

    if (storedCampaignData) {
      try {
        const storedCampaign = JSON.parse(storedCampaignData) as CampaignData;
        setCampaignData(storedCampaign);
      } catch {
        console.warn('Invalid campaign data stored');
        return;
      }
    }

    const dataToStore: CampaignData = {
      name: 'fireship02042025',
      discountAmount: 20,
    };

    sessionStorage.setItem(CAMPAIGN_KEY, JSON.stringify(dataToStore));
    setCampaignData(dataToStore);
  }, [campaignParam]);

  return campaignData;
};
