import { useExternalScript } from 'src/common/hooks/use-external-script';
import { VerisoulClient } from 'src/common/verisoul-client';
enum VerisoulEnv {
  SANDBOX = 'sandbox',
  PRODUCTION = 'prod',
}
const VERISOUL_PROJECT_ID = process.env.REACT_APP_ENVIRONMENT === 'production' ? '0bb0dd27-4e04-41e2-935e-acd6fd160c77' : '0ec51f80-7bd0-4d5f-b578-624f37054a01';
const VERISOUL_ENV = process.env.REACT_APP_ENVIRONMENT === 'production' ? VerisoulEnv.PRODUCTION : VerisoulEnv.SANDBOX;
const onLoad = () => VerisoulClient.initialize();
export const useVerisoul = () => {
  useExternalScript(`https://js.verisoul.ai/${VERISOUL_ENV}/bundle.js`, {
    onLoad,
    attributes: {
      'verisoul-project-id': VERISOUL_PROJECT_ID
    }
  });
};