import { apiSlice } from 'src/features/api/api-slice';
import { RTKQueryTag } from 'src/common/constants';
import { ApiResponse } from 'src/common/interfaces';

import { UserNotificationResult } from './notifications.types';

export const userNotificationsSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getUserNotifications: builder.query<ApiResponse<UserNotificationResult>, void>({
      query: () => '/notifications',
      providesTags: [RTKQueryTag.Notifications],
    }),
    updateReadNotifications: builder.mutation<unknown, void>({
      query: () => ({
        method: 'POST',
        url: '/notifications/read',
      }),
      invalidatesTags: [RTKQueryTag.Notifications],
    }),
  }),
});

export const { useGetUserNotificationsQuery, useUpdateReadNotificationsMutation } = userNotificationsSlice;
