import React from 'react';
import DOMPurify from 'dompurify';
import { countries } from 'country-flags-svg-v2';
import { addDays, addWeeks } from 'date-fns';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';

import { ONE_MIN_IN_MS, ONE_DAY_IN_MS } from 'src/common/constants';
import { CalculateDeadlineParams } from 'src/features/paths/components/auth-main-content/components/learner-scheduler/learner-scheduler.types';

import { UserData } from '../interfaces';
import { UserRoles } from '../enums';
import { gb } from '../growthbook';

export * from './get-rank-title';

export * from './pdfExtension';

export const recoveryCodesToText = (recoveryCodes: string[]) => {
  let text = '';
  for (const [index, recoveryCode] of recoveryCodes.entries()) {
    if (index === 0) {
      text = recoveryCode;
    } else if (index % 2 === 0) {
      text = `${text}\n${recoveryCode}`;
    } else {
      text = `${text} ${recoveryCode}`;
    }
  }
  return text;
};

export const convertDateToUTC = (date: Date | string) => {
  const newDate = new Date(date);
  return new Date(newDate.getTime() + newDate.getTimezoneOffset() * ONE_MIN_IN_MS);
};

export const isDeadlinePassed = (deadline: string) => {
  const nowUTC = convertDateToUTC(new Date());
  const deadlineUTC = convertDateToUTC(deadline);

  return nowUTC >= deadlineUTC;
};

export const getDifferenceInDays = (deadline: string) => {
  const nowUTC = convertDateToUTC(new Date());
  const deadlineUTC = convertDateToUTC(deadline);

  const diffInMs = deadlineUTC.getTime() - nowUTC.getTime();
  return Math.ceil(diffInMs / ONE_DAY_IN_MS);
};

export const extractB2BUserRoles = (user: UserData['user'] | undefined) => ({
  isCompanyAdmin: !!user?.companies?.length,
  isB2BUserActive: !!user?.subscriptions?.find((sub) => sub.type === 'company' && sub.status === 'active'),
});

export const B2BUserHelper = (user: UserData['user'] | undefined) => {
  const roles = extractB2BUserRoles(user);
  const subscription = user?.subscriptions?.find((sub) => sub?.type === 'company' && sub?.status === 'active');
  const hasB2BSubscription = !!subscription;
  const isBusinessUserOnly = roles.isB2BUserActive && !roles.isCompanyAdmin;
  const isAdminAndB2BUser = roles.isCompanyAdmin && !!subscription;
  const isAdminOnly = roles.isCompanyAdmin && !isBusinessUserOnly && !isAdminAndB2BUser;
  const activeCompany = user?.companies?.find((company) => !company.isDeadlinePassed);

  function getCompanyId(): string | undefined {
    if (isAdminOnly) {
      return user?.companies?.[0]?._id;
    }

    if ((isAdminOnly || isAdminAndB2BUser) && activeCompany) {
      return activeCompany._id;
    }

    return undefined;
  }

  const companyId = getCompanyId();

  return {
    ...roles,
    subscription,
    isAdminAndB2BUser,
    isBusinessUserOnly,
    isAdminOnly,
    companyId,
    isBusinessUser: isBusinessUserOnly || isAdminOnly || isAdminAndB2BUser || hasB2BSubscription,
  };
};

export const isB2BUserOnly = (user: UserData['user'] | undefined) => {
  if (!user) return false;
  const { isCompanyAdmin, isB2BUserActive } = extractB2BUserRoles(user);
  return isB2BUserActive && !isCompanyAdmin;
};

export const generateRandomToken = () => {
  const randomBytes = new Uint8Array(20);
  crypto.getRandomValues(randomBytes);
  return [...randomBytes].map((byte) => byte.toString(16).padStart(2, '0')).join('');
};

export const findCountryNameByIso2Code = (iso2: string): string => {
  const country = countries.find((someCountry) => someCountry.iso2 === iso2.toUpperCase());
  return country?.name ?? '';
};

export const sanitizeAndRenderHtmlString = (htmlString: string) => {
  const sanitizedHTML = DOMPurify.sanitize(htmlString);
  return React.createElement('div', {
    dangerouslySetInnerHTML: { __html: sanitizedHTML },
  });
};

/**
 * Helper function to check if the string provided as the argument is a valid URL
 */
export const isValidURL = (str: string) => {
  const domainPattern = '(?<domain>[\\w-]+\\.)+[\\w]{2,}';
  const ipPattern = '(?<ip>(\\d{1,3}\\.){3}\\d{1,3})';
  const portPattern = '(:(?<port>\\d+))?';
  const pathPattern = '(?<path>/[\\w-./%+&:]+)*';
  const queryPattern = '(\\?(?<query>[\\w-./?%+&=:]+))?';
  const fragmentPattern = '(#(?<fragment>[\\w-./_%+&]+))?';

  const pattern = new RegExp(
    `^(https?://)?(${domainPattern}|${ipPattern})${portPattern}${pathPattern}${queryPattern}${fragmentPattern}$`,
    'i',
  );

  return pattern.test(str);
};

export const isValidImageUrl = (url: string) => {
  const isImageUrl = /(https?:\/\/.*\.(?:png|jpg|jpeg|gif|svg))/i.test(url);
  return isImageUrl;
};

/**
 * Redirects the browser to a specific path on the same domain. Support localhost as well as other envs
 * @param redirectPath path in URL
 * @param toReact include /r/ by default
 * @param keepHistory while preserving history in browser, if kept user can press return and come back to page
 */
export const redirectToPath = (redirectPath: string, keepHistory = false, toReact = true) => {
  const port = (window.location.port && (toReact ? `:${window.location.port}` : ':1337')) || ''; // support localhost
  const hostName = `${window.location.hostname}${port}/`;
  const includeReact = toReact ? 'r/' : '';
  const redirectUrl = `${window.location.protocol}//${hostName}${includeReact}${redirectPath}`;
  if (keepHistory) {
    window.location.assign(redirectUrl);
  } else {
    window.location.replace(redirectUrl);
  }
};

/**
 * Safely decodes text by using regular expression to replace any '%' that is not followed by two valid hex characters
 * then decodeURIComponent
 * @param text - string
 */
export const decode = (text: string) => {
  try {
    return decodeURIComponent(text.replace(/%(?![\dA-Fa-f]{2})/g, '%25'));
  } catch {
    return text;
  }
};

export const calculateEstimatedCompletionDate = ({
  totalPathHours,
  completedHours,
  hoursPerWeek,
}: CalculateDeadlineParams): Date => {
  const remainingHours = totalPathHours - completedHours;

  if (remainingHours <= 0 || hoursPerWeek <= 0) {
    return new Date();
  }

  const weeksNeeded: number = remainingHours / 60 / hoursPerWeek;
  const wholeWeeks = Math.floor(weeksNeeded);
  const fractionalWeeks = weeksNeeded - wholeWeeks;
  const daysNeeded = Math.round(fractionalWeeks * 7);

  let estimatedCompletionDate = addWeeks(new Date(), wholeWeeks);
  estimatedCompletionDate = addDays(estimatedCompletionDate, daysNeeded);

  return estimatedCompletionDate;
};

export const triggerDownload = (url: string) => {
  const link = document.createElement('a');
  link.href = url;
  link.target = '_blank';
  link.setAttribute('download', '');
  document.body.append(link);
  link.click();
  link.remove();
};

export const formatPrice = (price?: number): [string | null, string | null, string | null] => {
  if (price === undefined) {
    return [null, null, null];
  }

  const int = Math.trunc(price).toString();
  const decimal = Number.isInteger(price) ? null : price.toFixed(2).split('.')[1]!;

  let formatted = int;
  if (decimal) {
    formatted += `.${decimal}`;
  }

  return [formatted, int, decimal];
};

/**
 * Extracts an error message from an error object.
 * @param error - The error object (can be FetchBaseQueryError, Error, or any other type).
 * @param defaultMessage - The default message to return if no error message can be extracted.
 * @returns The extracted error message or the default message.
 */
export const getErrorMessage = (error: unknown, defaultMessage = 'Something went wrong'): string => {
  // Handle FetchBaseQueryError (RTK Query error)
  if (isFetchBaseQueryError(error)) {
    if (error.data && typeof error.data === 'object' && 'message' in error.data) {
      return error.data.message as string;
    }
    return defaultMessage;
  }

  // Handle Error objects (e.g., network errors)
  if (isErrorWithMessage(error)) {
    return error.message;
  }

  // Handle unknown errors
  return defaultMessage;
};

// Type guard to check if the error is a FetchBaseQueryError
export const isFetchBaseQueryError = (error: unknown): error is FetchBaseQueryError =>
  typeof error === 'object' && error != null && 'status' in error;

// Type guard to check if the error has a `message` property
export const isErrorWithMessage = (error: unknown): error is { message: string } =>
  typeof error === 'object' && error != null && 'message' in error;

export const getSecureRandom = (min: number, max: number): number => {
  const { crypto } = window;
  const buffer: ArrayBufferLike = new ArrayBuffer(4);
  const array: Uint32Array = new Uint32Array(buffer);

  if (!crypto) {
    throw new Error('Crypto API not supported');
  }

  crypto.getRandomValues(array);

  // Add null check for array[0]
  const value = array[0];
  if (typeof value !== 'number') {
    throw new TypeError('Failed to generate random value');
  }

  const maxUint32 = 4_294_967_295;
  const randomNumber = value / (maxUint32 + 1);

  return Math.floor(randomNumber * (max - min)) + min;
};

export const capitalize = (str = '') => str.charAt(0).toUpperCase() + str.slice(1);

export const calculateDifferenceInPercentage = (value1: number, value2 = 0) => {
  const difference = value1 - value2;

  if (value2 === 0) {
    return value1 > value2 ? 100 : 0;
  }
  return Math.round((difference / value2) * 100);
};

export const pluralize = (word: string, count: number): string => {
  if (count === 1) return word;

  return `${word}s`;
};

// @TODO: Refactor - remove content-dev - https://tryhackme.atlassian.net/browse/PLAT-540
/**
 * @description Check if the user is a content dev.
 * This is feature-flag dependent for now as we are planning to remove the content dev role.
 * @param user - The user object.
 * @returns boolean - Whether the user is a content dev.
 */
export const isInternalContentDev = (user?: UserData['user']): boolean => {
  const shouldOnlyUseContentDevInternal = gb.isOn('is-content-dev-internal-enabled');

  if (!user?.roles) return false;

  if (shouldOnlyUseContentDevInternal) return user.roles.includes(UserRoles.CONTENT_DEV_INTERNAL);

  return user.roles.includes(UserRoles.CONTENT_DEV) || user.roles.includes(UserRoles.CONTENT_DEV_INTERNAL);
};

export const isExternalContentDev = (user: UserData['user'] | undefined) =>
  user?.roles?.includes(UserRoles.CONTENT_DEV_EXTERNAL) ?? false;

export const getDomainFromEmail = (email: string) => {
  if (!!email && email.includes('@')) {
    return email.slice(Math.max(0, email.lastIndexOf('@') + 1));
  }

  return '';
};
