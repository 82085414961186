import { secondaryColors } from '@tryhackme/thm-ui-components';

import { GraphColorScheme } from './skill-matrix-graphic.types';

const BACKGROUND_GRADIENT_END = 'rgba(115, 234, 42, 0.6)';
const BORDER_COLOR = 'rgba(92, 182, 50, 1)';
const HOVER_TEXT_DARK = 'rgba(113, 156, 249, 1)';
const HOVER_TEXT_LIGHT = 'rgba(17, 83, 228, 1)';

export const darkColorScheme: GraphColorScheme = {
  grid: secondaryColors.secondary.lighter,
  text: secondaryColors.grey[200],
  backgroundGradientStart: 'rgba(12, 123, 203, 0.4)',
  backgroundGradientMiddle: 'rgba(115, 234, 42, 0.4)',
  backgroundEdge: BACKGROUND_GRADIENT_END,
  borderColor: BORDER_COLOR,
  hoverText: HOVER_TEXT_DARK,
};
export const lightColorScheme: GraphColorScheme = {
  grid: secondaryColors.grey[300],
  text: secondaryColors.grey[900],
  backgroundGradientStart: 'rgba(12, 203, 191,0.3)',
  backgroundGradientMiddle: 'rgba(84, 225, 87, 0.4)',
  backgroundEdge: BACKGROUND_GRADIENT_END,
  borderColor: BORDER_COLOR,
  hoverText: HOVER_TEXT_LIGHT,
};

export const colorSchemes = {
  foundational: {
    dark: darkColorScheme,
    light: lightColorScheme,
  },
  analyst: {
    dark: {
      grid: secondaryColors.secondary.lighter,
      text: secondaryColors.grey[200],
      borderColor: 'rgba(17, 83, 228, 1)',
      backgroundGradientStart: 'rgba(17, 83, 228, 0.2)',
      backgroundGradientMiddle: 'rgba(17, 83, 228, 0.4)',
      backgroundEdge: 'rgba(17, 83, 228, 0.6)',
      hoverText: HOVER_TEXT_DARK,
    },
    light: {
      grid: secondaryColors.grey[300],
      text: secondaryColors.grey[900],
      borderColor: 'rgba(17, 83, 228, 1)',
      backgroundGradientStart: 'rgba(17, 83, 228, 0.2)',
      backgroundGradientMiddle: 'rgba(17, 83, 228, 0.5)',
      backgroundEdge: 'rgba(17, 83, 228, 0.6)',
      hoverText: HOVER_TEXT_LIGHT,
    },
  },
  penetration: {
    dark: {
      grid: secondaryColors.secondary.lighter,
      text: secondaryColors.grey[200],
      borderColor: 'rgba(235, 0, 55, 1)',
      backgroundGradientStart: 'rgba(235, 0, 55, 0.1)',
      backgroundGradientMiddle: 'rgba(235, 0, 55, 0.4)',
      backgroundEdge: 'rgba(235, 0, 55, 0.6)',
      hoverText: HOVER_TEXT_DARK,
    },
    light: {
      grid: secondaryColors.grey[300],
      text: secondaryColors.grey[900],
      borderColor: 'rgba(235, 0, 55, 1)',
      backgroundGradientStart: 'rgba(235, 0, 55, 0.2)',
      backgroundGradientMiddle: 'rgba(235, 0, 55, 0.4)',
      backgroundEdge: 'rgba(235, 0, 55, 0.6)',
      hoverText: HOVER_TEXT_LIGHT,
    },
  },
  engineer: {
    dark: {
      grid: secondaryColors.secondary.lighter,
      text: secondaryColors.grey[200],
      borderColor: 'rgba(255, 141, 0, 1)',
      backgroundGradientStart: 'rgba(255, 141, 0, 0.1)',
      backgroundGradientMiddle: 'rgba(255, 141, 0, 0.4)',
      backgroundEdge: 'rgba(255, 141, 0, 0.6)',
      hoverText: HOVER_TEXT_DARK,
    },
    light: {
      grid: secondaryColors.grey[300],
      text: secondaryColors.grey[900],
      borderColor: 'rgba(255, 141, 0, 1)',
      backgroundGradientStart: 'rgba(255, 141, 0, 0.2)',
      backgroundGradientMiddle: 'rgba(255, 141, 0, 0.6)',
      backgroundEdge: 'rgba(255, 141, 0, 0.8)',
      hoverText: HOVER_TEXT_LIGHT,
    },
  },
} as const;

export const roleKeys = ['foundational', 'analyst', 'penetration', 'engineer'] as const;
export type RoleKey = (typeof roleKeys)[number];
