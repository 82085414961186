/* eslint-disable unicorn/no-useless-undefined, consistent-return */
import React from 'react';
// eslint-disable-next-line no-restricted-imports
import {
  GrowthBook,
  useFeature as originalUseFeature,
  useFeatureIsOn as originalUseFeatureIsOn,
  useFeatureValue as originalUseFeatureValue,
  useGrowthBook as originalUseGrowthBook,
  IfFeatureEnabled as originalIfFeatureEnabled,
  FeatureString as originalFeatureString,
  FeatureResult,
  WidenPrimitives,
  BrowserCookieStickyBucketService,
  TrackingData,
  StickyAssignmentsDocument,
} from '@growthbook/growthbook-react';
import Cookies from 'js-cookie';

import { store } from 'src/app/store';

import { anonymousId, getUserData, getRequestAttributes } from './tracking/data';
import { AppFeatures } from './interfaces/growthbook-generated-types';
import { analyticsEndpoints } from './slices/analytics';
import { getFeatureOverrides } from './helpers/feature-flags';

export const getAttributesFromCookiesAndUrl = () => ({
  anonymousId,
  ...getUserData(),
  ...getRequestAttributes(),
});

const objectOrUndefined = (value: unknown) =>
  typeof value === 'object' && value !== null ? (value as Record<string, unknown>) : undefined;
const arrayOrUndefined = (value: unknown) => (Array.isArray(value) ? value : undefined);
// hydration by CF worker
const getInjectedGrowthBookData = () => {
  const scriptElement = document.querySelector('#__GROWTHBOOK_DATA__');
  if (!scriptElement) return undefined;
  try {
    /* eslint-disable @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access */
    const data = JSON.parse(scriptElement.textContent || '') ?? {};
    return {
      attributes: objectOrUndefined(data?.attributes),
      payload: objectOrUndefined(data?.payload),
      stickyBucketAssignmentDocs: objectOrUndefined(data?.stickyBucketAssignmentDocs),
      deferredTrackingCalls: arrayOrUndefined(data?.deferredTrackingCalls),
    };
    /* eslint-enable @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access */
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Failed to parse __GROWTHBOOK_DATA__:', error);
    return undefined;
  }
};
let data: Partial<ReturnType<typeof getInjectedGrowthBookData>> = getInjectedGrowthBookData();

// used when no CF worker is present
if (!data) {
  data = {
    attributes: getAttributesFromCookiesAndUrl(),
  };
}

export const gb = new GrowthBook<AppFeatures>({
  apiHost: 'https://cdn.growthbook.io',
  clientKey: process.env.REACT_APP_GROWTHBOOK_CLIENT_KEY,
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  attributes: data?.attributes,
  trackingCallback: (experiment, result) => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    store.dispatch(
      analyticsEndpoints.trackEvent.initiate({
        event: 'experiment-viewed',
        properties: {
          experiment_id: experiment.key,
          variation_id: result.key,
          variation_name: result.name,
        },
      }),
    );
  },
  // hydration by CF worker
  stickyBucketAssignmentDocs: (data?.stickyBucketAssignmentDocs as Record<string, StickyAssignmentsDocument>) ?? {},
  // used when no CF worker is present
  stickyBucketService: new BrowserCookieStickyBucketService({
    jsCookie: Cookies,
  }),
  enableDevMode: process.env.REACT_APP_ENVIRONMENT !== 'production',
});

/** ! Only use this if you need to use the GrowthBook instance before the router is ready (very unlikely) ! */
// eslint-disable-next-line @typescript-eslint/no-floating-promises, unicorn/prefer-top-level-await
export const gbPromise = (async () => {
  if (data?.payload) {
    gb.initSync({
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      payload: data?.payload ?? {},
      streaming: true,
    });
  } else {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    await gb.init({ streaming: true });
  }
  if (data?.deferredTrackingCalls) {
    gb.setDeferredTrackingCalls(data.deferredTrackingCalls as TrackingData[]);
    await gb.fireDeferredTrackingCalls();
  }

  const overrideMap = getFeatureOverrides(gb);

  if (overrideMap?.size) {
    // eslint-disable-next-line no-console
    console.warn('Overriding features:', overrideMap);
    gb.setForcedFeatures(overrideMap);
  }
})();

/*
 *	Typed built-in @growthbook/growthbook-react hooks
 */

export const useFeature: <K extends keyof AppFeatures>(id: K) => FeatureResult<AppFeatures[K] | null> =
  originalUseFeature;
export const useFeatureIsOn = originalUseFeatureIsOn<AppFeatures>;
export const useFeatureValue: <K extends keyof AppFeatures>(
  id: K,
  fallback: AppFeatures[K],
) => WidenPrimitives<AppFeatures[K]> = originalUseFeatureValue;
export const useGrowthBook = originalUseGrowthBook<AppFeatures>;
export const IfFeatureEnabled: (props: {
  children: React.ReactNode;
  feature: keyof AppFeatures;
}) => React.JSX.Element | null = originalIfFeatureEnabled;

type StringAppFeatureKeys = {
  [K in keyof AppFeatures]: AppFeatures[K] extends string ? K : never;
}[keyof AppFeatures];
export const FeatureString: <K extends StringAppFeatureKeys>(props: {
  default: string;
  feature: K;
}) => React.JSX.Element = originalFeatureString;

/*
 *	other useful @growthbook/growthbook-react re-exported as-is
 */
// eslint-disable-next-line no-restricted-imports
export { GrowthBookProvider, FeaturesReady } from '@growthbook/growthbook-react';
