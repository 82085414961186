import { Modal } from '@tryhackme/thm-ui-components';
import { ReactComponent as ImageSevenDaysStreakMilestone } from 'src/images/svgs/rooms/streak-milestone/7-streak-milestone.svg';
import { ReactComponent as ImageThirtyDaysStreakMilestone } from 'src/images/svgs/rooms/streak-milestone/30-streak-milestone.svg';
import { ReactComponent as ImageNinetyDaysStreakMilestone } from 'src/images/svgs/rooms/streak-milestone/90-streak-milestone.svg';
import { ReactComponent as ImageOneHundredAndEightyDaysStreakMilestone } from 'src/images/svgs/rooms/streak-milestone/180-streak-milestone.svg';
import { ReactComponent as ImageThreeHundredAndSixtyFiveDaysStreakMilestone } from 'src/images/svgs/rooms/streak-milestone/365-streak-milestone.svg';
import { ReactComponent as ImageFiveHundredDaysStreakMilestone } from 'src/images/svgs/rooms/streak-milestone/500-streak-milestone.svg';
import { ReactComponent as ImageSevenHundredAndFiftyDaysStreakMilestone } from 'src/images/svgs/rooms/streak-milestone/750-streak-milestone.svg';
import { ReactComponent as ImageOneThousandDaysStreakMilestone } from 'src/images/svgs/rooms/streak-milestone/1000-streak-milestone.svg';
import { ReactComponent as ImageOneThousandTwoHundredAndFifityDaysStreakMilestone } from 'src/images/svgs/rooms/streak-milestone/1250-streak-milestone.svg';
import { ReactComponent as ImageOneThousandFiveHundredDaysStreakMilestone } from 'src/images/svgs/rooms/streak-milestone/1500-streak-milestone.svg';
import { IS_BIG_STREAK_MILESTONE_DISMISSED } from 'src/features/header/constants';
import { ShareRoomsCompletionModalProps } from './big-streak-share-modal.types';
import { StyledWrapperContent } from './big-streak-share-modal.styles';
import { BigStreakSocialSharing } from './components/big-streak-social-share';
export const BigStreakShareModal = ({
  streakLength,
  onOpenChange,
  isShareModalOpen,
  setIsShareModalOpen,
  isCompletionShareRoomModal,
  username,
  ...otherModalProps
}: ShareRoomsCompletionModalProps) => {
  const images = {
    7: <ImageSevenDaysStreakMilestone data-testid="7-days-streak-img" height={160} />,
    30: <ImageThirtyDaysStreakMilestone data-testid="30-days-streak-img" height={160} />,
    90: <ImageNinetyDaysStreakMilestone data-testid="90-days-streak-img" height={160} />,
    180: <ImageOneHundredAndEightyDaysStreakMilestone data-testid="180-days-streak-img" height={160} />,
    365: <ImageThreeHundredAndSixtyFiveDaysStreakMilestone data-testid="365-days-streak-img" height={160} />,
    500: <ImageFiveHundredDaysStreakMilestone data-testid="500-days-streak-img" height={160} />,
    750: <ImageSevenHundredAndFiftyDaysStreakMilestone data-testid="750-days-streak-img" height={160} />,
    1000: <ImageOneThousandDaysStreakMilestone data-testid="1000-days-streak-img" height={160} />,
    1250: <ImageOneThousandTwoHundredAndFifityDaysStreakMilestone data-testid="1250-days-streak-img" height={160} />,
    1500: <ImageOneThousandFiveHundredDaysStreakMilestone data-testid="1500-days-streak-img" height={160} />
  };
  return <Modal {...otherModalProps} onOpenChange={onOpenChange} open={isShareModalOpen} data-sentry-element="Modal" data-sentry-component="BigStreakShareModal" data-sentry-source-file="big-streak-share-modal.tsx">
      <StyledWrapperContent data-sentry-element="StyledWrapperContent" data-sentry-source-file="big-streak-share-modal.tsx">
        {images[(streakLength as keyof typeof images)]}
        <h2>Amazing! You have hit a {streakLength} day streak!</h2>
        <p>
          You have been crushing it on TryHackMe for {streakLength} days. Come back tomorrow to keep your streak going!
        </p>
        <p>Show your network how consistent you have been!</p>
        <BigStreakSocialSharing username={username} streakLength={streakLength} data-sentry-element="BigStreakSocialSharing" data-sentry-source-file="big-streak-share-modal.tsx" />
      </StyledWrapperContent>
    </Modal>;
};
export const shouldShowBigStreakShareModal = (streakLength: number) => {
  const isBigStreak = [7, 30, 90, 180, 365, 500, 750, 1000, 1250, 1500].includes(streakLength);
  const isItDismissed = localStorage.getItem(`${IS_BIG_STREAK_MILESTONE_DISMISSED}_${streakLength}`) !== null;
  return isBigStreak && !isItDismissed;
};