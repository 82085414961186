import { useEffect } from 'react';
import { getUnixTime } from 'date-fns';

import { useGetFeatureFlagsQuery, useGetUserQuery } from 'src/common/slices';
import { FeatureFlagName } from 'src/common/constants';
import { IntercomSettings } from 'src/common/interfaces/intercom';

import { SHARED_INTERCOM_SETTINGS } from '../constants/intercom';

const initializeIntercom = (intercomSettings: IntercomSettings) => {
  if (window.Intercom) window.Intercom('boot', intercomSettings);
};

const shutdownIntercom = () => {
  if (window.Intercom) window.Intercom('shutdown');
};

const hideIntercom = () => {
  if (window.Intercom) window.Intercom('update', { ...SHARED_INTERCOM_SETTINGS, hide_default_launcher: true });
};

export const useIntercom = (shouldHideIntercom = false) => {
  const { data: featureFlags, isLoading } = useGetFeatureFlagsQuery();

  const isIntercomEnabled = !!featureFlags?.data?.find((flag) => flag.name === FeatureFlagName.INTERCOM_ENABLED)
    ?.enabled;
  const { data: userData, isSuccess, isError } = useGetUserQuery(undefined, { skip: isLoading || !isIntercomEnabled });

  useEffect(() => {
    if (!isIntercomEnabled || shouldHideIntercom) {
      hideIntercom();
      return;
    }

    shutdownIntercom();

    const user = userData?.data?.user;

    if (user && isSuccess) {
      // User is logged in
      initializeIntercom({
        ...SHARED_INTERCOM_SETTINGS,
        user_id: user._id,
        email: user.email,
        name: user.username,
        created_at: getUnixTime(new Date(user.dateSignUp)),
        user_hash: user.intercomUserHmac,
      });
    } else if (isError) {
      // User is logged out
      initializeIntercom(SHARED_INTERCOM_SETTINGS);
    }
  }, [isIntercomEnabled, isError, isSuccess, userData, shouldHideIntercom]);
};
