import styled, { css } from 'styled-components';

export const StyledIconLogo = styled.img<{
  isDark: boolean;
  width: string;
  height: string;
  margin?: string;
  marginMd?: string;
}>`
  position: relative;
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  margin: ${({ margin }) => margin ?? 'initial'};
  object-position: center;
  ${({ isDark }) =>
    isDark &&
    css`
      filter: brightness(0) invert(1);
    `}

  @media screen and (max-width: ${({ theme }) => theme.sizes.md}) {
    margin: ${({ marginMd }) => marginMd ?? 'initial'};

    ::last-child {
      display: none;
    }
  }
`;
