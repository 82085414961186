import { DifficultyType, LearningPathType } from '@tryhackme/thm-ui-components';
import { ImageProps } from '@tryhackme/thm-ui-components/dist/components/atoms/image/image.types';

import { Collaborator } from 'src/common/interfaces/room';

import { HacktivitiesRoomTypeCard } from '../../hacktivities.types';
import { PaidAccessItem } from '../../learn/learn.types';

export interface LearningPathItem {
  title: string;
  image: string;
  secondaryImage?: string;
  banner?: string;
  description: string;
  code: string;
  type: HacktivitiesRoomTypeCard;
  difficulty: DifficultyType;
  roomCodes: string[];
  pathType?: LearningPathType;
  collaborator?: Collaborator[];
}

export enum ExtendedSearchKind {
  ALL = 'all',
  PATHS = 'paths',
  MODULES = 'modules',
  ROOMS = 'rooms',
  NETWORKS = 'networks',
}

export enum HacktivitiesProgressStatus {
  ALL = 'all',
  COMPLETED = 'completed',
}
interface Streak {
  number: {
    freeUser: number;
    subUser: number;
  };
}
export interface ExtendedSearchProps extends LearningPathItem {
  imageURL: string;
  freeToUse: boolean;
  headerImage?: string;
  businessOnly?: boolean;
  kind: ExtendedSearchKind;
  summary?: string;
  moduleURL: string;
  cardHeight?: string;
  showCardType?: boolean;
  userCompleted: boolean;
  imageProps?: ImageProps;
  url: string;
  paidAccess: PaidAccessItem;
  _id: string;
  target: string;
  rel: string;
  completedByUser: boolean;
  published?: Date;
  streak?: Streak;
}
export interface DataHacktivities {
  docs: ExtendedSearchProps[];
  totalPages: number;
  limit: number;
  page: number;
  totalDocs: number;
}

export interface HacktivitiesApiResponse {
  status: string;
  data: DataHacktivities;
}

export type Kind = 'all' | 'paths' | 'modules' | 'rooms' | 'networks';
export type PovTagFilterOptions = 'purple' | 'red' | 'blue';

export interface GetHacktivitiesSearchQueryParams {
  kind: Kind;
  difficulty?: string;
  order?: string;
  roomType?: string;
  contentSubType?: string;
  page?: number;
  searchText?: string;
  limit?: number;
  userProgress?: string;
  newLimit?: number;
  collaborator?: Collaborator[];
  povTagFilter?: PovTagFilterOptions;
  from?: string;
}

export interface GetSearchProgressSearchQueryParams {
  roomCodes?: string;
  networkCodes?: string;
  pathIds?: string;
  moduleIds?: string;
}
export interface SearchProgressResponse {
  status: string;
  data: {
    roomProgress: { roomCode: string; progressPercentage: number }[];
    networkProgress: { networkCode: string; progressPercentage: number }[];
    moduleProgress: { moduleId: string; progressPercentage: number }[];
    pathProgress: { pathId: string; progressPercentage: number }[];
  };
}
