import { AnnouncementName } from 'src/common/enums/announcements';
import { AnnouncementConfig } from 'src/common/interfaces/announcements';

import { AnnouncementBar } from './components/announcement-bar';
import { AnnouncementModal } from './components/announcement-modal';
import { checkMatch } from './helpers';

export const topAnnoucementBar: AnnouncementConfig = {
  name: AnnouncementName.FLASH_SALE_SPRING_2025,
  pathsToExclude: [
    '/onboarding/*',
    '/why-subscribe',
    '/pricing',
    '/welcome',
    '/payment/pending',
    '/feedback/subscription',
    '/room/*',
    '/echo',
    '/path/outline/*',
    '/recapme/*',
    '/login/*',
    '/certification/*',
  ],
  canShow(pathname, user) {
    const isPremium = user?.user?.isPremium;
    const isOnManageSub = checkMatch(['/manage-account/subscription'], pathname).doesMatch;

    return isPremium ? !isOnManageSub : true;
  },
  Component: AnnouncementBar,
};

export const embeddedAnnouncementBar: AnnouncementConfig = {
  name: AnnouncementName.FLASH_SALE_SPRING_2025,
  pathsToInclude: ['/why-subscribe'],
  Component: AnnouncementBar,
};

export const currentAnnouncementModal: AnnouncementConfig = {
  name: AnnouncementName.FLASH_SALE_SPRING_2025,
  pathsToInclude: ['/dashboard'],
  Component: AnnouncementModal,
};
