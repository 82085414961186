import { getUserData } from './tracking/data';

declare global {
  interface Window {
    Verisoul?: {
      account: ({ id, email }: { id: string; email: string }) => Promise<void>;
      reinitialize: () => Promise<void>;
    };
  }
}

const initialize = async () => {
  const { id, email } = getUserData();
  if (id) {
    await window.Verisoul?.account({
      id,
      email: email || '',
    });
  }
};

const reinitialize = async () => {
  await window.Verisoul?.reinitialize();
};

export const VerisoulClient = {
  initialize,
  reinitialize,
};
