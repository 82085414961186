import { apiSlice } from 'src/features/api/api-slice';
import { ApiResponse } from 'src/common/interfaces';
import { AnnouncementDTO } from 'src/common/interfaces/announcements';
import { RTKQueryTag } from 'src/common/constants';

export const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAnnouncements: builder.query<ApiResponse<AnnouncementDTO | null>, void>({
      query: () => ({
        url: 'announcements',
        method: 'GET',
      }),
      providesTags: [RTKQueryTag.Announcements],
    }),
  }),
});

export const { useGetAnnouncementsQuery } = extendedApiSlice;
