import { apiSlice } from 'src/features/api/api-slice';
import { ApiResponse } from 'src/common/interfaces';
import {
  AnalyticsEvent,
  TrackAnonymousPageViewBody,
  TrackButtonClickBody,
  TrackCTAClickBody,
  TrackFormOpenBody,
  TrackFormSubmitBody,
  TrackPageBody,
} from 'src/common/interfaces/segment';

const analyticsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    trackViewPage: builder.mutation<ApiResponse, TrackPageBody>({
      query: (body) => ({
        method: 'POST',
        url: '/analytics/page-view',
        body,
      }),
    }),
    trackAnonymousViewPage: builder.mutation<ApiResponse, TrackAnonymousPageViewBody>({
      query: (body) => ({
        method: 'POST',
        url: '/analytics/anonymous-page-view',
        body,
      }),
    }),
    /**
     * @deprecated Use trackCTAClick instead
     */
    trackButtonClick: builder.mutation<ApiResponse, TrackButtonClickBody>({
      query: (body) => ({
        method: 'POST',
        url: '/analytics/button-click',
        body,
      }),
    }),
    trackCTAClick: builder.mutation<ApiResponse, TrackCTAClickBody>({
      query: (body) => ({
        method: 'POST',
        url: '/analytics/cta-click',
        body,
      }),
    }),
    trackFormOpen: builder.mutation<ApiResponse, TrackFormOpenBody>({
      query: (body) => ({
        method: 'POST',
        url: '/analytics/form-open',
        body,
      }),
    }),
    trackFormSubmit: builder.mutation<ApiResponse, TrackFormSubmitBody>({
      query: (body) => ({
        method: 'POST',
        url: '/analytics/form-submit',
        body,
      }),
    }),
    trackEvent: builder.mutation<undefined, AnalyticsEvent>({
      query: (event) => ({
        method: 'POST',
        url: '/client-insights',
        body: event,
      }),
    }),
  }),
});

export const {
  useTrackViewPageMutation,
  useTrackAnonymousViewPageMutation,
  useTrackButtonClickMutation,
  useTrackCTAClickMutation,
  useTrackFormOpenMutation,
  useTrackFormSubmitMutation,
  useTrackEventMutation,
  endpoints: analyticsEndpoints,
} = analyticsApiSlice;
