import { routeProtector } from 'src/router/route-protector';
import { ThmRouteObject } from 'src/common/interfaces';
import { UserRoles } from 'src/common/enums';
export const yourMaterialRoutes: ThmRouteObject[] = [{
  path: '/your-material',
  loader: routeProtector().requireLogin(),
  async lazy() {
    const {
      YourMaterial
    } = await import('src/features/your-material');
    return {
      Component: YourMaterial
    };
  },
  children: [{
    path: '',
    async lazy() {
      const {
        Uploads
      } = await import('src/features/your-material/views/uploads-content-engineer/views/uploads');
      return {
        Component: Uploads
      };
    }
  }, {
    path: 'custom-development',
    async lazy() {
      const {
        CustomDevelopment
      } = await import('src/features/your-material/views/uploads-content-engineer/views/custom-development');
      return {
        Component: CustomDevelopment
      };
    }
  }, {
    path: 'content-engineers',
    loader: routeProtector().requireNotOneOfRoles([UserRoles.CONTENT_DEV_EXTERNAL]),
    async lazy() {
      const {
        ContentEngineers
      } = await import('src/features/your-material/views/uploads-content-engineer/views/content-engineers');
      return {
        Component: ContentEngineers
      };
    }
  }]
}];