import { Navigate } from 'react-router-dom';
import { AI_TUTOR_NAME, FeatureFlagName } from 'src/common/constants';
import { ThemeColorMode, UserRoles } from 'src/common/enums';
import { ThmRouteObject } from 'src/common/interfaces';
import { careersRoutes } from 'src/router/sub-routes/careers';
import { managementDashboardRoutes } from 'src/router/sub-routes/management-dashboard';
import { socSimRoutes } from 'src/router/sub-routes/soc-sim';
import { yourMaterialRoutes } from 'src/router/sub-routes/your-material';
import { fullScreen } from './helpers';
import { routeProtector } from './route-protector';
import { certificationRoutes } from './sub-routes/certification';
export const networkTemplateManagerRoutes = [{
  path: '',
  async lazy() {
    const {
      GeneralNetworkTemplateStep
    } = await import('src/features/networks/components/network-template-steps/general');
    return {
      Component: GeneralNetworkTemplateStep
    };
  }
}, {
  path: 'subnets',
  async lazy() {
    const {
      SubnetsNetworkTemplateStep
    } = await import('src/features/networks/components/network-template-steps/subnets');
    return {
      Component: SubnetsNetworkTemplateStep
    };
  }
}, {
  path: 'security-groups',
  async lazy() {
    const {
      SecurityGroupsNetworkTemplateStep
    } = await import('src/features/networks/components/network-template-steps/security-groups');
    return {
      Component: SecurityGroupsNetworkTemplateStep
    };
  }
}, {
  path: 'machines',
  async lazy() {
    const {
      MachinesNetworkTemplateStep
    } = await import('src/features/networks/components/network-template-steps/machines');
    return {
      Component: MachinesNetworkTemplateStep
    };
  }
}, {
  path: 'diagram',
  async lazy() {
    const {
      DiagramNetworkTemplateStep
    } = await import('src/features/networks/components/network-template-steps/diagram');
    return {
      Component: DiagramNetworkTemplateStep
    };
  }
}];
export const routes: ThmRouteObject[] = [{
  path: '/',
  loader: routeProtector().requireNotLoggedIn('/dashboard').requireReactHomePageFlagOrRemovePrefixFlag(),
  async lazy() {
    const {
      Home
    } = await import('src/features/home/home');
    return {
      Component: Home
    };
  }
}, {
  path: '/leaderboards',
  async lazy() {
    const {
      Leaderboards
    } = await import('src/features/leaderboards');
    return {
      Component: Leaderboards
    };
  }
}, {
  path: '/admin',
  loader: routeProtector().requireCompletedOnboardingAndWelcome(),
  children: [{
    path: 'networks',
    loader: routeProtector().requireAdmin(),
    async lazy() {
      const {
        AdminNetworks
      } = await import('src/features/admin');
      return {
        Component: AdminNetworks
      };
    }
  }, {
    path: 'manage-users',
    loader: routeProtector().requireMod(),
    async lazy() {
      const {
        AdminManageUsers
      } = await import('src/features/admin');
      return {
        Component: AdminManageUsers
      };
    }
  }, {
    path: 'cloud',
    loader: routeProtector().requireOneOfRoles([UserRoles.SDR, UserRoles.BDM, UserRoles.CONTENT_DEV,
    // @TODO: Refactor - remove content-dev - https://tryhackme.atlassian.net/browse/PLAT-540
    UserRoles.CONTENT_DEV_INTERNAL, UserRoles.AZURE_ADMIN]),
    async lazy() {
      const {
        CloudTraining
      } = await import('src/features/admin/cloud-training/cloud-training');
      return {
        Component: CloudTraining
      };
    },
    children: [{
      path: 'aws-users',
      loader: routeProtector().requireOneOfRoles([UserRoles.SDR, UserRoles.BDM]),
      async lazy() {
        const {
          AwsCloudUsers
        } = await import('src/features/admin/cloud-training/users');
        return {
          Component: AwsCloudUsers
        };
      }
    }, {
      path: 'aws-accounts',
      loader: routeProtector().requireOneOfRoles([UserRoles.SDR, UserRoles.BDM]),
      async lazy() {
        const {
          AwsCloudAccounts
        } = await import('src/features/admin/cloud-training/accounts');
        return {
          Component: AwsCloudAccounts
        };
      }
    }, {
      path: 'azure-tenant-onboarding',
      loader: routeProtector().requireOneOfRoles([UserRoles.SDR, UserRoles.BDM, UserRoles.AZURE_ADMIN]),
      async lazy() {
        const {
          AzureTenantOnboarding
        } = await import('src/features/admin/cloud-training/azure-tenant-onboarding');
        return {
          Component: AzureTenantOnboarding
        };
      }
    }, {
      path: 'azure-lab-config',
      loader: routeProtector()
      // @TODO: Refactor - remove content-dev - https://tryhackme.atlassian.net/browse/PLAT-540
      .requireOneOfRoles([UserRoles.AZURE_ADMIN, UserRoles.CONTENT_DEV, UserRoles.CONTENT_DEV_INTERNAL]).requireFeatureFlag(FeatureFlagName.AZURE_CLOUD_TRAINING),
      async lazy() {
        const {
          AzureLabConfig
        } = await import('src/features/admin/cloud-training/azure-lab-config');
        return {
          Component: AzureLabConfig
        };
      }
    }]
  }, {
    path: 'user-feedback',
    loader: routeProtector().requireSDROrAnalyst(),
    async lazy() {
      const {
        UserFeedback
      } = await import('src/features/user-feedback');
      return {
        Component: UserFeedback
      };
    }
  }, {
    path: 'submissions',
    loader: routeProtector().requireTester(),
    async lazy() {
      const {
        Submissions
      } = await import('src/features/admin');
      return {
        Component: Submissions
      };
    }
  }, {
    path: 'corp',
    loader: routeProtector().requireBDM(),
    async lazy() {
      const {
        AdminCorporate
      } = await import('src/features/admin');
      return {
        Component: AdminCorporate
      };
    }
  }, {
    path: 'user-occupation-details',
    loader: routeProtector().requireSDR(),
    async lazy() {
      const {
        AdminUserOccupation
      } = await import('src/features/admin');
      return {
        Component: AdminUserOccupation
      };
    }
  }, {
    path: 'tagging',
    loader: routeProtector().requireTagAdmin(),
    async lazy() {
      const {
        Tagging
      } = await import('src/features/admin');
      return {
        Component: Tagging
      };
    }
  }, {
    path: 'categorization-dashboard',
    loader: routeProtector().requireCategorizationRuleDev(),
    async lazy() {
      const {
        CategorizationDashboard
      } = await import('src/features/admin');
      return {
        Component: CategorizationDashboard
      };
    }
  }]
}, {
  path: '/manage-account',
  loader: routeProtector().requireLogin().requireCompletedOnboardingAndWelcome(),
  async lazy() {
    const {
      ManageAccount
    } = await import('src/features/manage-account');
    return {
      Component: ManageAccount
    };
  },
  children: [{
    path: 'account-details',
    async lazy() {
      const {
        AccountDetails
      } = await import('src/features/manage-account');
      return {
        Component: AccountDetails
      };
    }
  }, {
    path: 'password-security',
    loader: routeProtector().requireLocalUser(),
    async lazy() {
      const {
        PasswordAndSecurity
      } = await import('src/features/manage-account');
      return {
        Component: PasswordAndSecurity
      };
    }
  }, {
    path: 'subscription',
    async lazy() {
      const {
        Subscription
      } = await import('src/features/manage-account');
      return {
        Component: Subscription
      };
    }
  }, {
    path: 'teams',
    loader: routeProtector().requireFeatureFlag(FeatureFlagName.CTF_FEATURES),
    async lazy() {
      const {
        Teams
      } = await import('src/features/manage-account');
      return {
        Component: Teams
      };
    }
  }, {
    index: true,
    element: <Navigate to="account-details" replace />
  }]
}, {
  path: '/legal',
  children: [{
    path: '',
    element: <Navigate to="/legal/terms-of-use" replace />
  }, {
    path: 'cookie-policy',
    async lazy() {
      const {
        CookiePolicy
      } = await import('src/features/legal');
      return {
        Component: CookiePolicy
      };
    }
  }, {
    path: 'privacy-policy',
    async lazy() {
      const {
        PrivacyPolicy
      } = await import('src/features/legal');
      return {
        Component: PrivacyPolicy
      };
    }
  }, {
    path: 'acceptable-use-policy',
    async lazy() {
      const {
        AcceptableUsePolicy
      } = await import('src/features/legal');
      return {
        Component: AcceptableUsePolicy
      };
    }
  }, {
    path: 'terms-of-use',
    async lazy() {
      const {
        TermsOfUse
      } = await import('src/features/legal');
      return {
        Component: TermsOfUse
      };
    }
  }, {
    path: 'business-terms-of-use',
    async lazy() {
      const {
        BusinessTermsOfUse
      } = await import('src/features/legal');
      return {
        Component: BusinessTermsOfUse
      };
    }
  }, {
    path: 'data-processing-addendum',
    async lazy() {
      const {
        DataProcessingAddendum
      } = await import('src/features/legal');
      return {
        Component: DataProcessingAddendum
      };
    }
  }, {
    path: 'ai-terms-of-use',
    async lazy() {
      const {
        AITermsOfUse
      } = await import('src/features/legal');
      return {
        Component: AITermsOfUse
      };
    }
  }]
}, ...careersRoutes, ...certificationRoutes, {
  path: '/badges',
  loader: routeProtector().requireLogin().requireCompletedOnboardingAndWelcome(),
  async lazy() {
    const {
      Badges
    } = await import('src/features/badges');
    return {
      Component: Badges
    };
  }
}, {
  path: '/access',
  loader: routeProtector().requireLogin().requireCompletedOnboardingAndWelcome(),
  async lazy() {
    const {
      Access
    } = await import('src/features/access');
    return {
      Component: Access
    };
  }
}, {
  path: '/pricing',
  loader: routeProtector().requireNotLoggedIn('/why-subscribe').requireFeatureFlag(FeatureFlagName.REACT_PRICING_PAGE),
  async lazy() {
    const {
      Pricing
    } = await import('src/features/pricing');
    return {
      Component: Pricing
    };
  }
}, {
  path: '/why-subscribe',
  async loader({
    params,
    request
  }) {
    const url = new URL(request.url);
    const source = url.searchParams.get('source');
    const pricing = source === null ? '/pricing' : `/pricing?source=${source}`;
    return routeProtector().requireLogin(pricing)({
      params,
      request
    });
  },
  async lazy() {
    const {
      WhySubscribe
    } = await import('src/features/why-subscribe');
    return {
      Component: WhySubscribe
    };
  }
}, {
  path: '/not-found',
  async lazy() {
    const {
      NotFound404
    } = await import('src/features/error-pages');
    return {
      Component: NotFound404
    };
  }
}, {
  path: '/forgot',
  async lazy() {
    const {
      ForgotPassword
    } = await import('src/features/forgot-password');
    return {
      Component: ForgotPassword
    };
  }
}, {
  path: '/reset/:code',
  async lazy() {
    const {
      ResetPassword
    } = await import('src/features/reset-password');
    return {
      Component: ResetPassword
    };
  }
}, {
  path: '/500',
  async lazy() {
    const {
      Error500
    } = await import('src/features/error-pages');
    return {
      Component: Error500
    };
  }
}, {
  path: '/banned',
  loader: routeProtector().requireCompletedOnboardingAndWelcome(),
  async lazy() {
    const {
      Banned
    } = await import('src/features/error-pages');
    return {
      Component: Banned
    };
  }
}, {
  path: '/about',
  loader: routeProtector().requireCompletedOnboardingAndWelcome(),
  async lazy() {
    const {
      AboutUs
    } = await import('src/features/about-us');
    return {
      Component: AboutUs
    };
  }
}, {
  path: '/voucher/stats/:groupId',
  loader: routeProtector().requireCompletedOnboardingAndWelcome(),
  async lazy() {
    const {
      Voucher
    } = await import('src/features/voucher');
    return {
      Component: Voucher
    };
  }
}, {
  path: '/contact',
  loader: routeProtector().requireCompletedOnboardingAndWelcome(),
  async lazy() {
    const {
      ContactUs
    } = await import('src/features/contact-us');
    return {
      Component: ContactUs
    };
  }
}, {
  path: '/resources/',
  loader: routeProtector().requireCompletedOnboardingAndWelcome(),
  async lazy() {
    const {
      Resources
    } = await import('src/features/resources');
    return {
      Component: Resources
    };
  },
  children: [{
    path: ':category',
    async lazy() {
      const {
        Resources
      } = await import('src/features/resources');
      return {
        Component: Resources
      };
    }
  }]
}, {
  path: 'resources/blog/:slug',
  loader: routeProtector().requireCompletedOnboardingAndWelcome(),
  async lazy() {
    const {
      Article
    } = await import('src/features/resources');
    return {
      Component: Article
    };
  }
}, ...socSimRoutes, ...managementDashboardRoutes, {
  path: '/glossary',
  async lazy() {
    const {
      Glossary
    } = await import('src/features/glossary/glossary');
    return {
      Component: Glossary
    };
  }
}, {
  path: '/room/:roomCode',
  loader: routeProtector().requireCompletedOnboardingAndWelcome(),
  async lazy() {
    const {
      Room
    } = await import('src/features/room');
    return {
      Component: Room
    };
  },
  handle: {
    isHeaderSticky: false,
    hideIntercom: true
  }
},
// {
//   path: '/jr/:roomCode',
//   loader: routeProtector()
//     .requireFeatureFlag(FeatureFlagName.ROOMS_REBUILD_JOIN_ROOM)
//     .requireCompletedOnboardingAndWelcome(),
//   async lazy() {
//     const { JoinRoom } = await import('src/features/join-room');
//     return { Component: JoinRoom };
//   },
// },
{
  path: '/rooms',
  loader: routeProtector().requireCompletedOnboardingAndWelcome(),
  async lazy() {
    const {
      MyRooms
    } = await import('src/features/my-rooms');
    return {
      Component: MyRooms
    };
  }
}, {
  path: '/networks',
  loader: routeProtector()
  // @TODO: Refactor - remove content-dev - https://tryhackme.atlassian.net/browse/PLAT-540
  .requireOneOfRoles([UserRoles.CONTENT_DEV, UserRoles.CONTENT_DEV_INTERNAL]).requireFeatureFlag('networks-via-iac'),
  async lazy() {
    const {
      Networks
    } = await import('src/features/networks');
    return {
      Component: Networks
    };
  }
}, {
  path: '/networks/create',
  loader: routeProtector()
  // @TODO: Refactor - remove content-dev - https://tryhackme.atlassian.net/browse/PLAT-540
  .requireOneOfRoles([UserRoles.CONTENT_DEV, UserRoles.CONTENT_DEV_INTERNAL]).requireFeatureFlag('networks-via-iac'),
  async lazy() {
    const {
      CreateNetworkTemplate
    } = await import('src/features/networks/pages/template/create-template');
    return {
      Component: CreateNetworkTemplate
    };
  },
  children: networkTemplateManagerRoutes
}, {
  path: '/networks/edit/:templateId',
  loader: routeProtector()
  // @TODO: Refactor - remove content-dev - https://tryhackme.atlassian.net/browse/PLAT-540
  .requireOneOfRoles([UserRoles.CONTENT_DEV, UserRoles.CONTENT_DEV_INTERNAL]).requireFeatureFlag('networks-via-iac'),
  async lazy() {
    const {
      EditNetworkTemplate
    } = await import('src/features/networks/pages/template/edit-template');
    return {
      Component: EditNetworkTemplate
    };
  },
  children: networkTemplateManagerRoutes
}, {
  path: '/networks/templates/:templateId/instances',
  loader: routeProtector()
  // @TODO: Refactor - remove content-dev - https://tryhackme.atlassian.net/browse/PLAT-540
  .requireOneOfRoles([UserRoles.CONTENT_DEV, UserRoles.CONTENT_DEV_INTERNAL]).requireFeatureFlag('networks-via-iac'),
  async lazy() {
    const {
      NetworkInstances
    } = await import('src/features/networks/pages/instances');
    return {
      Component: NetworkInstances
    };
  }
}, {
  path: '/networks/instances',
  loader: routeProtector()
  // @TODO: Refactor - remove content-dev - https://tryhackme.atlassian.net/browse/PLAT-540
  .requireOneOfRoles([UserRoles.CONTENT_DEV, UserRoles.CONTENT_DEV_INTERNAL]).requireFeatureFlag('networks-via-iac'),
  async lazy() {
    const {
      NetworkInstances
    } = await import('src/features/networks/pages/instances');
    return {
      Component: NetworkInstances
    };
  }
}, {
  path: '/networks/instances/:networkInstanceId',
  loader: routeProtector()
  // @TODO: Refactor - remove content-dev - https://tryhackme.atlassian.net/browse/PLAT-540
  .requireOneOfRoles([UserRoles.CONTENT_DEV, UserRoles.CONTENT_DEV_INTERNAL]).requireFeatureFlag('networks-via-iac'),
  async lazy() {
    const {
      NetworkInstance
    } = await import('src/features/networks/pages/instance');
    return {
      Component: NetworkInstance
    };
  }
}, {
  path: '/room/manage/:roomCode',
  loader: routeProtector().requireLogin().requireCompletedOnboardingAndWelcome(),
  async lazy() {
    const {
      ManageRoom
    } = await import('src/features/manage-room');
    return {
      Component: ManageRoom
    };
  }
}, {
  path: '/payment/pending',
  async lazy() {
    const {
      PaymentPending
    } = await import('src/features/payment-pending');
    return {
      Component: PaymentPending
    };
  }
}, {
  path: '/christmas-2023',
  loader: routeProtector().requireCompletedOnboardingAndWelcome(),
  async lazy() {
    const {
      ChristmasPage2023
    } = await import('src/features/christmas-page-2023');
    return {
      Component: ChristmasPage2023
    };
  },
  handle: {
    forcedTheme: ThemeColorMode.DARK
  }
}, {
  path: '/christmas',
  async lazy() {
    const {
      ChristmasPage2024
    } = await import('src/features/christmas-page-2024');
    return {
      Component: ChristmasPage2024
    };
  },
  handle: {
    forcedTheme: ThemeColorMode.DARK,
    isHeaderTransparent: true,
    isHeaderSticky: false
  }
}, {
  path: '/attacking-and-defending-aws',
  loader: routeProtector().requireCompletedOnboardingAndWelcome(),
  async lazy() {
    const {
      AwsPricingPage
    } = await import('src/features/aws-pricing-page');
    return {
      Component: AwsPricingPage
    };
  },
  handle: {
    forcedTheme: ThemeColorMode.LIGHT
  }
}, {
  path: '/hacktivities/',
  loader: routeProtector().requireCompletedOnboardingAndWelcome(),
  async lazy() {
    const {
      Hacktivities
    } = await import('src/features/hacktivities');
    return {
      Component: Hacktivities
    };
  },
  children: [{
    path: '',
    async lazy() {
      const {
        Learn
      } = await import('src/features/hacktivities');
      return {
        Component: Learn
      };
    },
    handle: {
      isHeaderTransparent: true,
      isHeaderSticky: false
    }
  }, {
    path: 'practice',
    loader: async loadersArgs => {
      const processFlow = routeProtector();
      const protectorResult = await processFlow(loadersArgs);
      if (protectorResult) return null;
      window.location.href = '/r/hacktivities/challenges';
      return processFlow;
    },
    async lazy() {
      const {
        Practice
      } = await import('src/features/hacktivities');
      return {
        Component: Practice
      };
    }
  }, {
    path: 'challenges',
    loader: routeProtector(),
    async lazy() {
      const {
        Challenges
      } = await import('src/features/hacktivities');
      return {
        Component: Challenges
      };
    },
    handle: {
      isHeaderTransparent: true,
      isHeaderSticky: false
    }
  }, {
    path: 'search',
    async lazy() {
      const {
        Search
      } = await import('src/features/hacktivities');
      return {
        Component: Search
      };
    },
    handle: {
      isHeaderTransparent: true,
      isHeaderSticky: false
    }
  }]
}, {
  path: 'onboarding',
  children: [{
    path: '',
    async lazy() {
      const {
        OnboardingRenderer
      } = await import('src/features/onboarding');
      return {
        Component: OnboardingRenderer
      };
    },
    handle: {
      forcedTheme: ThemeColorMode.DARK,
      hideFooter: true,
      hideHeader: true
    }
  }]
}, {
  path: '/assignments',
  loader: routeProtector().requireBusinessUserOrCorporatePartner().requireCompletedOnboardingAndWelcome(),
  async lazy() {
    const {
      UserAssignments
    } = await import('src/features/hacktivities');
    return {
      Component: UserAssignments
    };
  }
}, {
  path: '/p/:username',
  loader: routeProtector().requireCompletedOnboardingAndWelcome(),
  async lazy() {
    const {
      PublicProfile
    } = await import('src/features/public-profile/public-profile');
    return {
      Component: PublicProfile
    };
  }
}, {
  path: '/:username/badges/:badgeName',
  async lazy() {
    const {
      PersonalBadges
    } = await import('src/features/personal-badges');
    return {
      Component: PersonalBadges
    };
  },
  handle: {
    hideHeader: true,
    hideFooter: true
  }
}, {
  path: '/:username/streaks/:streakName',
  async lazy() {
    const {
      BigStreak
    } = await import('src/features/big-streak');
    return {
      Component: BigStreak
    };
  },
  handle: {
    hideHeader: true,
    hideFooter: true
  }
}, {
  path: '/mobile-signup',
  loader: routeProtector().requireFeatureFlag(FeatureFlagName.REACT_MOBILE_SIGNUP_PAGE).requireCompletedOnboardingAndWelcome(),
  async lazy() {
    const {
      MobileSignup
    } = await import('src/features/mobile-signup');
    return {
      Component: MobileSignup
    };
  },
  handle: {
    hideFooter: true
  }
}, {
  path: '/signup',
  async lazy() {
    const {
      Signup
    } = await import('src/features/signup');
    return {
      Component: Signup
    };
  }
}, {
  path: '/welcome',
  loader: routeProtector().requireLogin(),
  async lazy() {
    const {
      Welcome
    } = await import('src/features/welcome');
    return {
      Component: Welcome
    };
  },
  handle: {
    ...fullScreen
  }
}, {
  path: '/dashboard',
  loader: routeProtector().requireLogin().requireCompletedOnboardingAndWelcome(),
  async lazy() {
    const {
      Dashboard
    } = await import('src/features/dashboard');
    return {
      Component: Dashboard
    };
  }
}, {
  path: '/login',
  loader: routeProtector().requireNotLoggedIn('/dashboard'),
  async lazy() {
    const {
      LoginPage
    } = await import('src/features/login');
    return {
      Component: LoginPage
    };
  },
  handle: {
    hideFooter: true
  }
}, {
  path: '/login/two-factor',
  loader: routeProtector().requireNotLoggedIn('/dashboard'),
  async lazy() {
    const {
      TwoFactorPage
    } = await import('src/features/login');
    return {
      Component: TwoFactorPage
    };
  },
  handle: {
    hideFooter: true
  }
}, {
  path: '/login/magic',
  loader: routeProtector().requireNotLoggedIn('/dashboard'),
  async lazy() {
    const {
      MagicLoginPage
    } = await import('src/features/login');
    return {
      Component: MagicLoginPage
    };
  },
  handle: {
    hideFooter: true
  }
}, {
  path: '/login/sso',
  loader: routeProtector().requireNotLoggedIn('/dashboard').requireFeatureFlag(FeatureFlagName.SINGLE_SIGN_ON),
  async lazy() {
    const {
      SingleSignOnLogin
    } = await import('src/features/login');
    return {
      Component: SingleSignOnLogin
    };
  }
}, {
  path: '/path',
  children: [{
    path: 'outline/:path',
    loader: routeProtector().requireCompletedOnboardingAndWelcome(),
    async lazy() {
      const {
        PathOutline
      } = await import('src/features/path-outline/path-outline');
      return {
        Component: PathOutline
      };
    },
    handle: {
      isHeaderTransparent: true,
      isHeaderSticky: false
    }
  }]
}, {
  path: '/paths',
  async lazy() {
    const {
      Paths
    } = await import('src/features/paths');
    return {
      Component: Paths
    };
  }
}, {
  path: '/recapme/:year/:username',
  loader: routeProtector().requireFeatureFlag(FeatureFlagName.RECAPME_PAGE),
  async lazy() {
    const {
      RecapmePage
    } = await import('src/features/recapme');
    return {
      Component: RecapmePage
    };
  },
  handle: {
    hideHeader: true,
    hideFooter: true
  }
}, {
  path: `/${AI_TUTOR_NAME.toLowerCase()}`,
  async lazy() {
    const {
      TutorLandingPage
    } = await import('src/features/landing');
    return {
      Component: TutorLandingPage
    };
  },
  handle: {
    isHeaderTransparent: true,
    isHeaderSticky: false
  }
}, {
  path: '/subscriptions',
  async lazy() {
    const {
      Subscriptions
    } = await import('src/features/subscriptions');
    return {
      Component: Subscriptions
    };
  }
}, ...yourMaterialRoutes, {
  path: '/upload',
  loader: routeProtector().requireLogin(),
  async lazy() {
    const {
      Upload
    } = await import('src/features/upload');
    return {
      Component: Upload
    };
  }
}, {
  path: '/certificate/:certificateID',
  loader: routeProtector().requireFeatureFlag(FeatureFlagName.SHARING_CERTIFICATE),
  async lazy() {
    const {
      CertificatePage
    } = await import('src/features/certificate-page');
    return {
      Component: CertificatePage
    };
  }
}, {
  path: '/feedback',
  loader: routeProtector().requireLogin(),
  async lazy() {
    const {
      Feedback
    } = await import('src/features/feedback');
    return {
      Component: Feedback
    };
  }
}, {
  path: '/learning',
  loader: routeProtector().requireFeatureFlag(FeatureFlagName.MY_LEARNING_PAGE).requireCompletedOnboardingAndWelcome(),
  async lazy() {
    const {
      MyLearning
    } = await import('src/features/my-learning');
    return {
      Component: MyLearning
    };
  }
}, {
  path: '/business/checkout',
  loader: routeProtector().requireFeatureFlag(FeatureFlagName.REACT_BUSINESS_CHECKOUT_PAGE),
  async lazy() {
    const {
      BusinessCheckout
    } = await import('src/features/business-checkout');
    return {
      Component: BusinessCheckout
    };
  },
  handle: {
    forcedTheme: ThemeColorMode.DARK,
    hideHeader: true,
    hideFooter: true
  }
}, {
  path: '/develop-rooms',
  loader: routeProtector().requireLogin(),
  async lazy() {
    const {
      DevelopRooms
    } = await import('src/features/develop-rooms');
    return {
      Component: DevelopRooms
    };
  }
}, {
  path: '/classrooms',
  loader: routeProtector().requireFeatureFlag(FeatureFlagName.REACT_CLASSROOMS_PAGE),
  async lazy() {
    const {
      Education
    } = await import('src/features/education');
    return {
      Component: Education
    };
  }
}, {
  path: '/module/:moduleURL',
  async lazy() {
    const {
      Module
    } = await import('src/features/module');
    return {
      Component: Module
    };
  }
}, {
  path: '/games',
  children: [{
    path: 'koth',
    loader: routeProtector().requireCompletedOnboardingAndWelcome(),
    async lazy() {
      const {
        Koth
      } = await import('src/features/koth');
      return {
        Component: Koth
      };
    }
  }, {
    path: 'koth/join/:token',
    async lazy() {
      const {
        JoinGame
      } = await import('src/features/koth/pages/join-game');
      return {
        Component: JoinGame
      };
    }
  }, {
    path: 'koth/:gameId',
    async lazy() {
      const {
        KothGame
      } = await import('src/features/koth/pages/koth-game');
      return {
        Component: KothGame
      };
    }
  }]
}, {
  path: '/skills-matrix',
  loader: routeProtector().requireFeatureFlag('skills-matrix-page').requireLogin(),
  async lazy() {
    const {
      SkillsMatrix
    } = await import('src/features/skills-matrix');
    return {
      Component: SkillsMatrix
    };
  }
}, {
  path: '/leagues',
  loader: routeProtector().requireFeatureFlag('weekly-leagues-page').requireLogin(),
  async lazy() {
    const {
      WeeklyLeagues
    } = await import('src/features/weekly-leagues');
    return {
      Component: WeeklyLeagues
    };
  },
  handle: {
    isHeaderTransparent: true,
    isHeaderSticky: false
  }
}, {
  path: '/retention',
  children: [{
    path: 'feedback',
    async lazy() {
      const {
        Retention
      } = await import('src/features/retention');
      return {
        Component: Retention
      };
    }
  }, {
    path: 'processing-offer',
    async lazy() {
      const {
        ProcessingOffer
      } = await import('src/features/retention/pages/processing-offer/processing-offer');
      return {
        Component: ProcessingOffer
      };
    },
    handle: {
      forcedTheme: ThemeColorMode.DARK
    }
  }, {
    path: 'processing-cancellation',
    async lazy() {
      const {
        ProcessingCancellation
      } = await import('src/features/retention/pages/processing-cancellation/processing-cancellation');
      return {
        Component: ProcessingCancellation
      };
    },
    handle: {
      forcedTheme: ThemeColorMode.DARK
    }
  }]
}, {
  path: '/hackfinity',
  loader: routeProtector().requireFeatureFlag('ctf-template'),
  async lazy() {
    const {
      CtfTemplate
    } = await import('src/features/ctf-template/ctf-template');
    return {
      Component: CtfTemplate
    };
  },
  handle: {
    forcedTheme: ThemeColorMode.DARK
  }
}];