import styled from 'styled-components';

export const StyledRow = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  white-space: nowrap;

  @media screen and (min-width: ${({ theme }) => theme.sizes.md}) {
    gap: 1.6rem;
  }
`;

export const StyledWrapRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 1.6rem;
`;

export const StyledBar = styled.div<{ $thin: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 10;
  color: ${({ theme }) => theme.colors.text.invert};
  padding: 1.6rem;
  gap: 1.6rem;
  background: ${({ theme }) => theme.colors.black};
  background-image: ${({ theme }) => `
    radial-gradient(300px 100px at 10% -10%, ${theme.colors.primary.main} -25%, transparent 100%);
  `};

  @media screen and (min-width: ${({ theme }) => theme.sizes.md}) {
    background-image: ${({ theme }) => `
      radial-gradient(300px 100px at 90% 110%, ${theme.colors.primary.main} -25%, transparent 100%),
      radial-gradient(300px 100px at 10% -10%, ${theme.colors.primary.main} -25%, transparent 100%);
    `};
  }

  @media screen and (min-width: ${({ theme }) => theme.sizes.lg}) {
    flex-direction: row;
    flex-wrap: wrap;
    flex-basis: 8rem;
    flex-shrink: 0;
    justify-content: center;

    background-image: ${({ theme }) => `
      radial-gradient(300px 100px at 90% 120%, ${theme.colors.primary.main} -25%, transparent 100%),
      radial-gradient(300px 100px at 50% -20%, ${theme.colors.primary.main} -25%, transparent 100%),
      radial-gradient(300px 100px at 10% 120%, ${theme.colors.primary.main} -25%, transparent 100%);
    `};
  }
`;

export const StyledHeading = styled.span<{ $thin: boolean }>`
  margin: 0;
  text-align: center;
  font-size: 2.4rem;

  font-weight: ${({ theme, $thin }) => ($thin ? theme.fonts.weight.regular : theme.fonts.weight.bolder)};
  color: ${({ theme }) => theme.colors.primary.main};
`;

export const StyledText = styled.span`
  font-size: ${({ theme }) => theme.fonts.sizes.medium};
  font-weight: ${({ theme }) => theme.fonts.weight.bolder};
  line-height: 3.3rem;
  text-align: center;

  @media screen and (min-width: ${({ theme }) => theme.sizes.md}) {
    font-size: 2.4rem;
  }
`;

export const StyledCtaTimer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1.6rem;

  @media screen and (min-width: ${({ theme }) => theme.sizes.md}) {
    gap: 2.4rem;
    flex-direction: row;
  }
`;

export const StyledTimerMessage = styled(StyledText)`
  display: none;
  font-weight: ${({ theme }) => theme.fonts.weight.regular};

  @media screen and (min-width: ${({ theme }) => theme.sizes.md}) {
    display: initial;
  }
`;

export const StyledHighlight = styled.span`
  color: ${({ theme }) => theme.colors.primary.main};
  font-weight: ${({ theme }) => theme.fonts.weight.bolder};
`;
