import { Button } from '@tryhackme/thm-ui-components';
import { useNavigate } from 'react-router-dom';
import contentImage from 'src/images/svgs/certifications/certificate-banner-placeholder.svg';
import { useSegmentTracking } from 'src/common/hooks/use-segment-tracking';
import { StyledBannerTextWrapper, StyledHeadingWrapper, StyledBannerImage, StyledTitleSection, StyledBannerImageWrapper } from './certification-app-banner.styles';
export const CertificationAppBanner = () => {
  const navigate = useNavigate();
  const {
    triggerButtonClickEvent
  } = useSegmentTracking();
  const handleLearnMore = () => {
    triggerButtonClickEvent('sal1-ad-click', 'certification-banner-ad', 'learn-more');
    navigate('/certification/security-analyst-level-1');
  };
  return <>
      <StyledBannerTextWrapper data-sentry-element="StyledBannerTextWrapper" data-sentry-source-file="certification-app-banner.tsx">
        <StyledHeadingWrapper data-sentry-element="StyledHeadingWrapper" data-sentry-source-file="certification-app-banner.tsx">
          <span>NEW</span>
          <h1>
            The <StyledTitleSection data-sentry-element="StyledTitleSection" data-sentry-source-file="certification-app-banner.tsx">defensive certification</StyledTitleSection> that gets you{' '}
            <StyledTitleSection data-sentry-element="StyledTitleSection" data-sentry-source-file="certification-app-banner.tsx">hired</StyledTitleSection>
          </h1>
        </StyledHeadingWrapper>
        <p>
          Stand out with Security Analyst Level 1 (SAL1). An entry-level, hands-on security analyst certification <br />
          built by industry experts. Prove your skills, showcase real-world experience, and launch your cyber security
          career.
        </p>
        <Button color="primary" variant="solid" onClick={handleLearnMore} data-sentry-element="Button" data-sentry-source-file="certification-app-banner.tsx">
          Learn more
        </Button>
      </StyledBannerTextWrapper>
      <StyledBannerImageWrapper data-sentry-element="StyledBannerImageWrapper" data-sentry-source-file="certification-app-banner.tsx">
        <StyledBannerImage src={contentImage} alt="Certification" data-sentry-element="StyledBannerImage" data-sentry-source-file="certification-app-banner.tsx" />
      </StyledBannerImageWrapper>
    </>;
};