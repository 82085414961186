import { store } from 'src/app/store';
import { commonEndpoints } from 'src/common/slices';
import { WorkspaceEndpoints } from 'src/features/management-dashboard/workspace/workspace.slice';

export const userIsPartOfAWorkspace = async () => {
  const workspaceQuery = store.dispatch(WorkspaceEndpoints.getWorkspace.initiate(undefined, {}));
  const { data: workspaceData } = await workspaceQuery;
  const isInWorkspace = !!workspaceData?.hasJoinedWorkspace;
  workspaceQuery.unsubscribe();
  return isInWorkspace;
};

export const isBusinessUserOrCoporatePartner = async () => {
  const userQuery = store.dispatch(commonEndpoints.getUser.initiate());
  const { data } = await userQuery.unwrap();
  const isBusinessUser = data?.user?.subscriptions?.some((sub) => sub.type === 'company' && sub.status === 'active');
  const isCorporatePartner = !!data?.user?.companies?.length;
  userQuery.unsubscribe();

  return !!isBusinessUser || isCorporatePartner;
};
