import styled from 'styled-components';

export const StyledHeader = styled.div<{
  $isTransparent: boolean;
  $showBanner: boolean;
  $headerDisplayNone: boolean;
  $stickToTop: boolean;
}>`
  display: ${({ $headerDisplayNone }) => ($headerDisplayNone ? 'none' : 'block')};

  header {
    top: ${({ $showBanner, $isTransparent, $stickToTop }) => {
      if (!$stickToTop) {
        return 'auto';
      }

      return $showBanner && $isTransparent ? '49rem' : '0';
    }};
  }

  @media screen and (min-width: ${({ theme }) => theme.sizes.lg}) {
    header {
      top: ${({ $showBanner, $isTransparent, $stickToTop }) => {
        if (!$stickToTop) {
          return 'auto';
        }

        return $showBanner && $isTransparent ? '33rem' : '0';
      }};
    }
  }
`;
