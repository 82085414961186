import { redirect, RouteObject } from 'react-router-dom';
import { FeatureFlagName } from 'src/common/constants';
import { ThemeColorMode } from 'src/common/enums';
import { ThmRouteObject } from 'src/common/interfaces';
import { routeProtector } from '../route-protector';
import { socSimRoutes } from './soc-sim';
const fullScreen = {
  hideFooter: true,
  forcedTheme: ThemeColorMode.DARK,
  headerDisplayNone: true
};
const fullScreenWithFooter = {
  hideHeader: true,
  forcedTheme: ThemeColorMode.DARK
};
const makeChildrenFullScreen = (route: ThmRouteObject): ThmRouteObject => {
  let children: ThmRouteObject[] | undefined;
  if (route.children) {
    children = route.children.map(child => makeChildrenFullScreen({
      ...child
    }));
  }
  return ({
    ...route,
    children,
    handle: fullScreen
  } as ThmRouteObject);
};

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const socSimModifiedRoute = makeChildrenFullScreen(socSimRoutes[0]!);
export const certificationRoutes: RouteObject[] = [{
  path: '/security-analyst-level-1',
  loader: () => redirect('/certification/security-analyst-level-1')
}, {
  path: '/certification',
  loader: routeProtector().requireFeatureFlag(FeatureFlagName.REACT_SECURITY_ANALYST_CERTIFICATION_LANDING_PAGE),
  children: [{
    path: '',
    loader: () => redirect('/not-found')
  }, {
    path: 'security-analyst-level-1',
    loader: routeProtector().requireFeatureFlag(FeatureFlagName.REACT_SECURITY_ANALYST_CERTIFICATION_LANDING_PAGE).requireCompletedOnboardingAndWelcome(),
    handle: {
      forcedTheme: ThemeColorMode.DARK
    },
    async lazy() {
      const {
        SecurityAnalystLandingPage
      } = await import('src/features/certifications/security-analyst-landing-page');
      return {
        Component: SecurityAnalystLandingPage
      };
    }
  }, {
    path: 'security-analyst-level-1/details',
    loader: routeProtector().requireFeatureFlag(FeatureFlagName.REACT_SECURITY_ANALYST_CERTIFICATION_LANDING_PAGE).requireLogin('/certification/security-analyst-level-1'),
    async lazy() {
      const {
        JuniorSecurityAnalyst
      } = await import('src/features/certifications/junior-security-analyst');
      return {
        Component: JuniorSecurityAnalyst
      };
    }
  }, {
    path: 'exam/:certificationSlug',
    loader: routeProtector().requireFeatureFlag(FeatureFlagName.CERTIFICATION_EXAM).requireLogin('/certification/security-analyst-level-1'),
    async lazy() {
      const {
        CertificationExam
      } = await import('src/features/certifications/exam');
      return {
        Component: CertificationExam
      };
    },
    handle: fullScreen,
    children: [{
      ...socSimModifiedRoute,
      path: 'soc-sim'
    }]
  }, {
    path: 'exam/:certificationSlug/results',
    loader: routeProtector().requireFeatureFlag(FeatureFlagName.CERTIFICATION_EXAM).requireLogin('/certification/security-analyst-level-1'),
    async lazy() {
      const {
        CertificationResults
      } = await import('src/features/certifications/exam/certification-results');
      return {
        Component: CertificationResults
      };
    },
    handle: fullScreenWithFooter
  }, {
    path: 'exam/:certificationSlug/results',
    loader: routeProtector().requireFeatureFlag(FeatureFlagName.CERTIFICATION_EXAM).requireLogin('/certification/security-analyst-level-1'),
    async lazy() {
      const {
        CertificationResults
      } = await import('src/features/certifications/exam/certification-results');
      return {
        Component: CertificationResults
      };
    },
    handle: fullScreenWithFooter
  }, {
    path: 'certificate/:certificationSlug',
    loader: routeProtector().requireFeatureFlag(FeatureFlagName.CERTIFICATION_EXAM),
    handle: {
      forcedTheme: ThemeColorMode.DARK
    },
    async lazy() {
      const {
        CertificatePage
      } = await import('src/features/certifications/certification');
      return {
        Component: CertificatePage
      };
    }
  }]
}];