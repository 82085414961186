import { useEffect, useRef, useCallback } from 'react';

import { addSocketListener, removeSocketListener } from 'src/common/sockets';
import { useAppDispatch, useAppSelector } from 'src/app/hooks';
import * as TutorReducer from 'src/app/reducers/room/tutor.reducer';

import { TutorNudge, TutorSocketEventType } from '../tutor.types';

import { useTutorSocketEvents } from './use-tutor-socket';

interface UseTutorNudgeProps {
  roomId?: string;
  roomCode: string;
}

export const useTutorNudge = ({ roomId, roomCode }: UseTutorNudgeProps) => {
  const {
    chat: { sessionId: tutorSessionId, open: isTutorOpen, notificationsEnabled = true, nudge },
  } = useAppSelector((state) => state.tutor);
  const dispatch = useAppDispatch();
  const { readMessages } = useTutorSocketEvents({
    roomId,
    roomCode,
  });

  const nudgeTimerRef = useRef<ReturnType<typeof setTimeout> | null>(null);
  const nudgeStatusRef = useRef(nudge.status);

  const setNudgeToIdle = useCallback(() => {
    dispatch(TutorReducer.setNudge({ status: 'idle', preview: '', message: '' }));
  }, [dispatch]);

  const setNudgeToInactive = useCallback(() => {
    dispatch(TutorReducer.setNudgeToInactive());
    nudgeTimerRef.current = setTimeout(() => setNudgeToIdle(), 60 * 1000);
  }, [dispatch, setNudgeToIdle]);

  const clearNudge = useCallback(() => {
    if (nudgeTimerRef.current) {
      clearTimeout(nudgeTimerRef.current);
      nudgeTimerRef.current = null;
    }
    if (nudgeStatusRef.current !== 'idle') {
      setNudgeToIdle();
    }
  }, [setNudgeToIdle]);

  useEffect(() => {
    nudgeStatusRef.current = nudge.status;
  }, [nudge.status]);

  useEffect(() => {
    const handleReceiveNudge = (
      data: Omit<TutorNudge, 'status'> & {
        tutorSessionId: string;
      },
    ) => {
      if (!notificationsEnabled) return;
      if (!data.tutorSessionId && !tutorSessionId) return;
      if (data.tutorSessionId) dispatch(TutorReducer.setTutorSessionId(data.tutorSessionId));
      if (!isTutorOpen) {
        dispatch(
          TutorReducer.setNudge({
            message: data.message,
            preview: data.preview,
            status: 'active',
          }),
        );
        if (nudgeTimerRef.current) {
          clearTimeout(nudgeTimerRef.current);
        }
        nudgeTimerRef.current = setTimeout(() => setNudgeToInactive(), 60 * 1000);
      }
      readMessages({ source: 'nudge', sessionId: data.tutorSessionId });
    };

    addSocketListener(TutorSocketEventType.RECEIVE_NUDGE, handleReceiveNudge);

    return () => {
      removeSocketListener(TutorSocketEventType.RECEIVE_NUDGE);
      clearNudge();
    };
  }, [
    roomId,
    tutorSessionId,
    isTutorOpen,
    notificationsEnabled,
    readMessages,
    dispatch,
    setNudgeToIdle,
    clearNudge,
    setNudgeToInactive,
  ]);

  return { clearNudge };
};
