import { useCallback, useState, useEffect, useMemo } from 'react';
import Cookies from 'universal-cookie';
import { addDays } from 'date-fns';
import { gb } from 'src/common/growthbook';
import { useGetDashboardNotificationsQuery, useGetUserQuery, useTrackEventMutation } from 'src/common/slices';
import { SegmentEvent } from 'src/common/types';
export const useLockContent = () => {
  const [shouldFetchNotifications, setShouldFetchNotifications] = useState(false);
  const {
    data: user,
    isSuccess: isUserLoggedIn
  } = useGetUserQuery();
  const {
    data: notifications
  } = useGetDashboardNotificationsQuery(undefined, {
    skip: !shouldFetchNotifications
  });
  const [trackEvent] = useTrackEventMutation();
  const cookies = useMemo(() => new Cookies(), []);
  const userId = (localStorage.getItem('ajs_user_id') || '').trim().split('"').join('');
  const [isUnlocking, setIsUnlocking] = useState(false);
  const exploreAllContentCookie = !!cookies.get(`explore-all-content-clicked-${userId}`);
  const [hasClickedExploreAllContent, setHasClickedExploreAllContent] = useState(exploreAllContentCookie === true);
  useEffect(() => {
    if (isUserLoggedIn && user?.data?.user) {
      setShouldFetchNotifications(true);
    }
  }, [isUserLoggedIn, user]);
  useEffect(() => {
    const handleStorageChange = () => {
      setHasClickedExploreAllContent(cookies.get(`explore-all-content-clicked-${userId}`) === true);
    };
    window.addEventListener('storage', handleStorageChange);
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, [userId, hasClickedExploreAllContent, cookies]);
  const sendEvent = useCallback((segmentEvent: SegmentEvent) => {
    // eslint-disable-next-line no-void
    void trackEvent({
      event: segmentEvent,
      properties: {
        name: 'clicked_on',
        page: 'hacktivities'
      }
    });
  }, [trackEvent]);
  const onExploreAllContent = (segmentEvent: SegmentEvent) => {
    setIsUnlocking(true);
    sendEvent(segmentEvent);
    const expiryDate = addDays(new Date(), 365);
    cookies.set(`explore-all-content-clicked-${userId}`, true, {
      path: '/',
      expires: expiryDate
    });
    setHasClickedExploreAllContent(true);
    window.location.reload();
  };
  const shouldNudgeUser = gb.isOn('nudge-users-back-1st-room-hacktivities') && !notifications?.data?.welcomeTask?.hasUserCompletedARoom && !hasClickedExploreAllContent;
  return {
    onExploreAllContent,
    sendEvent,
    hasClickedExploreAllContent,
    shouldNudgeUser,
    isUnlocking
  };
};