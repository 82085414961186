export const VM_ID_KALI_LINUX = '5f3a9d6842e1ab8a6de7f291';
export const VM_ID_FREE_OR_ATTACK_BOX = '5f39eb6342e1ab8a6d900ba3';
export const ROOM_CODE_ATTACK_BOX_FOR_FREE_USERS = 'freeattackbox';
export const ROOM_CODE_ATTACK_BOX_PREMIUM_OR_KALI = 'kali';
export const ATTACK_BOX_CODES = new Set([ROOM_CODE_ATTACK_BOX_PREMIUM_OR_KALI, ROOM_CODE_ATTACK_BOX_FOR_FREE_USERS]);
export const ATTACK_BOX_IDS = new Set(['5c7d676b90238128a41bb61a', '5f4990c717520e3b95ff868a']);

export const VM_INFO_ATTACK_BOX_FREE = {
  instanceId: VM_ID_FREE_OR_ATTACK_BOX,
  roomCode: ROOM_CODE_ATTACK_BOX_FOR_FREE_USERS,
  machineType: 'Free TryHackMe AttackBox',
};

export const VM_INFO_ATTACK_BOX_PREMIUM = {
  instanceId: VM_ID_FREE_OR_ATTACK_BOX,
  roomCode: ROOM_CODE_ATTACK_BOX_PREMIUM_OR_KALI,
  machineType: 'TryHackMe AttackBox',
};

export const VM_INFO_KALI_PREMIUM = {
  instanceId: VM_ID_KALI_LINUX,
  roomCode: ROOM_CODE_ATTACK_BOX_PREMIUM_OR_KALI,
  machineType: 'Kali Linux',
}; // Kali Free does not exist, only for premium users
