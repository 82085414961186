import { FeatureFlagName } from 'src/common/constants';
import { store } from 'src/app/store';
import { commonEndpoints } from 'src/common/slices';
import { ThemeColorMode } from 'src/common/enums';
import { RouteHandleObject } from 'src/common/interfaces';

export type FlattedFeatureFlags = Record<`IS_${FeatureFlagName}_ENABLED`, boolean>;

// As the loader does not forward the types returned by the async function it runs, it is useless to bother type safety here
// as you'll (for now) always need to manually cast the type when using useLoaderData() in the component.
export const getFeatureFlagsAtLoaderTime = async (): Promise<FlattedFeatureFlags | never> => {
  const query = store.dispatch(commonEndpoints.getFeatureFlags.initiate());
  const flattedFeatureFlags = {} as FlattedFeatureFlags;
  try {
    const result = await query.unwrap();
    return (
      result?.data?.reduce(
        // eslint-disable-next-line no-param-reassign,no-sequences,no-return-assign
        (flags, flag) => ((flags[`IS_${flag.name}_ENABLED`] = flag.enabled), flags),
        flattedFeatureFlags,
      ) ?? flattedFeatureFlags
    );
  } catch {
    return flattedFeatureFlags;
  } finally {
    query.unsubscribe();
  }
};

export const rootHandle: Partial<RouteHandleObject> = {
  forcedTheme: ThemeColorMode.DARK,
};

export const withoutFooter: Partial<RouteHandleObject> = {
  ...rootHandle,
  hideFooter: true,
};

export const fullScreen: Partial<RouteHandleObject> = {
  ...withoutFooter,
  headerDisplayNone: true,
};
