import { type PayloadAction, createSlice } from '@reduxjs/toolkit';

import { CertificationSectionType } from 'src/common/enums/certification';
import { CertificationState, CertificateSectionState, AllQuestions } from 'src/common/interfaces/certifications';

interface IsLoading {
  isLoading: true;
}

export interface CertificationStore {
  status: CertificationState | IsLoading;
  sectionStatus: CertificateSectionState | IsLoading;
  allQuestions: AllQuestions[] | IsLoading;
}

const initialState: CertificationStore = {
  status: { isLoading: true },
  sectionStatus: { isLoading: true },
  allQuestions: { isLoading: true },
};

const certificationStatusSlice = createSlice({
  name: 'certificationExamStatus',
  initialState,
  reducers: {
    setCertificationStatus: (state, { payload }: PayloadAction<CertificationState>) => ({ ...state, status: payload }),
    setCertificationSectionStatus: (state, { payload }: PayloadAction<CertificateSectionState>) => {
      if (state.sectionStatus.isLoading) {
        return { ...state, sectionStatus: payload };
      }
      if (state.sectionStatus.sectionType !== CertificationSectionType.MULTIPLE_CHOICE) {
        return { ...state, sectionStatus: payload };
      }
      return {
        ...state,
        sectionStatus: { ...payload, selectedAnswerOrderNumber: state.sectionStatus.selectedAnswerOrderNumber },
      };
    },
    setCertificationMultipleChoiceAnswers: (state, { payload }: PayloadAction<number | undefined>) => {
      if (state.sectionStatus.isLoading) {
        return state;
      }

      if (state.sectionStatus.sectionType !== CertificationSectionType.MULTIPLE_CHOICE) {
        return state;
      }

      return { ...state, sectionStatus: { ...state.sectionStatus, selectedAnswerOrderNumber: payload } };
    },
    setCertificationMultipleChoiceQuestions: (state, { payload }: PayloadAction<AllQuestions[]>) => ({
      ...state,
      allQuestions: payload,
    }),
  },
});

export const {
  setCertificationStatus,
  setCertificationSectionStatus,
  setCertificationMultipleChoiceAnswers,
  setCertificationMultipleChoiceQuestions,
} = certificationStatusSlice.actions;

const certificationReducer = certificationStatusSlice.reducer;

export { certificationReducer };
