import { apiSlice } from 'src/features/api/api-slice';
import { ApiResponse } from 'src/common/interfaces';

import { SkillsMatrix } from './skill-matrix-graphic.types';

export const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getUserSkillsMatrix: builder.query<ApiResponse<SkillsMatrix>, string | undefined>({
      query: (role) => ({
        method: 'GET',
        url: role ? `users/skills?role=${role}` : 'users/skills',
      }),
    }),
  }),
});

export const { useGetUserSkillsMatrixQuery } = extendedApiSlice;
