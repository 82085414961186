import { useContext } from 'react';
import { SwitchThemeContext } from '@tryhackme/thm-ui-components';
import CertificationBadgeIcon from 'src/images/svgs/certifications/certification-badge.svg';
import SocSimLogoIcon from 'src/images/svgs/soc-sim/soc-sim-icon.svg';
import { ThemeColorMode } from 'src/common/enums';
import { useMediaQuery } from 'src/common/hooks/use-media-query';
import { BREAKPOINTS } from 'src/common/constants/breakpoints';
import { StyledIconLogo } from './custom-navbar-icon.styles';
import { CustomNavbarIconTypes } from './custom-navbar-icon.types';
const navTypeMap = {
  [CustomNavbarIconTypes.CERTIFICATION]: {
    icon: CertificationBadgeIcon,
    alt: 'certification logo',
    width: '2rem',
    height: '2.5rem',
    margin: '0 1rem 0 0.1rem',
    marginMd: '0 0.5rem 0 0'
  },
  [CustomNavbarIconTypes.SOC_SIM]: {
    icon: SocSimLogoIcon,
    alt: 'soc simulator logo',
    width: '2.5rem',
    height: '3rem',
    marginMd: '0 -0.5rem 0 0'
  }
};
const pathStartWith = ['/soc-sim'];
const pathMatching = new Set(['/certification/security-analyst-level-1']);
export const CustomNavbarIcon = ({
  type
}: {
  type: CustomNavbarIconTypes;
}) => {
  const {
    theme
  } = useContext(SwitchThemeContext);
  const isDarkModeTheme = theme === ThemeColorMode.DARK;
  const {
    icon,
    ...rest
  } = navTypeMap[type];
  const isMobile = useMediaQuery(`(max-width: ${BREAKPOINTS.MOBILE}px)`);
  const isDarkMode = pathStartWith.some(path => !!window.location.pathname.includes(path)) || pathMatching.has(window.location.pathname) || isMobile || isDarkModeTheme;
  return <StyledIconLogo isDark={isDarkMode} src={icon} {...rest} data-sentry-element="StyledIconLogo" data-sentry-component="CustomNavbarIcon" data-sentry-source-file="custom-navbar-icon.tsx" />;
};