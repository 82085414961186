import { useCallback, useMemo } from 'react';
import { NotificationDispatch, StyledNotificationContent } from '@tryhackme/thm-ui-components';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { usePostStartVmMutation } from 'src/features/api/rooms/vms/vms.slice';
import { VmTypes } from 'src/common/interfaces/vms';
import { useGetUserQuery } from 'src/common/slices';
import { useTrackEventMutation } from 'src/common/slices/analytics';
import { VM_INFO_ATTACK_BOX_FREE, VM_INFO_ATTACK_BOX_PREMIUM, VM_INFO_KALI_PREMIUM } from 'src/common/constants/vms';
import { hasErrorPostData, hasResponseData } from 'src/common/helpers/slices';
import { RunningInstance } from 'src/common/interfaces/instances';
import { setCurrentTab, setVmPanelOpen, SplitScreenTab } from 'src/app/reducers/split-screen.reducer';
import { useAppDispatch } from 'src/app/hooks';
import { TaskType } from 'src/common/enums/task';
import { ROOM_VM_READY } from 'src/features/room/room.constants';
import { emitSocketEvent } from '../sockets';
const messageStarting = 'Starting your machine… please wait!';
const messageStarted = 'Hooray! Your machine has started. It may need a few minutes to become accessible.';
const messageSubscriptionNeeded = 'You need a subscription to start this type of VM';
const messageUploadIdRequired = 'Upload ID is required';
export const useStartVm = (roomCode: string) => {
  const dispatch = useAppDispatch();
  const [postStartVm, {
    isLoading: isVmStartLoading
  }] = usePostStartVmMutation();
  const [trackEvent] = useTrackEventMutation();
  const {
    data: userSelfData,
    isSuccess: isUserAuth
  } = useGetUserQuery();
  const isUserPremium = useMemo(() => userSelfData?.data?.user.isPremium, [userSelfData?.data?.user.isPremium]);
  const userId = useMemo(() => userSelfData?.data?.user._id ?? '', [userSelfData?.data?.user._id]);
  const startVmHandler = useCallback(async (vmType: VmTypes, uploadId?: string, taskId?: string) => {
    let vmInfo;
    let sendDeployBoxEvent = false;
    if (vmType === 'AttackBox') {
      vmInfo = isUserAuth && isUserPremium ? VM_INFO_ATTACK_BOX_PREMIUM : VM_INFO_ATTACK_BOX_FREE;
      sendDeployBoxEvent = true;
    }
    if (vmType === 'Kali Linux') {
      vmInfo = isUserAuth && isUserPremium ? VM_INFO_KALI_PREMIUM : undefined;
      sendDeployBoxEvent = true;
    }
    if (vmType === 'Deploy') {
      if (!uploadId) {
        NotificationDispatch('error', messageUploadIdRequired);
        return;
      }
      vmInfo = {
        roomCode,
        instanceId: uploadId
      };
    }
    if (!vmInfo) {
      NotificationDispatch('info', messageSubscriptionNeeded);
      return;
    }
    NotificationDispatch('warning', messageStarting);
    const startVmResult = await postStartVm({
      roomCode: vmInfo.roomCode,
      vmId: vmInfo.instanceId,
      taskId
    });
    if (startVmResult && hasResponseData<RunningInstance>(startVmResult)) {
      NotificationDispatch('success', messageStarted);
      if (startVmResult?.data.data?.remote.remoteUrl) {
        const tab: SplitScreenTab = {
          id: startVmResult.data.data.id,
          type: TaskType.STATIC_SITE,
          title: startVmResult.data.data.title,
          url: startVmResult.data.data.remote.remoteUrl,
          vm: startVmResult.data.data
        };
        dispatch(setCurrentTab(tab));
        dispatch(setVmPanelOpen(true));
        const uniqueId = `${userId}-${startVmResult.data.data.id}`;
        emitSocketEvent(ROOM_VM_READY, {
          uniqueId
        });
      }
      if (sendDeployBoxEvent) {
        // eslint-disable-next-line no-void
        void trackEvent({
          event: 'deploy-attackbox',
          properties: {
            from: 'room_page',
            machine_type: vmInfo.machineType
          }
        });
      }
      return;
    }
    let answerMessage;
    // eslint-disable-next-line unicorn/prefer-ternary
    if (hasErrorPostData(startVmResult)) {
      // Message returned from our server
      answerMessage = startVmResult.error?.data?.message;
      if (answerMessage.toLowerCase().includes('subscribe')) {
        // TODO [2024-04-01] Remove this when deployment.js is migrated, should use proper status codes instead of unsafe check
        NotificationDispatch('error', <StyledNotificationContent>
              {/* eslint-disable-next-line react/no-danger */}
              <p dangerouslySetInnerHTML={{
            __html: answerMessage
          }} />
            </StyledNotificationContent>);
      } else {
        NotificationDispatch('error', `Oh no, an error occurred while starting VM: ${answerMessage ?? 'unknown'} `);
      }
    } else {
      answerMessage = (startVmResult.error as FetchBaseQueryError).status;
      NotificationDispatch('error', `Oh no, an error occurred while starting VM: ${answerMessage ?? 'unknown'}`);
    }
  },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [dispatch, isUserAuth, isUserPremium, postStartVm, roomCode, trackEvent]);
  return {
    startVmHandler,
    isVmStartLoading
  };
};